import {directives} from '../app';

directives.directive('currency', ($filter) => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModelController) {
        const currencyFilter = $filter('currency');

        function parser(val)
        {
            return val ? parseFloat(val.replace(',', '.')) : 0.0;
        }

        ngModelController.$parsers.push(parser);

        // convert data from model format to view format
        ngModelController.$formatters.push((data) => currencyFilter(data, ''));

        $(element).bind('change', function () {
            $(this).val(
                currencyFilter(parser($(this).val()), ''),
            );
            scope.$apply();
        });
    },
}));
