import {entities} from '../app';

entities.factory('Role', () => function (data) {
    if (!data) {
        data = {};
    }

    this.id = data.id;
    this.code = data.code;
    this.authorizations = data.authorizations;

    this.distributorRoles = ['ROLE_TOC', 'ROLE_INEKX'];

    /**
         * @returns {boolean}
         */
    this.isDistributor = function () {
        return this.distributorRoles.indexOf(this.code) > -1;
    };
});
