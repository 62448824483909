import {controllers} from '../app';

controllers.controller('InspectionListController', ['$rootScope', '$scope', 'InspectionService', '$location', '$timeout', 'Inspection', 'localStorageService', function ($rootScope, $scope, InspectionService, $location, $timeout, Inspection, localStorageService) {
    $scope.inspections = null;
    $scope.inspectionCount = 0;
    $scope.activeTab = 'new';
    $scope.filter = {};
    $scope.filter.limit = 25;
    $scope.page = 1;
    $scope.totalPages = 1;
    $scope.selectedIds = [];
    $scope.selectedInvoiceableIds = [];
    $scope.selectAll = false;
    $scope.centercomTabCriteria = {
        new: ['new'],
        open: ['assigned', 'accepted', 'awaiting_cc', 'awaiting_distributor'],
        done: ['done'],
    };
    $scope.distributorTabCriteria = {
        new: ['assigned'],
        open: ['accepted', 'awaiting_cc', 'awaiting_distributor'],
        done: ['done'],
    };

    $scope.load = function () {
        $scope.inspections = [];
        $scope.inspectionCount = 0;
        $scope.selectAll = false;

        if ($scope.request) {
            $scope.request.$cancelRequest();
        }

        $scope.request = InspectionService.query($scope.getCriteria(), (data) => {
            if (!(data.count && data.result.length)) {
                $scope.inspections = false;
                return;
            }

            $scope.inspectionCount = data.count;
            $scope.totalPages = Math.ceil($scope.inspectionCount / $scope.filter.limit);

            $scope.inspections = data.result.map((item) => {
                const inspection = new Inspection(item);

                if ($scope.selectedIds.indexOf(item.id) > -1) {
                    inspection.selected = true;
                }
                return inspection;
            });

            $scope.updateSelectAll();
        });
    };

    $scope.open = function (inspection) {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        $location.path(`/inspection/${inspection.id}`);
    };

    $scope.setActiveTab = function (newTab) {
        $scope.selectedIds = [];
        $scope.selectedInvoiceableIds = [];
        $scope.activeTab = newTab;
        $scope.page = 1;
        localStorageService.set('inspection.activeTab', newTab);
        $scope.load();
    };

    $scope.getActiveTab = function () {
        return localStorageService.get('inspection.activeTab') || 'new';
    };

    $scope.getCriteria = function () {
        $scope.filter.offset = ($scope.page - 1) * $scope.filter.limit;

        const criteria = {};
        Object
            .keys($scope.filter)
            .forEach((key) => {
                criteria[key] = $scope.filter[key];
            });
        if ($scope.filter.createdAt) {
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            criteria.createdAt = `${$scope.filter.createdAt.getDate()}-${$scope.filter.createdAt.getMonth() + 1}-${$scope.filter.createdAt.getFullYear()}`;
        }

        if ($rootScope.user.isDistributor()) {
            criteria['status[]'] = $scope.distributorTabCriteria[$scope.getActiveTab()];
        } else {
            criteria['status[]'] = $scope.centercomTabCriteria[$scope.getActiveTab()];
        }

        return criteria;
    };

    $scope.filterChange = function () {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
            $scope.load();
        }, 500);
    };

    $scope.nextPage = function () {
        $scope.setPage(Math.min($scope.page + 1, $scope.totalPages));
    };

    $scope.previousPage = function () {
        $scope.setPage(Math.max($scope.page - 1, 0));
    };

    $scope.setPage = function (page) {
        $scope.page = page;
        $scope.load();
    };

    $scope.getPageButtons = function () {
        const result = [];
        const lowestPage = Math.max(1, Math.min($scope.page - 4, $scope.totalPages - 8));
        const highestPage = Math.min($scope.totalPages, Math.max(1, $scope.page - 4) + 8);

        for (let i = lowestPage; i <= highestPage; i++) {
            result.push(i);
        }

        return result;
    };

    /**
     * Called when selectAll checkbox is checked/unchecked
     */
    $scope.onSelectAll = function () {
        for (let i = 0; i < $scope.inspections.length; i++) {
            const index = $scope.selectedIds.indexOf($scope.inspections[i].id);
            if ($scope.selectAll && index === -1) {
                $scope.selectedIds.push($scope.inspections[i].id);
                $scope.inspections[i].selected = true;
            } else if (!$scope.selectAll && index > -1) {
                $scope.selectedIds.splice(index, 1);
                $scope.inspections[i].selected = false;
            }

            $scope.updateSelectedInvoiceableIds($scope.inspections[i]);
        }
    };

    /**
     * Called when a single row is checked/unchecked
     * @param inspection
     */
    $scope.onInspectionSelect = function (inspection) {
        const index = $scope.selectedIds.indexOf(inspection.id);
        if (inspection.selected && index === -1) {
            $scope.selectedIds.push(inspection.id);
            $scope.updateSelectAll();
        } else if (!inspection.selected && index > -1) {
            $scope.selectedIds.splice(index, 1);
            $scope.selectAll = false;
        }

        $scope.updateSelectedInvoiceableIds(inspection);
    };

    /**
     * Update the selectAll checkbox. Called when checking a row or switching page.
     */
    $scope.updateSelectAll = function () {
        $scope.selectAll = typeof $scope.inspections.find((inspection) => !inspection.selected) === 'undefined';
    };

    /**
     * Update selectedInvoiceableIds array. Called when selecting/deselecting one or more rows
     * @param inspection
     */
    $scope.updateSelectedInvoiceableIds = function (inspection) {
        if (!inspection.canBeInvoiced()) {
            return;
        }

        const index = $scope.selectedInvoiceableIds.indexOf(inspection.id);
        if (inspection.selected && index === -1) {
            $scope.selectedInvoiceableIds.push(inspection.id);
        } else if (!inspection.selected && index > -1) {
            $scope.selectedInvoiceableIds.splice(index, 1);
        }
    };

    $scope.exportInspections = function () {
        if (!$scope.selectedIds.length) {
            return;
        }

        window.open(
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            `/inspection/export.csv?${decodeURIComponent(
                $.param({
                    ids: $scope.selectedIds.join(','),
                }),
            )}`,
        );
    };

    $scope.invoiceInspections = function () {
        if (!$scope.selectedInvoiceableIds.length) {
            return false;
        }

        return InspectionService.invoice({ ids: $scope.selectedInvoiceableIds })
            .$promise
            .then(() => {
                for (let i = 0; i < $scope.inspections.length; i++) {
                    if ($scope.selectedInvoiceableIds.indexOf($scope.inspections[i].id) > -1) {
                        $scope.inspections[i].invoiced = true;
                    }
                }

                window.open(
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    `/inspection/export.csv?${decodeURIComponent(
                        $.param({
                            ids: $scope.selectedInvoiceableIds.join(','),
                        }),
                    )}`,
                );

                $scope.selectedInvoiceableIds = [];
            });
    };

    $scope.load();
}]);
