import {entities} from '../app';

entities.factory('Inspection', ['InspectionService', 'Role', 'Comment', 'Malfunction', function (InspectionService, Role, Comment, Malfunction) {
    return function (data) {
        if (!data) {
            data = {};
        }

        this.id = data.id;
        this.object = data.object;
        this.description = data.description;
        this.status = data.status;
        this.accepted = !!data.accepted;
        this.billable = !!data.billable;
        this.invoiced = !!data.invoiced;
        this.distributor = new Role(data.distributor);
        this.previousCampaign = data.previousCampaign;
        this.currentCampaign = data.currentCampaign;
        this.nextCampaign = data.nextCampaign;
        this.images = [];
        this.comments = [];
        this.createdBy = data.createdBy;
        this.malfunction = new Malfunction(data.malfunction);
        this.caseUrl = data.caseUrl;
        this.assignedAt = data.assignedAt;
        this.createdDateTime = data.createdDateTime;
        this.updatedDateTime = data.updatedDateTime;

        if (data.comments) {
            this.comments = data.comments.map((item) => new Comment(item));
        }

        const self = this;

        this.isValid = function () {
            return this.object
                && this.malfunction.id
                && (
                    !this.isDescriptionRequired()
                    || this.description
                );
        };

        /**
         * Returns if description is required based on chosen malfunction
         * @returns {boolean}
         */
        this.isDescriptionRequired = function () {
            const requiredMalfunctionCodes = [
                'overige',
            ];

            return requiredMalfunctionCodes.indexOf(this.malfunction.code) > -1;
        };

        this.getInspectionPhotos = function () {
            return InspectionService.getInspectionPhotos({ id: self.id }, (response) => {
                self.images = response;
                return response;
            }).$promise;
        };

        /**
         * Save or update an inspection
         * @returns {*}
         */
        this.save = function save()
        {
            if (this.id) {
                return InspectionService.update(this).$promise;
            }
            return InspectionService.save(this).$promise;
        };

        /**
         * Check if an inspection has the correct status to be approved
         * @param user
         * @returns {boolean}
         */
        this.canBeApproved = function (user) {
            if (!user.isDistributor()) {
                return false;
            }

            return ['assigned', 'awaiting_cc', 'awaiting_distributor'].indexOf(this.status) > -1;
        };

        /**
         * Check if an inspection has the correct status to be disapproved
         * @param user
         * @returns {boolean}
         */
        this.canBeDisapproved = function (user) {
            if (!user.isDistributor()) {
                return false;
            }

            return ['assigned'].indexOf(this.status) > -1;
        };

        /**
         * Check if an inspection can be set to invoiced
         * @returns {boolean}
         */
        this.canBeInvoiced = function () {
            return this.status === 'done' && !this.invoiced && this.billable;
        };

        /**
         * Check if an inspection is distributed
         * @returns {boolean}
         */
        this.isDistributed = function () {
            return this.id
                && this.status !== 'new'
                && (this.status !== 'done' || this.distributor === null);
        };

        /**
         * Check if the logged in user may distribute an inspection
         * @param user
         * @returns {boolean}
         */
        this.mayBeDistributed = function (user) {
            if (!user.isAuthorizedTo('manage_inspections') || user.isDistributor()) {
                return false;
            }

            return this.status === 'new';
        };

        /**
         * Check if an inspection can be distributed
         * @returns {boolean}
         */
        this.canBeDistributed = function () {
            return this.object.serie
                && this.object.serie.distributor
                && this.object.serie.distributor.role !== null;
        };

        /**
         * Check if billable can be changed
         * @param user
         * @returns {boolean}
         */
        this.mayChangeBillable = function (user) {
            if (!user.isAuthorizedTo('manage_inspections') || user.isDistributor()) {
                return false;
            }

            return this.status !== 'done' && this.accepted !== true;
        };

        /**
         * Get distributor where an inspection is distributed to
         * @returns {string}
         */
        this.getAssignedTo = function () {
            if (this.distributor.code === 'ROLE_TOC') {
                return 'TOC';
            }
            if (this.distributor.code === 'ROLE_INEKX') {
                return 'Inekx';
            }
            return null;
        };

        /**
         * Returns the date the inspection was assigned to a distributor
         * @returns {Date}
         */
        this.getAssignedAt = function () {
            return this.assignedAt;
        };
    };
}]);
