import {controllers} from '../app';

controllers.controller('QuoteComparisonController', ['$rootScope', '$scope', '$location', 'CampaignService', function ($rootScope, $scope, $location, CampaignService) {
    $scope.campaigns = {};
    $scope.loading = true;

    CampaignService.getQuoteComparisonList().$promise.then((response) => {
        $scope.campaigns = response;
        $scope.loading = false;
    });

    $scope.open = function (campaign) {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        $location.path(`/campaigns/${campaign.id}`);
    };
}]);
