import {directives} from '../app';

directives.directive('serieDetailCard', ($rootScope, ngDialog, SerieService) => ({
    restrict: 'E',
    link($scope, element) {
        element.on('click', ($event) => {
            $event.stopPropagation();
        });

        $scope.refreshSerieMap = function () {
            $scope.markers = $scope.objectsToMarkers($scope.objects);
        };

        $scope.refreshBounds = function () {
            // Recalculate bounds and fit to map.
            const bounds = new google.maps.LatLngBounds();
            const boundMarkers = $scope.markers.concat($scope.nonSerieMarkers);

            for (let i = 0; i < boundMarkers.length; i++) {
                bounds.extend(
                    new google.maps.LatLng(boundMarkers[i].latitude, boundMarkers[i].longitude),
                );
            }

            $scope.gmap.getGMap().fitBounds(bounds);
        };

        $scope.objectsToMarkers = function (objects, defaults) {
            const markers = [];
            defaults = defaults || {};

            angular.forEach(objects, (object) => {
                if (!object || !object.GPSPositionX || !object.GPSPositionY) {
                    return;
                }

                const marker = angular.copy(defaults);

                marker.id = object.id;
                marker.latitude = object.GPSPositionY.replace(',', '.');
                marker.longitude = object.GPSPositionX.replace(',', '.');
                marker.title = `m${marker.id}`;

                if (!marker.icon) {
                    marker.icon = (object.type === '2sign' ? '/build/img/icons/sign.png' : '');
                }

                markers.push(marker);
            });

            return markers;
        };

        $scope.getObjects = function (serie) {
            SerieService.getObjects({ id: serie.id }, (data) => {
                $scope.objects = data;
                $scope.refreshSerieMap();
                $scope.refreshBounds();
            });
        };

        $scope.getRates = function (serie) {
            SerieService.getRates({ id: serie.id }, (data) => {
                $scope.rates = data;
            });
        };

        $scope.getCurrentRates = function (serie) {
            SerieService.getCurrentRates({ id: serie.id }, (data) => {
                $scope.serie.currentRates = data;
            });
        };

        $scope.openPopup = function () {
            $scope.objectTypes = $rootScope.objectTypes;
            $scope.objectSizes = $rootScope.objectSizes;

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/serie-detail-dialog.html',
                className: 'ngdialog-theme-default ngdialogBig',
                scope: $scope,
            });
        };

        $scope.download = function () {
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            window.open(`/objects.xls?serieId=${$scope.serie.id}`);
        };

        $scope.construct = function () {
            SerieService.get({ id: $scope.object.id }, (data) => {
                $scope.serie = data;
                $scope.getObjects($scope.serie);
                $scope.getCurrentRates($scope.serie);

                $scope.openPopup();
            });
        };

        if ($rootScope.user.isAuthorizedTo('view_seriedetailcard')) {
            element.on('click', () => {
                $scope.serie = null;
                $scope.numberOfAdSpots = null;
                $scope.objects = null;
                $scope.rates = null;

                // Map stuff
                $scope.markers = [];
                $scope.nonSerieMarkers = [];

                $scope.map = {
                    center: {
                        latitude: 52.2836652,
                        longitude: 4.8323447,
                    },
                    zoom: 8,
                };

                $scope.gmap = {};

                $scope.construct();
            });
        }
    },
}));
