import {filters} from '../app';

filters.filter('capitalize', () => function (input) {
    if (input) {
        return input.substring(0, 1)
            .toUpperCase() + input.substring(1);
    }

    return input;
});
