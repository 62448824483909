import {controllers} from '../app';

controllers.controller('SerieOrganisationListController', ['$rootScope', '$scope', 'OrganisationService', '$location', '$timeout', 'Organisation', function ($rootScope, $scope, OrganisationService, $location, $timeout, Organisation) {
    $scope.api = OrganisationService.query;

    $scope.search = {
        organisation: { name: '' },
        incomplete: true,
        complete: true,
        overcomplete: true,
    };

    $scope.organisations = [];
    $scope.organisationOffset = 0;
    $scope.organisationLimit = 25;
    $scope.organisationType = ($location.hash() ? $location.hash() : 'city');

    $scope.open = function (organisation) {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        $location.path(`/settings/series/organisations/${organisation.id}`);
    };

    $scope.organisationSearch = function () {
        const currentCall = (new Date()).getTime();
        const lastCall = currentCall;

        const request = {
            query: $scope.search.organisation.name,
            businessType: $scope.organisationType,
            offset: $scope.organisationOffset,
            limit: $scope.organisationLimit,
            serie: true,
            incomplete: +$scope.search.incomplete,
            complete: +$scope.search.complete,
            overcomplete: +$scope.search.overcomplete,
        };

        $scope.api(request, (json) => {
            if (currentCall !== lastCall) {
                return;
            }

            if ($scope.organisations.length) {
                $scope.organisations = $scope.organisations.concat(json);
            } else {
                $scope.organisations = (json.length > 0 ? json : null);
            }

            angular.forEach($scope.organisations, (organisation, key) => {
                $scope.organisations[key] = new Organisation(organisation);
            })
                .$promise
                .then(() => {
                    getNextStatistics();
                });
        });
    };

    function showStatistics(organisation)
    {
        organisation.statistics = true;

        return OrganisationService.getStatistics({ id: organisation.id }, (response) => {
            organisation.statistics = response;
        });
    }

    function getNextStatistics()
    {
        for (let i = 0; i < $scope.organisations.length; i++) {
            if (!$scope.organisations[i].statistics) {
                return showStatistics($scope.organisations[i])
                    .$promise
                    .then(getNextStatistics);
            }
        }

        return Promise.resolve();
    }

    $scope.getPreviousOrganisations = function () {
        $scope.organisationOffset = (
            $scope.organisationOffset > $scope.organisationLimit
                ? $scope.organisationOffset - $scope.organisationLimit
                : 0
        );
        $scope.organisations = [];

        $scope.organisationSearch();
    };

    $scope.getNextOrganisations = function () {
        $scope.organisationOffset += $scope.organisationLimit;
        $scope.organisations = [];

        $scope.organisationSearch();
    };

    $scope.$watch('search.organisation.name', (search) => {
        $scope.organisations = [];
        $scope.organisationOffset = null;

        if ($scope.timeout && typeof $scope.timeout === 'function') {
            $scope.timeout.cancel();
        }

        $scope.timeout = $timeout(() => {
            if (search === $scope.search.organisation.name) {
                $scope.organisationSearch();
                $scope.timeout = null;
            }
        }, 700);
    }, true);

    $scope.$watch('organisationType', (older, newer) => {
        if (older === newer) {
            return;
        }

        $scope.organisations = [];
        $scope.organisationOffset = 0;
        $scope.organisationLimit = 25;
        $scope.organisationSearch();
        $location.hash($scope.organisationType);
    });

    $scope.checkboxAction = function () {
        $scope.organisations = [];
        $scope.organisationSearch();
    };
}]);
