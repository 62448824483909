import {controllers} from '../app';
// phpcs:disable Generic.WhiteSpace.ScopeIndent.Incorrect, Generic.WhiteSpace.ScopeIndent.IncorrectExact
controllers.controller('SerieDetailsController', ['$rootScope', '$scope', 'SerieService', '$routeParams', '$timeout', 'ObjectService', 'ngDialog', function ($rootScope, $scope, SerieService, $routeParams, $timeout, ObjectService, ngDialog) {
    // Object search
    $scope.objects = [];
    $scope.offset = 0;
    $scope.limit = 25;
    $scope.scrollEnabled = true;

    // Available object search
    $scope.availableObjects = [];
    $scope.availableOffset = 0;
    $scope.availableLimit = 25;
    $scope.availableScroll = true;

    $scope.search = [];
    $scope.search.type = '';
    $scope.search.street = '';
    $scope.saveStatus = 0;

    // Map stuff
    $scope.markers = [];
    $scope.nonSerieMarkers = [];

    $scope.map = {
        center: {
            latitude: 52.2836652,
            longitude: 4.8323447,
        },
        zoom: 8,
    };

    $scope.gmap = {};

    $scope.resetSearch = function () {
        $scope.objects = [];
        $scope.offset = 0;
        $scope.limit = 25;
    };

    $scope.resetAvailableObjectSearch = function () {
        $scope.availableObjects = [];
        $scope.availableOffset = 0;
        $scope.availableLimit = 25;
        $scope.availableScroll = true;
    };

    SerieService.get({ id: $routeParams.serieId }, (data) => {
        $scope.serie = data;
        $scope.initalRequest();
        $scope.availableObjectSearch();
        $scope.initMap();
    });

    SerieService.getCurrentRates({ id: $routeParams.serieId }, (data) => {
        $scope.currentRates = data;
    });

    $scope.initalRequest = function () {
        SerieService.getObjects({
            id: $scope.serie.id,
        }, (data) => {
            $scope.objects = data;
            $scope.refreshSerieMap();
            $scope.refreshBounds();
        });
    };

    $scope.objectSearch = function () {
        $scope.scrollEnabled = false;

        const currentCall = (new Date()).getTime();
        const lastCall = currentCall;

        SerieService.getObjects({
            id: $scope.serie.id,
        }, (data) => {
            if (currentCall !== lastCall) {
                return;
            }

            if ($scope.objects) {
                $scope.objects = $scope.objects.concat(data);
            } else {
                $scope.objects = data;
            }

            $scope.scrollEnabled = data.length > $scope.limit;
        });
    };

    $('*').scroll(function () {
        if ($scope.scrollEnabled && _.isFunction($scope.search)) {
            let scroll = $(this).scrollTop() + $(this).innerHeight();
            let pageHeight = $(this)[0].scrollHeight;
            if (scroll >= pageHeight) {
                $scope.offset += $scope.limit;
                $scope.search();
            }
        }
    });

    $scope.availableObjectSearch = function (reload = false) {
        $scope.availableObjectScroll = true;

        const currentCall = (new Date()).getTime();
        const lastCall = currentCall;

        ObjectService.query(
            {
                available: true,
                serieId: $scope.serie.id,
                addressDescription: $scope.search.addressDescription,
                type: $scope.search.type,
                serieEndDateExpired: true,
            },
            (data) => {
            if (currentCall !== lastCall) {
                return;
            }
            if ($scope.availableObjects && reload === false) {
                $scope.availableObjects = $scope.availableObjects.concat(data);
            } else {
            $scope.availableObjects = data;
            }

                $scope.availableObjectScroll = !(data.length <= $scope.limit);
            },
        );
    };

    $scope.selectObject = function (objectIndex) {
        // Reset previous selected object to red marker
        if ($scope.selectedObject != null) {
            $scope.resetMarkers($scope.markers);
        }

        $scope.selectedObject = objectIndex;
        $scope.selectedAvailableObject = null;
        $scope.selectedAvailableIndex = null;
        $scope.nonSerieMarkers = [];
        $scope.markers[objectIndex].icon = '/build/img/icons/marker_blue.png';
        $scope.refreshBounds();
    };

    $scope.selectAvailableObject = function ($index, object) {
        $scope.resetMarkers($scope.markers);
        if (object) {
            $scope.selectedAvailableObject = $scope.getObjectIndexById(
                $scope.availableObjects,
                object.id,
            );
        }
        $scope.selectedObject = null;

        $scope.selectedAvailableIndex = $index;

        $scope.nonSerieMarkers = $scope.objectsToMarkers(
            [$scope.availableObjects[$scope.selectedAvailableObject]],
            { icon: '/build/img/icons/marker_blue.png' },
        );

        $scope.refreshBounds();
    };

    $scope.resetMarkers = function (markers) {
        for (let i = 0; i < markers.length; i++) {
            markers[i].icon = null;
        }
    };

    $scope.moveUp = function () {
        const object = $scope.availableObjects[$scope.selectedAvailableObject];

        SerieService.addObject({
            id: $routeParams.serieId,
            objectId: object.id,
        }, (response) => {
            response.owner = object.owner;
            $scope.objects.push(response);
            $scope.availableObjects.splice($scope.selectedAvailableObject, 1);
            $scope.resetSelectedObjects();
            $scope.refreshSerieMap();
            $scope.refreshBounds();
            $scope.updateAdSpots();
        });
    };

    $scope.moveDown = function () {
        const object = $scope.objects[$scope.selectedObject];

        SerieService.removeObject({
            id: $routeParams.serieId,
            objectId: object.id,
        }, (response) => {
            response.owner = object.owner;
            $scope.availableObjects.push(response);
            $scope.objects.splice($scope.selectedObject, 1);
            $scope.resetSelectedObjects();
            $scope.refreshSerieMap();
            $scope.refreshBounds();
            $scope.updateAdSpots();
        });
    };

    $scope.resetSelectedObjects = function () {
        $scope.selectedObject = null;
        $scope.selectedAvailableObject = null;
        $scope.selectedAvailableIndex = null;
    };

    $scope.editObject = function (object) {
        if (
            !$rootScope.user.isAuthorizedTo('manage_objects_full')
            && !$rootScope.user.isAuthorizedTo('manage_objects_toc')
            && !$rootScope.user.isAuthorizedTo('manage_objects_fieldservice')
        ) {
            return;
        }

        $scope.form = object;

        ngDialog.openConfirm({
            template: '/build/html/partials/ngDialogTemplates/object-form.html',
            className: 'ngdialog-theme-default ngdialogBig',
            scope: $scope,
        })
            .then((edited) => {
                $scope.saveObject(edited);
            });
    };

    $scope.validate = function (object) {
        if (object.postcode) {
            object.postcode = object.postcode.replace(/ /g, '')
                .toUpperCase();
            if (!object.postcode.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
                $scope.form.postcodeError = true;
                return false;
            }
        }
        $scope.form.postcodeError = false;
        return true;
    };

    $scope.saveObject = function (object) {
        ObjectService.update(object, () => {
            $scope.availableObjectSearch(true);
            $scope.refreshSerieMap();
            $scope.refreshBounds();
            $scope.updateAdSpots();
        });
    };

    $scope.initMap = function (lat, long) {
        lat = lat || '52.28366519999999';
        long = long || '4.834538699999939';

        // @TODO: create a filter or something (or fix this in import - make it a float)
        lat = lat.replace(/,/g, '.');
        long = long.replace(/,/g, '.');

        return {
            center: {
                latitude: lat,
                longitude: long,
            },
            zoom: 12,
            control: {},
            draggable: true,
            dragging: true,
            bounds: {},
            options: {},
            refresh: true,
            markers: [{
                id: 0,
                latitude: lat,
                longitude: long,
            }],
        };
    };

    $scope.$watchCollection('[search.type, search.addressDescription]', (older, newer) => {
        if (older === newer) {
            return;
        }

        if ($scope.searchAvailableTimeout) {
            $timeout.cancel($scope.searchAvailableTimeout);
        }

        if ($scope.serie) {
            $scope.searchAvailableTimeout = $timeout(() => {
                $scope.resetAvailableObjectSearch();
                $scope.availableObjectSearch();
            }, 250);
        }
    });

    $scope.objectsToMarkers = function (objects, defaults) {
        const markers = [];
        defaults = defaults || {};

        angular.forEach(objects, (object) => {
            if (!object || !object.GPSPositionX || !object.GPSPositionY) {
                return;
            }

            const marker = angular.copy(defaults);

            marker.id = object.id;
            marker.latitude = object.GPSPositionX.replace(',', '.');
            marker.longitude = object.GPSPositionY.replace(',', '.');
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            marker.title = `m${marker.id}`;

            if (!marker.icon) {
                marker.icon = (object.type === '2sign' ? '/build/img/icons/sign.png' : '');
            }

            markers.push(marker);
        });

        return markers;
    };

    $scope.markerEvents = {
        click(marker) {
            $scope.selectObject(
                $scope.getObjectIndexById($scope.objects, marker.model.id),
            );
        },

        dblclick(marker) {
            $scope.editObject(
                $scope.objects[$scope.getObjectIndexById(
                    $scope.objects,
                    marker.model.id,
                )],
            );
        },
    };

    $scope.nonSerieMarkerEvents = {
        click(marker) {
            $scope.selectAvailableObject(
                $scope.getObjectIndexById($scope.availableObjects, marker.model.id),
            );
        },

        dblclick(marker) {
            $scope.editObject(
                $scope.availableObjects[$scope.getObjectIndexById(
                    $scope.availableObjects,
                    marker.model.id,
                )],
            );
        },
    };

    $scope.refreshSerieMap = function () {
        $scope.markers = $scope.objectsToMarkers($scope.objects);

        // reselect to refresh GPS cords.
        if ($scope.selectedAvailableObject > -1) {
            $scope.nonSerieMarkers = $scope.objectsToMarkers(
                [$scope.availableObjects[$scope.selectedAvailableObject]],
                { icon: '/build/img/icons/marker_blue.png' },
            );
        }
    };

    $scope.refreshBounds = function () {
        // Recalculate bounds and fit to map.
        const bounds = new google.maps.LatLngBounds();
        const boundMarkers = $scope.markers.concat($scope.nonSerieMarkers);

        for (let i = 0; i < boundMarkers.length; i++) {
            bounds.extend(
                new google.maps.LatLng(boundMarkers[i].latitude, boundMarkers[i].longitude),
            );
        }

        $scope.gmap.getGMap()
            .fitBounds(bounds);
    };

    $scope.getObjectIndexById = function (objects, objectId) {
        for (const [key, value] of Object.entries(objects)) {
            if (value.id === objectId) {
                return key;
            }
        }
        return null;
    };

    $scope.updateAdSpots = function () {
        SerieService.getNumberOfAdSpots({ id: $scope.serie.id }, (response) => {
            $scope.serie.actualNumberOfAdSpots = response.result;
        });
    };

    $scope.getTagString = function (tags) {
        if (!tags) {
            return '';
        }

        return tags.map((tag) => tag.text)
            .join(', ');
    };

    const organisations = new Bloodhound({
        datumTokenizer(d) {
            return Bloodhound.tokenizers.whitespace(d.num);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/organisations', // Required
            replace(url, query) {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                return `/organisations?query=${query}&type=owner`;
            },
        },
    });

    $scope.$on('typeahead:selected', (event) => {
        if (event.targetScope.options.type === 'formowner') {
            const owner = $scope.form.owner.name;
            $scope.form.owner.id = owner.id;
            $scope.form.owner.name = owner.name;
            $scope.$apply();
        }
    });

    $scope.searchOptions = {
        formowner: {
            highlight: true,
            type: 'formowner',
        },
    };

    $scope.organisationData = {
        displayKey: 'name',
        source: organisations.ttAdapter(),
    };

    organisations.initialize();
}]);
