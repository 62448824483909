import {bootboxConfirm, directives, getWeeks, WeekYear} from '../app';
// phpcs:disable Generic.WhiteSpace.ScopeIndent.Incorrect, Generic.WhiteSpace.ScopeIndent.IncorrectExact, PSR2.Methods.FunctionCallSignature.Indent
directives.directive('campaignPlanner', ($rootScope, SerieService, CampaignPlanning, $timeout, Serie, PlanningOption, ngDialog, Comment, CommentService, CampaignPlanningService) => ({
    restrict: 'A',
    templateUrl: '/build/html/partials/directives/campaignPlanner.html',
    transclude: true,
    scope: {
        organisations: '=organisations',
        campaign: '=campaign',
        weekYear: '=weekYear',
        isInHeatMapPlanner: '=isInHeatMapPlanner',
    },
    link($scope)
    {
        $scope.organisationPlanning = {};
        $scope.isInCopyMode = false;
        $scope.showPopup = null;
        $scope.copiedPlanning = null;
        $scope.copiedPosition = null;
        $scope.deleteOld = false;
        $scope.weekYears = typeof $scope.weekYear !== 'undefined' ? getWeeks($scope.weekYear, 4) : getWeeks();
        $scope.showFloatingWeekNavigator = false;
        $scope.serieFilter = {};
        $scope.errors = [];

        this.isInHeatMapPlanner = typeof $scope.isInHeatMapPlanner !== 'undefined' ? $scope.isInHeatMapPlanner : false;

        $scope.resetSerieFilter = function () {
            $scope.serieFilter = {
                active: true,
                currentWeekYear: $scope.weekYear,
                minObjects: 0,
                maxObject: 150,
                searchString: '',
            };
        };

        /**
         * @param organisation
         * @param weekYears
         */
        $scope.showNextWeek = function (organisation) {
            if (organisation.isLocked) {
                return;
            }
            organisation.isLocked = true;

            const last = $scope.weekYears[$scope.weekYears.length - 1];

            $scope.weekYears.shift();
            $scope.weekYears.push(last.getNextWeek());

            $scope.getWeekYearComments($scope.weekYears);
            $scope.setPlanning(organisation, organisation.series);
        };

        /**
         * @param organisation
         * @param weekYears
         */
        $scope.showPreviousWeek = function (organisation) {
            if (organisation.isLocked) {
                return;
            }
            organisation.isLocked = true;

            const first = $scope.weekYears[0];

            $scope.weekYears.pop();
            $scope.weekYears.unshift(first.getPreviousWeek());

            $scope.getWeekYearComments($scope.weekYears);
            $scope.setPlanning(organisation, organisation.series);
        };

        $scope.showNextSeries = function (organisation) {
            $scope.offset += $scope.limit;
            $scope.setPlanning(organisation, null, true);
        };

        $scope.resetLazyLoad = function () {
            $scope.offset = 0;
            $scope.limit = 10;
            $scope.lazyLoadEnabled = true;
        };

        /**
         * @param organisation
         * @param weekYears
         * @param series
         */
        $scope.loadPlanning = function (organisation) {
            if (organisation.isLocked) {
                return;
            }

            organisation.isLocked = true;
            $scope.resetLazyLoad();
            $scope.resetSerieFilter();

            for (let i = 0; i < $scope.organisations.length; i++) {
                $scope.organisations[i].isShowable = false;

                delete $scope.organisations[i].revenue;
            }

            $scope.setPlanning(organisation);
        };

        /**
         * @param organisation
         * @param series
         */
        $scope.setPlanning = function (organisation, series, isScrolling) {
            series = series || [];

            $scope.currentWeekYear = $rootScope.getDateOfISOWeek(
                $scope.weekYears[0].week,
                $scope.weekYears[0].year,
            );

            if (series.length >= 1) {
                $scope.setOrganisationPlanning(organisation, series);
                return;
            }

            const options = {
                limit: $scope.limit,
                offset: $scope.offset,
            };

            if ($scope.serieFilter) {
                angular.extend(options, {query: JSON.stringify($scope.serieFilter)});
            }

            if ($scope.campaign) {
                angular.extend(options, {competitor: $scope.campaign.competitor});
            }

            const amountOfSeries = organisation.series.length;
            organisation.getSeries(options).then(() => {
                if (
                    $scope.offset
                    && (organisation.series.length < (amountOfSeries + $scope.limit))
                ) {
                    $scope.lazyLoadEnabled = false;
                }

                $scope.setOrganisationPlanning(
                    organisation,
                    organisation.newSeries,
                    isScrolling,
                );

                $scope.getWeekYearComments($scope.weekYears);
            });
        };

        $scope.setCustomStartDate = function (weekYear, confirmValue, serie) {
            const startDateOfWeek = weekYear.getStartDate();
            const endDateOfWeek = weekYear.getDateByDay(27);

            const startDateOfWeekMonth = startDateOfWeek.getMonth() + 1;
            const endDateOfWeekMonth = endDateOfWeek.getMonth() + 1;

            const startDateMonth = (startDateOfWeekMonth < 10) ? `0${startDateOfWeekMonth}` : startDateOfWeekMonth;
            const endDateMonth = (endDateOfWeekMonth < 10) ? `0${endDateOfWeekMonth}` : endDateOfWeekMonth;

            const startDateDay = (startDateOfWeek.getDate() < 10) ? `0${startDateOfWeek.getDate()}` : startDateOfWeek.getDate();
            const endDateDay = (endDateOfWeek.getDate() < 10) ? `0${endDateOfWeek.getDate()}` : endDateOfWeek.getDate();

            if (serie.isFromType('screen')) {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                $scope.confirmValue.startDateOn = `${startDateOfWeek.getFullYear()}-${startDateMonth}-${startDateDay}`;
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                $scope.confirmValue.endDateOn = `${endDateOfWeek.getFullYear()}-${endDateMonth}-${endDateDay}`;
            }
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            $scope.customMinDate = `${startDateOfWeek.getFullYear()}-${startDateMonth}-${startDateDay}`;
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            $scope.customMaxDate = `${endDateOfWeek.getFullYear()}-${endDateMonth}-${endDateDay}`;

            $scope.confirmValue = {
                startDateOn: $scope.customMinDate,
                endDateOn: $scope.customMaxDate,
                amount: 1,
                ...confirmValue,
            };
        };

        $scope.totalDaysController = [
            '$scope', function (innerScope) {
                innerScope.adSpotsRemaining = 0;

                innerScope.getTotalDays = function () {
                    const startDate = new Date(innerScope.confirmValue.startDateOn).getTime();
                    const endDate = new Date(innerScope.confirmValue.endDateOn).getTime();

                    return Math.trunc((endDate - startDate) / (24 * 3600 * 1000) + 1);
                };

                innerScope.adSpotsRemaining = innerScope.confirmValue.remainingAmount;
                innerScope.adSpotsTaken = innerScope.confirmValue.numberOfObjects
                    - innerScope.adSpotsRemaining;
            }];

        /**
         * @param organisation
         * @param serie
         * @param weekYear
         */
        $scope.openTriangleDialog = function (organisation, serie, weekYear) {
            $scope.setCustomStartDate(weekYear, {}, serie);

            let triangleContract = {};

            angular.forEach(organisation.contracts, (contract) => {
                if (contract.type === 'Losse Driehoek') {
                    triangleContract = contract;
                }
            });

            $scope.contract = triangleContract;

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/triangle-dialog.html',
                className: 'ngdialog-theme-default',
                scope: $scope,
                controller: $scope.totalDaysController,
            }).then((value) => {
                $scope.addPlanning(
                    organisation,
                    serie,
                    weekYear,
                    value.startDateOn,
                    value.endDateOn,
                    value.amount,
                );
            });
        };

        $scope.addPlanningPrecondition = function (
            organisation,
            serie,
            weekYear,
            startDateOn,
            endDateOn,
            amount,
        ) {
            const campaign = $scope.isInCopyMode ? $scope.copiedPlanning.campaign : $scope.campaign;

            if (
                (this.isInHeatMapPlanner && !$scope.isInCopyMode)
                || (
                    $rootScope.isDateInPast(serie.getDateOfChange(weekYear))
                    && serie.isWeekOfChange(weekYear)
                )
                || organisation.hasCampaignPlannedForSerieAndWeekYear(campaign, serie, weekYear)
            ) {
                return;
            }

            if (serie.isEndDateInPast(weekYear) || serie.isStartDateInFuture(weekYear)) {
                return;
            }

            if (serie.isFromType('stopper') && organisation.findPlanningForSerie(serie, campaign)) {
                $rootScope.errors.push({
                    message: 'Stopper serie van dit formaat is reeds gepland!',
                });
                return;
            }

            if (serie.isFromType('triangle') && ((startDateOn === null || endDateOn === null || amount === null) || (startDateOn === undefined || endDateOn === undefined || amount === undefined))) {
                $scope.openTriangleDialog(organisation, serie, weekYear);
                return;
            }

            if (serie.isFromType('custom', 'folders', 'screen', 'stopper')) {
                $scope.openSpecialDialog(organisation, serie, weekYear);
                return;
            }

            if (campaign.rate !== 'STOPPER') {
                $scope.addPlanning(
                    organisation,
                    serie,
                    weekYear,
                    startDateOn,
                    endDateOn,
                    amount,
                );
                return;
            }

            campaign.getStopperAvailableAdSpots(serie).then((response) => {
                let priceLevel = 'STOPPER';
                if (!response.available) {
                    $rootScope.errors.push({
                        message: 'Er zijn niet genoeg posters beschikbaar om als stopper te worden gepland',
                    });
                }

                $scope.addPlanning(
                    organisation,
                    serie,
                    weekYear,
                    startDateOn,
                    endDateOn,
                    amount,
                    priceLevel,
                );
            });
        };

        /**
         * @param organisation
         * @param serie
         * @param weekYear
         * @param startDateOn
         * @param endDateOn
         * @param amount
         */
        $scope.addPlanning = function (
            organisation,
            serie,
            weekYear,
            startDateOn,
            endDateOn,
            amount,
            priceLevel,
        ) {
            const campaign = $scope.isInCopyMode ? $scope.copiedPlanning.campaign : $scope.campaign;
            let description;
            const packageId = (
                $scope.isInCopyMode
                && $scope.copiedPlanning.campaign.package !== null
                    ? $scope.copiedPlanning.campaign.package.id : null
            );
            let campaignPlanning = $scope.getAddedPlanning(organisation, serie, weekYear);

            if ($scope.isInCopyMode) {
                priceLevel = $scope.copiedPlanning.priceLevel;
                if ($scope.copiedPlanning.serie) {
                    description = $scope.copiedPlanning.description;
                } else { // Options don't have series.
                    description = $scope.copiedPlanning.networkDescription;
                }
            }

            organisation.addPlanning(
                campaign,
                serie,
                weekYear,
                packageId,
                startDateOn,
                endDateOn,
                amount,
                priceLevel,
                description,
            ).then(
                () => {
                    campaignPlanning = organisation.findPlanningForSerieAndWeekYear(
                        serie,
                        weekYear,
                    );
                    $scope.addToPlanning(organisation, serie, weekYear, campaignPlanning);
                    if ($scope.isInCopyMode) {
                        if ($scope.copiedPlanning.serie) {
                            $scope.deletePlanning(
                                organisation,
                                $scope.copiedPlanning,
                                $scope.copiedPosition,
                            );
                        } else {
                            $scope.deleteOption($scope.copiedPlanning);
                        }
                    }
                    $rootScope.$broadcast('planning-changed');
                    $scope.updateStopperSeries(organisation);
                },
                () => {
                    campaignPlanning = organisation.findPlanningForSerieAndWeekYear(
                        serie,
                        weekYear,
                    );
                    $scope.addToPlanning(organisation, serie, weekYear, campaignPlanning);
                },
            ).finally(() => {
                $scope.isInCopyMode = false;
                $scope.copiedPlanning = null;
            });
        };

        /**
         *
         * @param organisation
         * @param serie
         * @param weekYear
         * @returns {Array.<campaignPlanning>}
         */
        $scope.getCampaignPlanningPerLine = function (organisation, serie, weekYear) {
            const recordPlanning = $scope.organisationPlanning[organisation.id].series[serie.id].weekYears;
            for (let i = 0; i < recordPlanning.length; i++) {
                if (recordPlanning[i].weekYear.isEqual(weekYear)) {
                    return recordPlanning[i].planning;
                }
            }
            return [];
        };

        /**
         * @param organisation
         * @param campaignPlanning
         * @param position
         */
        $scope.deletePlanning = async function (organisation, campaignPlanning, position, hideDialog) {
            if (campaignPlanning.hasStatus('LOCKED') || campaignPlanning.preferred || campaignPlanning.proclaimed) {
                return;
            }

            let doDelete = true;
            if (!hideDialog) {
                if ($scope.isInCopyMode) {
                    doDelete = $scope.deleteOld;
                } else {
                    doDelete = await bootboxConfirm('Let op!', 'U staat op het punt om deze geplande serie te verwijderen. Weet u het zeker?');
                }
            }

            if (!doDelete) {
                return;
            }

            $scope.addToPlanning(
                organisation,
                campaignPlanning.serie,
                campaignPlanning.weekYear,
                $scope.getRemovedPlanning(
                    organisation,
                    campaignPlanning.serie,
                    campaignPlanning.weekYear,
                    position,
                ),
            );

            organisation.removePlanning(campaignPlanning).then(
                () => {
                    if (
                        !$scope.isInCopyMode
                        && campaignPlanning.campaign
                        && $scope.campaign
                        && $scope.campaign.id === campaignPlanning.campaign.id
                    ) {
                        $rootScope.$broadcast('planning-changed');
                        $rootScope.$broadcast('campaignmaterials-changed', $scope.campaign.id);
                        $scope.updateStopperSeries(organisation);
                    }
                },
                () => {
                    $scope.addToPlanning(
                        organisation,
                        campaignPlanning.serie,
                        campaignPlanning.weekYear,
                        organisation.findPlanningForSerieAndWeekYear(
                            campaignPlanning.serie,
                            campaignPlanning.weekYear,
                        ),
                    );
                },
            );
        };

        /**
         * @param organisation
         * @param serie
         * @param weekYear
         * @param planning
         */
        $scope.addToPlanning = function (organisation, serie, weekYear, planning) {
            if (!(organisation.id in $scope.organisationPlanning)) {
                $scope.organisationPlanning[organisation.id] = {
                    series: {},
                };
            }

            if (!(serie.id in $scope.organisationPlanning[organisation.id].series)) {
                $scope.organisationPlanning[organisation.id].series[serie.id] = {
                    serie,
                    weekYears: [],
                };
            }

            const recordPlanning = $scope.organisationPlanning[organisation.id].series[serie.id].weekYears;
            for (let i = 0; i < recordPlanning.length; i++) {
                if (recordPlanning[i].weekYear.isEqual(weekYear)) {
                    $scope.organisationPlanning[organisation.id].series[serie.id].weekYears[i].planning = planning;
                    return;
                }
            }

            $scope.organisationPlanning[organisation.id].series[serie.id].weekYears.push({
                planning,
                weekYear,
            });
        };

        /**
         * @param organisationKey
         */
        $scope.removeOrganisation = function (organisationKey) {
            if (!(organisationKey in $scope.organisations && confirm('Weet u zeker dat u deze gemeente wilt verwijderen? Alle planningen van deze campagne binnen deze gemeente zullen worden verwijderd.'))) {
                return;
            }

            const organisation = $scope.organisations[organisationKey];
            const planning = $scope.organisationPlanning[organisation.id];
            $scope.organisations.remove(organisationKey);
            delete $scope.organisationPlanning[organisation.id];
            $scope.campaign.removePlanningByOrganisation(organisation).then(
                () => {
                    $rootScope.$broadcast('planning-changed');
                },
                () => {
                    $scope.organisations.push(organisation);
                    $scope.organisationPlanning[organisation.id] = planning;
                },
            );
        };

        /**
         * Enables copy mode for a certain planning
         * @param planning
         */
        $scope.setCopyMode = function (planning, position, deleteOld) {
            if (deleteOld && (planning.isLocked() || planning.preferred || planning.proclaimed)) {
                return;
            }

            $scope.copiedPlanning = planning;
            $scope.copiedPosition = position;
            $scope.isInCopyMode = !$scope.isInCopyMode;
            $scope.deleteOld = deleteOld;
        };

        /**
         * Checks if we are in copy mode
         * @param planning
         * @returns {boolean}
         */
        $scope.inCopyMode = function (planning) {
            return !!($scope.isInCopyMode && planning === $scope.copiedPlanning);
        };

        /**
         * @param organisation
         * @param serie
         * @param weekYear
         * @returns {Array.<campaignPlanning>}
         */
        $scope.getAddedPlanning = function (organisation, serie, weekYear) {
            const campaignPlanning = $scope.getCampaignPlanningPerLine(
                organisation,
                serie,
                weekYear,
            );
            campaignPlanning.pop();
            campaignPlanning.push({campaign: $scope.campaign});

            if (serie.isFromType('scroller', 'custom', 'screen', 'folders', 'stopper')) {
                campaignPlanning.push(null);
            }

            return campaignPlanning;
        };

        /**
         * @param organisation
         * @param serie
         * @param weekYear
         * @param position
         * @returns {Array.<campaignPlanning>}
         */
        $scope.getRemovedPlanning = function (organisation, serie, weekYear, position) {
            const campaignPlanning = $scope.getCampaignPlanningPerLine(
                organisation,
                serie,
                weekYear,
            );
            if (serie.isFromType('scroller')) {
                campaignPlanning.remove(position);
            } else if (serie.isFromType('custom', 'screen', 'folders')) {
                campaignPlanning.remove(position);
            } else {
                campaignPlanning[position] = null;
            }

            return campaignPlanning;
        };

        $scope.openPopup = function (type, id) {
            $scope.hidePopup();
            if (type === 'planningDescription') {
                $scope.showPlanningDescriptionPopup = id;
            } else if (type === 'weekComment') {
                $scope.showWeekCommentPopup = id;
            } else if (type === 'optionNetworkDescription') {
                $scope.showOptionNetworkDescriptionPopup = id;
            }
        };

        $scope.hidePopup = function () {
            $scope.showPlanningDescriptionPopup = null;
            $scope.showWeekCommentPopup = null;
            $scope.showOptionNetworkDescriptionPopup = null;
        };

        /**
         * @param {Organisation} organisation
         * @param {Array<Serie>}series
         * @param {boolean} keepPlanning
         * @param {boolean} makeVisible
         */
        $scope.setOrganisationPlanning = function (
            organisation,
            series,
            keepPlanning,
            makeVisible = true,
        ) {
            organisation.getCampaignPlanning($scope.weekYears, series).then(() => {
                if (!keepPlanning) {
                    delete $scope.organisationPlanning[organisation.id];
                }
                angular.forEach(series, (serie) => {
                    angular.forEach($scope.weekYears, (weekYear) => {
                        $scope.addToPlanning(
                            organisation,
                            serie,
                            weekYear,
                            organisation.findPlanningForSerieAndWeekYear(serie, weekYear),
                        );
                    });
                });

                // Only load planningoptions if we're clearing planner like on a fresh load
                // or sideskipping weeks, but not on scrolling down)
                if (!keepPlanning) {
                    organisation.getPlanningOptions($scope.weekYears).finally(() => {
                        organisation.isShowable = makeVisible;
                    });
                } else {
                    organisation.isShowable = makeVisible;
                }
            }).finally(() => {
                organisation.isLocked = false;
            });
        };

        /**
         * @returns {boolean}
         *
         * @TODO: Move to campaignPlanning entity.
         */
        $scope.isLockable = function () {
            return true;
        };

        /**
         * Gets an active organisation
         * @returns {*}
         */
        $scope.getActiveOrganisation = function () {
            const organisation = $scope.organisations.find((item) => item.isShowable);

            return typeof organisation !== 'undefined' ? organisation : null;
        };

        /**
         * Set weekYears to the chosen week/year in the calendar
         * @param value
         */
        $scope.setCurrentWeekYear = function (value) {
            if (value === null) {
                return;
            }

            $scope.weekYears = getWeeks(
                new WeekYear(value.getWeek(), value.getFullYear()),
                4,
            );
        };

        /**
         * Lock a planning (disables editing and deleting)
         * @param planning
         */
        $scope.lockPlanning = function (planning) {
            if (!$scope.isLockable()) {
                return;
            }

            planning.setLocked(!planning.hasStatus('LOCKED'));
        };

        /**
         * Set a planning to preferred (hides delete button)
         * @param planning
         */
        $scope.preferPlanning = function (planning) {
            if (!planning.hasStatus('LOCKED')) {
                planning.setPreferred();
            }
        };

        /**
         *
         * @param campaign
         * @param organisation
         * @param weekYear
         * @param serie
         * @param priceLevel
         * @param planning
         * @param position
         */
        $scope.createPlanningOption = function (
            campaign,
            organisation,
            weekYear,
            serie,
            priceLevel,
            planning,
            position,
        ) {
            $scope.getPriceForOption(planning, weekYear, serie, priceLevel).then((priceForOption) => {
                $scope.confirmValue = {
                    id: null,
                    description: serie.name,
                    numberOfObjects: serie.numberOfObjects,
                    objectAmount: priceForOption,
                    objectType: serie.objectTypes[0],
                    dayOfChange: serie.dayOfChange,
                };

                if (typeof planning !== 'undefined') {
                    $scope.confirmValue.networkDescription = planning.description;
                }

                $scope.maxOptionCharacters = 50;
                $scope.campaignName = campaign.name;
                $scope.week = weekYear.week;

                // If we are copying an existing planning, we want to delete it afterwards.
                if (typeof position !== 'undefined' && typeof planning !== 'undefined') {
                    $scope.doDelete = true;
                }

                ngDialog.openConfirm({
                    template: '/build/html/partials/ngDialogTemplates/option-dialog.html',
                    className: 'ngdialog-theme-default',
                    scope: $scope,
                }).then((value) => {
                    $scope.addOption(campaign, organisation, weekYear, value);

                    if ($scope.doDelete) {
                        $scope.deletePlanning(organisation, planning, position, true);
                    }
                });
            });
        };

        /**
         * Get the price for an option based on week, year, priceLevel and serie
         * @param planning
         * @param weekYear
         * @param serie
         * @param priceLevel
         * @returns {Promise}
         */
        $scope.getPriceForOption = function (planning, weekYear, serie, priceLevel) {
            return new Promise((resolve) => {
                if (planning) {
                    return resolve(planning.objectAmount);
                }

                return SerieService.getPriceForPlanning({
                    serieId: serie.id,
                    week: weekYear.week,
                    year: weekYear.year,
                    priceLevel,
                }, (response) => {
                    resolve(response.price);
                });
            });
        };

        /**
         * Creates a new option with it's object information
         * @param campaign
         * @param organisation
         * @param weekYear
         * @param objectValues
         */
        $scope.addOption = function (campaign, organisation, weekYear, objectValues) {
            const planningOption = new PlanningOption();
            planningOption.campaign = campaign;
            planningOption.weekYear = weekYear;

            if (objectValues !== null) {
                planningOption.numberOfObjects = objectValues.numberOfObjects;
                planningOption.objectAmount = objectValues.objectAmount;
                planningOption.objectType = objectValues.objectType;
                planningOption.description = objectValues.description;
                planningOption.networkDescription = objectValues.networkDescription;
                planningOption.dayOfChange = objectValues.dayOfChange;
            }

            if ($scope.isInCopyMode && $scope.copiedPlanning.campaign.package !== null) {
                planningOption.package = $scope.copiedPlanning.campaign.package;
            }

            organisation.addOption(planningOption).then(
                () => {
                    $rootScope.$broadcast('planning-changed');
                },
            ).finally(() => {
                $scope.isInCopyMode = false;
                $scope.copiedPlanning = null;
            });
        };

        /**
         * Opens the edit option dialog
         * @param planningOption
         */
        $scope.editPlanningOption = function (planningOption) {
            $scope.confirmValue = {
                id: planningOption.id,
                description: planningOption.description,
                numberOfObjects: planningOption.numberOfObjects,
                objectAmount: planningOption.objectAmount,
                objectType: planningOption.objectType,
            };

            $scope.maxOptionCharacters = 50;
            $scope.campaignName = planningOption.campaign.name;

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/option-dialog.html',
                className: 'ngdialog-theme-default',
                scope: $scope,
            }).then((value) => {
                $scope.updateOption(planningOption, value);
            });
        };

        /**
         * Update an existing option
         * @param planningOption
         * @param objectValues
         */
        $scope.updateOption = function (planningOption, objectValues) {
            planningOption.numberOfObjects = objectValues.numberOfObjects;
            planningOption.objectAmount = objectValues.objectAmount;
            planningOption.objectType = objectValues.objectType;
            planningOption.description = objectValues.description;

            planningOption.save();
        };

        /**
         * Create new option from an existing planning
         * @param planning
         * @param organisation
         * @param position
         */
        $scope.transformToOption = function (planning, organisation, position) {
            if (planning.hasStatus('LOCKED') || planning.preferred || planning.proclaimed) {
                return;
            }

            SerieService.get({id: planning.serie.id}, (data) => {
                $scope.createPlanningOption(
                    planning.campaign,
                    organisation,
                    planning.weekYear,
                    new Serie(data),
                    planning.priceLevel,
                    planning,
                    position,
                );
            });
        };

        /**
         * Open special planning dialog or open campaign detail card
         */
        $scope.openPlanning = function (planning, organisation) {
            if (planning && planning.serie && planning.serie.type && (planning.serie.type === 'custom' || planning.serie.type === 'folders')) {
                $scope.editSpecialPlanning(planning, organisation);
            }
        };

        /**
         * Deletes an option
         * @param planningOption
         */
        $scope.deleteOption = function (planningOption) {
            if (!confirm('Wilt u deze optie verwijderen?')) {
                return;
            }

            planningOption.organisation.deleteOption(planningOption).then(
                () => {
                    $rootScope.$broadcast('planning-changed');
                },
            );
        };

        /**
         * @param organisation
         * @param serie
         * @param weekYear
         */
        $scope.openSpecialDialog = function (organisation, serie, weekYear) {
            const campaign = $scope.isInCopyMode ? $scope.copiedPlanning.campaign : $scope.campaign;

            $scope.confirmValue = {
                id: null,
                quoteDescription: '',
                numberOfObjects: 1,
                objectAmount: '',
                remainingAmount: 1,
                startDateOn: null,
                endDateOn: null,
            };

            $scope.objectTypes = $rootScope.objectTypes;
            $scope.maxOptionCharacters = 50;
            $scope.campaignName = campaign.name;

            let template = '';
            if (serie.isFromType('custom')) {
                template = '/build/html/partials/ngDialogTemplates/custom-dialog.html';
            } else if (serie.isFromType('folders')) {
                template = '/build/html/partials/ngDialogTemplates/folders-dialog.html';
            } else if (serie.isFromType('screen')) {
                $scope.setCustomStartDate(weekYear, $scope.confirmValue, serie);

                template = '/build/html/partials/ngDialogTemplates/screen-dialog.html';
            } else if (serie.isFromType('stopper')) {
                template = '/build/html/partials/ngDialogTemplates/stopper-dialog.html';
            }

            ngDialog.openConfirm({
                template,
                className: 'ngdialog-theme-default',
                scope: $scope,
                controller: $scope.totalDaysController,
            }).then((values) => {
                $scope.addSpecialPlanning(serie, organisation, weekYear, values);
            });
        };

        /**
         *
         * @param {Serie} serie
         * @param {Organisation} organisation
         * @param {WeekYear} weekYear
         * @param values
         */
        $scope.addSpecialPlanning = function (serie, organisation, weekYear, values) {
            const campaign = $scope.isInCopyMode ? $scope.copiedPlanning.campaign : $scope.campaign;

            if (
                (this.isInHeatMapPlanner && !$scope.isInCopyMode)
                || (
                    $rootScope.isDateInPast(serie.getDateOfChange(weekYear))
                    && serie.isWeekOfChange(weekYear)
                )
                || organisation.hasCampaignPlannedForSerieAndWeekYear(campaign, serie, weekYear)
            ) {
                return;
            }

            if (serie.isEndDateInPast(weekYear)) {
                return;
            }

            const packageId = (
                $scope.isInCopyMode && $scope.copiedPlanning.campaign.package !== null
                    ? $scope.copiedPlanning.campaign.package.id
                    : null
            );

            $scope.getAddedPlanning(organisation, serie, weekYear);

            organisation.addSpecialPlanning(campaign, serie, weekYear, packageId, values).then(
                () => {
                    $scope.addToPlanning(
                        organisation,
                        serie,
                        weekYear,
                        organisation.findPlanningForSerieAndWeekYear(serie, weekYear),
                    );
                    if ($scope.isInCopyMode) {
                        $scope.deletePlanning(
                            organisation,
                            $scope.copiedPlanning,
                            $scope.copiedPosition,
                        );
                        if (!$scope.deleteOld) {
                            $rootScope.$broadcast('planning-changed');
                            $scope.updateStopperSeries(organisation);
                        }
                    } else {
                        $rootScope.$broadcast('planning-changed');
                        $scope.updateStopperSeries(organisation);
                    }
                },
                () => {
                    $scope.addToPlanning(
                        organisation,
                        serie,
                        weekYear,
                        organisation.findPlanningForSerieAndWeekYear(serie, weekYear),
                    );
                },
            ).finally(() => {
                $scope.isInCopyMode = false;
                $scope.copiedPlanning = null;
            });
        };

        /**
         * Opens the edit special planning dialog
         * @param planning
         * @param organisation
         */
        $scope.editSpecialPlanning = function (planning, organisation) {
            $scope.confirmValue = {
                id: planning.id,
                serieId: planning.serie.id,
                quoteDescription: planning.quoteDescription,
                numberOfObjects: planning.amount,
                remainingAmount: planning.remainingAmount,
                objectAmount: planning.objectAmount / 100,
            };

            $scope.maxOptionCharacters = 50;
            $scope.campaignName = planning.campaign.name;

            if (planning.startDateOn && planning.endDateOn) {
                $scope.confirmValue.startDateOn = $scope.reformatDate(planning.startDateOn);
                $scope.confirmValue.endDateOn = $scope.reformatDate(planning.endDateOn);
            }

            let template = null;
            if (planning.serie.isFromType('custom')) {
                template = '/build/html/partials/ngDialogTemplates/custom-dialog.html';
            } else if (planning.serie.isFromType('folders')) {
                template = '/build/html/partials/ngDialogTemplates/folders-dialog.html';
            } else if (planning.serie.isFromType('screen')) {
                template = '/build/html/partials/ngDialogTemplates/screen-dialog.html';
            } else if (planning.serie.isFromType('stopper')) {
                template = '/build/html/partials/ngDialogTemplates/stopper-dialog.html';
            }

            if (!template) {
                return;
            }

            ngDialog.openConfirm({
                template,
                className: 'ngdialog-theme-default',
                scope: $scope,
                controller: $scope.totalDaysController,
            }).then((values) => {
                if (planning.serie.isFromType('screen', 'stopper')) {
                    $scope.updateCustomPlanning(values, planning);
                }
                $scope.updateSpecialPlanning(organisation, planning, values);
            });
        };

        $scope.editPlanning = function (serie, planning, organisation) {
            if (serie.type === 'triangle') {
                return $scope.editTriangleDialog(planning, organisation);
            }

            // Already handled in the open
            if (planning && planning.serie && planning.serie.type && (planning.serie.type === 'custom' || planning.serie.type === 'folders')) {
                return;
            }

            return $scope.editSpecialPlanning(planning, organisation);
        };

        /**
         * Reformat the given date from 01-01-2020 to 2020-01-01 and vice-versa
         *
         * @param {string} date
         */
        $scope.reformatDate = function (date) {
            return date.split('-').reverse().join('-');
        };

        /**
         *
         * @param {Object} value
         * @param {CampaignPlanning} planning
         */
        $scope.updateCustomPlanning = function (values, planning) {
            planning.startDateOn = $scope.reformatDate(values.startDateOn);
            planning.endDateOn = $scope.reformatDate(values.endDateOn);
            planning.objectAmount = values.objectAmount;
            planning.amount = values.amount;
        };

        $scope.updateTrianglePlanning = function (value, planning) {
            planning.startDateOn = $scope.reformatDate(value.startDateOn);
            planning.endDateOn = $scope.reformatDate(value.endDateOn);
            planning.amount = value.amount;
        };

        $scope.editTriangleDialog = function (planning, organisation) {
            $scope.confirmValue = {
                startDateOn: $scope.reformatDate(planning.startDateOn),
                endDateOn: $scope.reformatDate(planning.endDateOn),
                amount: planning.amount,
            };

            let triangleContract = {};

            angular.forEach(organisation.contracts, (contract) => {
                if (contract.type === 'Losse Driehoek') {
                    triangleContract = contract;
                }
            });

            $scope.contract = triangleContract;

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/triangle-dialog.html',
                className: 'ngdialog-theme-default',
                scope: $scope,
                controller: $scope.totalDaysController,
            }).then((value) => {
                $scope.updateTrianglePlanning(value, planning);

                $scope.getActiveOrganisation().updatePlanning(planning);
            });
        };

        /**
         * Update an existing SpecialPlanning
         * @param planning
         * @param values
         */
        $scope.updateSpecialPlanning = function (organisation, planning, values) {
            planning.quoteDescription = values.quoteDescription;
            planning.amount = values.numberOfObjects;
            planning.objectAmount = (values.objectAmount * 100).toFixed(2);

            organisation.updatePlanning(planning).then((updated) => {
                planning = updated;

                $rootScope.$broadcast('planning-changed');
                $scope.updateStopperSeries(organisation);
            });
        };

        /**
         * Update straight from the planning
         * @param {CampaignPlanning} planning
         */
        $scope.updateInlinePlanning = function (planning) {
            let preconditions = Promise.resolve(true);
            if (planning.campaign.rate === 'STOPPER' && planning.priceLevel === 'STOPPER') {
                preconditions = CampaignPlanningService.getStopperAvailableAdSpots({
                    id: planning.id,
                }, (response) => {
                    if (!response.available) {
                        $rootScope.errors.push({
                            message: 'Er zijn geen posters beschikbaar in dit formaat als stopper',
                        });
                    }

                    return response.available;
                }).$promise;
            }

            return preconditions.then((update) => {
                if (!update) {
                    return;
                }

                planning.update().then(() => {
                    $rootScope.$broadcast('planning-changed');
                    $scope.updateStopperSeries($scope.getActiveOrganisation());
                });
            });
        };

        /**
         * Get comment per week and add them to the weekYear object
         * @param weekYears
         */
        $scope.getWeekYearComments = function (weekYears) {
            const params = {
                requestParams: decodeURIComponent($.param({
                    weekYears: weekYears.map((weekYear) => weekYear.getSimpleObject()),
                })),
            };

            CommentService.getByWeekYear(params).$promise.then((comments) => {
                angular.forEach($scope.weekYears, (weekYear) => {
                    angular.forEach(comments, (data) => {
                        const comment = new Comment(data);
                        if (weekYear.week === comment.week) {
                            weekYear.setComment(comment);
                        }
                    });

                    if (weekYear.comment === null) {
                        const comment = new Comment();
                        comment.type = 'weekyear';
                        comment.week = weekYear.week;
                        comment.year = weekYear.year;
                        weekYear.setComment(comment);
                    }
                });
            });
        };

        $scope.showWeekAndChangeDayInformation = function (serie, weekYear, position) {
            if ($scope.timeout) {
                $timeout.cancel($scope.timeout);
            }

            $scope.timeout = $timeout(() => {
                $scope.hoverInformation = null;
                if (serie && weekYear && serie.isWeekOfChange(weekYear)) {
                    $scope.hoverInformation = {
                        serieId: serie.id,
                        week: weekYear.week,
                        dayOfChange: serie.getDateOfChange(weekYear),
                        position,
                    };
                }
            }, 500);
        };

        $scope.checkPlannerBodyScrollPosition = function () {
            setInterval(() => {
                const plannerBody = document.getElementById('planner-body');
                if (plannerBody) {
                    $scope.showFloatingWeekNavigator = plannerBody.getBoundingClientRect().top <= 0;
                }
            }, 250);
        };

        /**
         * @param {Organisation} visibleOrganisation
         */
        $scope.updateStopperSeries = function (visibleOrganisation) {
            let maatwerkId = null;
            Object.keys($scope.organisationPlanning).forEach((o) => {
                const item = $scope.organisationPlanning[o];
                if (
                    typeof Object.values(item.series).find((serie) => serie.serie.isFromType('stopper')) !== 'undefined'
                ) {
                    maatwerkId = o;
                }
            });

            if (maatwerkId && maatwerkId !== visibleOrganisation.id) {
                const organisation = $scope.organisations.find((item) => (
                    item.id === parseInt(maatwerkId, 10)
                ));
                const series = Object.values($scope.organisationPlanning[maatwerkId].series).map((item) => item.serie);

                organisation.campaignPlanning = [];
                $scope.setOrganisationPlanning(organisation, series, false, organisation.isShowable);
            }
        };

        $scope.$on('filter-changed', ($event, filter) => {
            $scope.resetLazyLoad();
            $scope.getWeekYearComments($scope.weekYears);
            $scope.serieFilter = filter;
            $scope.setCurrentWeekYear(filter.currentWeekYear);
            $scope.setPlanning($scope.getActiveOrganisation());
        });

        $scope.resetLazyLoad();
        $scope.resetSerieFilter();
        $scope.checkPlannerBodyScrollPosition();

        if ($scope.organisations.length === 1) {
            $scope.setPlanning($scope.organisations[0]);
        }
    },
}));

$(function () {
    $('body')
        .tooltip({ selector: '[data-toggle=tooltip]' }, { placement: 'bottom' });
});
