angular.module('siyfion.sfTypeahead', [])
    .directive('sfTypeahead', () => ({
        restrict: 'AC', // Only apply on an attribute or class
        scope: {
            value: '=ngModel', // The two-way data bound value that is returned by the directive
            options: '=', // The typeahead configuration options (https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#options)
            datasets: '=', // The typeahead datasets to use (https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#datasets)
        },
        link(scope, directiveElement) {
            directiveElement = $(directiveElement);

            // Create the typeahead on the element
            directiveElement.typeahead(scope.options, scope.datasets);

            function getCursorPosition(element)
            {
                let position = 0;
                element = element[0];

                // IE Support.
                if (document.selection) {
                    const range = document.selection.createRange();
                    range.moveStart('character', -element.value.length);

                    position = range.text.length;
                } else if (typeof element.selectionStart === 'number') {
                    // Other browsers.
                    position = element.selectionStart;
                }
                return position;
            }

            function setCursorPosition(element, position)
            {
                element = element[0];
                if (document.selection) {
                    const range = element.createTextRange();
                    range.move('character', position);
                    range.select();
                } else if (typeof element.selectionStart === 'number') {
                    element.focus();
                    element.setSelectionRange(position, position);
                }
            }

            function updateScope(object, suggestion)
            {
                // for some reason $apply will place [Object] into element, this hacks around it
                const preserveVal = directiveElement.val();
                scope.$apply(() => {
                    scope.value = suggestion;
                });
                directiveElement.val(preserveVal);
            }

            // Update the value binding when a value is manually selected from the dropdown.
            directiveElement.bind('typeahead:selected', (object, suggestion) => {
                updateScope(object, suggestion);
                scope.$emit('typeahead:selected');
            });

            // Update the value binding when a query is autocompleted.
            directiveElement.bind('typeahead:autocompleted', (object, suggestion) => {
                updateScope(object, suggestion);
                scope.$emit('typeahead:autocompleted');
            });

            // Propagate the opened event
            directiveElement.bind('typeahead:opened', () => {
                scope.$emit('typeahead:opened');
            });

            // Propagate the closed event
            directiveElement.bind('typeahead:closed', () => {
                scope.$emit('typeahead:closed');
            });

            // Propagate the cursorchanged event
            directiveElement.bind('typeahead:cursorchanged', (event, suggestion) => {
                scope.$emit('typeahead:cursorchanged', event, suggestion);
            });

            // Update the value binding when the user manually enters some text
            directiveElement.bind('input', () => {
                const preservePos = getCursorPosition(directiveElement);
                scope.$apply(() => {
                    const value = directiveElement.val();
                    scope.value = value;
                });
                setCursorPosition(directiveElement, preservePos);
            });
        },
    }));
