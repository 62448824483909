import {entities, WeekYear} from '../app';

entities.factory('PlanningOption', [
    '$injector', 'Package', 'PlanningOptionService', '$rootScope', function ($injector, Package, PlanningOptionService, $rootScope) {
        return function (data, handleCampaign, handleOrganisation) {
            data = data || {};
            this.id = data.id;
            this.campaign = null;
            this.package = null;
            this.organisation = null;
            this.weekYear = data.weekYear
                ? new WeekYear(data.weekYear.week, data.weekYear.year)
                : new WeekYear(data.week, data.year);

            if (data.package) {
                this.package = new Package(data.package);
            }

            this.numberOfObjects = data.numberOfObjects;
            this.objectAmount = data.objectAmount;
            this.objectType = data.objectType;
            this.description = data.description;
            this.user = data.user;
            this.proclaimed = data.proclaimed;
            this.networkDescription = data.networkDescription;
            this.dayOfChange = data.dayOfChange ? data.dayOfChange : 0;

            let self = this;

            /**
             * @param campaign
             */
            this.setCampaign = function (campaign) {
                this.campaign = campaign;
            };

            /**
             * @param {Package} pkg
             */
            this.setPackage = function (pkg) {
                this.package = pkg;
            };

            /**
             * @param organisation
             */
            this.setOrganisation = function (organisation) {
                this.organisation = organisation;
            };

            /**
             * @param campaign
             * @returns {boolean}
             */
            this.isFromCampaign = function (campaign) {
                return this.campaign.id === campaign.id;
            };

            /**
             * @returns {*}
             */
            this.delete = function () {
                return PlanningOptionService.delete(
                    {
                        id: this.id,
                    },
                    {},
                    () =>
                    {
                        console.log('removing option success');
                        $rootScope.$broadcast('planning-changed');
                    },
                    (reason) =>
                    {
                        console.log('removing option failed: ', reason);
                    },
                ).$promise;
            };

            /**
             * @returns {*}
             */
            this.save = function () {
                self = this;

                if (this.id) {
                    return PlanningOptionService.update(
                        {
                            id: this.id,
                        },
                        {
                            organisationId: this.organisation.id,
                            campaignId: this.campaign.id,
                            week: this.weekYear.week,
                            year: this.weekYear.year,
                            packageId: this.package ? this.package.id : null,
                            numberOfObjects: this.numberOfObjects,
                            objectAmount: this.objectAmount,
                            objectType: this.objectType,
                            description: this.description,
                            proclaimed: this.proclaimed,
                            networkDescription: this.networkDescription,
                            dayOfChange: this.dayOfChange,
                        },
                        (response) =>
                        {
                            self.numberOfObjects = response.numberOfObjects;
                            self.objectAmount = response.objectAmount;
                            self.objectType = response.objectType;
                            self.description = response.description;
                            self.user = response.user;
                            self.proclaimed = response.proclaimed;
                            self.networkDescription = response.networkDescription;
                            self.dayOfChange = response.dayOfChange;
                            $rootScope.$broadcast('planning-changed');
                        },
                    ).$promise;
                }
                return PlanningOptionService.save(
                    {},
                    {
                        organisationId: this.organisation.id,
                        campaignId: this.campaign.id,
                        week: this.weekYear.week,
                        year: this.weekYear.year,
                        packageId: this.package ? this.package.id : null,
                        numberOfObjects: this.numberOfObjects,
                        objectAmount: this.objectAmount,
                        objectType: this.objectType,
                        description: this.description,
                        networkDescription: this.networkDescription,
                        dayOfChange: this.dayOfChange,
                    },
                    (response) =>
                    {
                        self.id = response.id;
                        self.numberOfObjects = response.numberOfObjects;
                        self.objectAmount = response.objectAmount;
                        self.objectType = response.objectType;
                        self.description = response.description;
                        self.user = response.user;
                        self.networkDescription = response.networkDescription;
                        self.dayOfChange = response.dayOfChange;
                        $rootScope.$broadcast('planning-changed');
                    },
                ).$promise;
            };

            /**
             * @param weekYear
             */
            this.setWeekYear = function (weekYear) {
                this.weekYear = weekYear;
            };

            this.proclaim = function ($event) {
                $event.stopPropagation();
                this.proclaimed = !this.proclaimed;
                this.save();
            };

            if (handleCampaign !== false && data.campaign) {
                const Campaign = $injector.get('Campaign');
                this.campaign = new Campaign(data.campaign);
                this.campaign.package = this.package;
            }

            if (handleOrganisation !== false && data.organisation) {
                // this.setOrganisation(data.organisation);
                const Organisation = $injector.get('Organisation');
                this.organisation = new Organisation(data.organisation);
            }
        };
    }]);
