import {services} from '../app';

services.factory('InspectionService', ($resource) => $resource('/inspections/:id/:action/:actionId', { id: '@id' }, {
    query: {
        method: 'GET', url: '/inspections', isArray: false, cancellable: true,
    },
    update: { method: 'PUT' },
    getInspectionPhotos: { method: 'GET', params: { action: 'photos' }, isArray: true },
    deleteImage: { method: 'DELETE', params: { action: 'images' }, isArray: false },
    assign: { method: 'PATCH', params: { action: 'assign' }, isArray: false },
    invoice: { method: 'PATCH', params: { action: 'invoice' }, isArray: false },
    approve: { method: 'PATCH', params: { action: 'approve' }, isArray: false },
    finalize: { method: 'PATCH', params: { action: 'finalize' }, isArray: false },
    disapprove: { method: 'PATCH', params: { action: 'disapprove' }, isArray: false },
    addComment: { method: 'PATCH', params: { action: 'comment' }, isArray: false },
    createCase: { method: 'POST', params: { action: 'cases' }, isArray: false },
}));
