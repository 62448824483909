import {directives} from '../app';
// phpcs:ignoreFile
directives.directive('materialLine', ['$rootScope', 'CampaignService', 'CampaignMaterials', 'OrganisationService', function ($rootScope, CampaignService, CampaignMaterials, OrganisationService) {
    return {
        restrict: 'A',
        templateUrl: '/build/html/partials/material-line.html',
        scope: {
            totalMaterials: '=materialLine',
            campaign: '=campaign',
        },

        link($scope)
        {
            $scope.materialsList = [];
            $scope.gettingMaterials = true;
            $scope.totalMaterials = new CampaignMaterials($scope.totalMaterials);

            $scope.getCampaignMaterials = function () {
                CampaignService.getCampaignMaterials(
                    {
                        id: $scope.campaign.id,
                    },
                    (campaignMaterials) =>
                    {
                        $scope.materialsList = [];
                        angular.forEach(
                            campaignMaterials,
                            (row) =>
                            {
                                if (row.id && row.size[0] === $scope.totalMaterials.size[0]) {
                                    row.deliveredDate = new Date(row.deliveredDate);

                                    $scope.materialsList.push(new CampaignMaterials(row));
                                }
                            });
                        $scope.addMaterials();
                        $scope.gettingMaterials = false;
                    });
            };

            $scope.addMaterials = function () {
                const materials = new CampaignMaterials();
                materials.campaign = {
                    id: $scope.campaign.id,
                };
                materials.size = $scope.totalMaterials.size[0];

                $scope.materialsList.push(materials);
            };

            $scope.removeMaterials = function (materials, $index) {
                materials.delete()
                    .then(() => {
                        $scope.materialsList.splice($index, 1);
                        $scope.broadcastMaterialsChanged();
                    });
            };

            $scope.save = function (materials, addNewLine) {
                if ($scope.sum('desiredAmount') > 0) {
                    $scope.totalMaterials.desiredAmount = 0;
                }
                materials.save()
                    .then(() => {
                        if (addNewLine) {
                            $scope.addMaterials();
                        }
                        $scope.broadcastMaterialsChanged();
                    });
            };

            $scope.sum = function (index) {
                let total = 0;
                angular.forEach($scope.materialsList, (item) => {
                    total += parseInt(item[index], 10);
                });
                return total;
            };

            $scope.getDistributors = function () {
                OrganisationService.query({businessType: 'distributor'}, (response) => {
                    $scope.distributors = response;
                });
            };

            $scope.saveDesired = function () {
                $scope.totalMaterials.save()
                    .then(() => {
                        $scope.broadcastMaterialsChanged();
                    });
            };

            $scope.broadcastMaterialsChanged = function () {
                if (!$scope.isInPlanner) {
                    $rootScope.$broadcast('campaignmaterials-changed', $scope.campaign.id);
                }
            };

            $scope.getDistributors();
            $scope.getCampaignMaterials();
        },
    };
}]);
