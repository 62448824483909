import {directives} from '../app';

directives.directive('card', () => ({
    restrict: 'E',
    templateUrl: '/build/html/partials/directives/card-detail.html',
    scope: {
        object: '=',
        type: '@',
        template: '=',
    },
}));
