import {directives} from '../app';

directives.directive('campaignPhotos', ['CampaignService', function (CampaignService) {
    return {
        restrict: 'E',
        templateUrl: '/build/html/partials/directives/campaign-photos.html',
        scope: {
            campaign: '=',
            title: '=',
        },
        link($scope) {
            $scope.images = [];
            $scope.loaded = false;

            $scope.getMaterialPhotos = function () {
                $scope.loaded = false;
                $scope.images = [];

                if (!$scope.campaign || !$scope.campaign.id) {
                    $scope.loaded = true;
                    return;
                }

                CampaignService.getMaterialPhotos({ id: $scope.campaign.id }, (response) => {
                    $scope.loaded = true;
                    $scope.currentIndex = 0;
                    $scope.images = response;
                    $scope.updateView();
                });
            };

            $scope.nextImage = function () {
                if ($scope.currentIndex < $scope.images.length - 1) {
                    $scope.currentIndex++;
                    return;
                }
                $scope.currentIndex = 0;
            };

            $scope.previousImage = function () {
                if ($scope.currentIndex > 0) {
                    $scope.currentIndex--;
                    return;
                }

                $scope.currentIndex = $scope.images.length - 1;
            };

            $scope.updateView = function () {
                if (!$scope.images.length) {
                    return;
                }

                for (let i = 0; i < $scope.images.length; i++) {
                    $scope.images[i].visible = false;
                }

                $scope.images[$scope.currentIndex].visible = true;
            };

            $scope.$watch('currentIndex', $scope.updateView);

            $scope.$watch('campaign', $scope.getMaterialPhotos);
        },
    };
}]);
