/**
 * See: https://github.com/angular/angular.js/issues/1286
 */

import {filters} from '../app';

filters.filter('toArray', () => function (obj) {
    if (!(obj instanceof Object)) {
        return obj;
    }
    return _.map(obj, (val, key) => Object.defineProperty(val, '$key', { __proto__: null, value: key }));
});
