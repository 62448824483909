/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// import css files
import './css/app.css';
import './css/fine-uploader-new.css';
import './css/angular.rangeSlider.css';
import 'bootstrap/dist/css/bootstrap.css';
import './css/font-awesome.min.css';
import './css/unicorn.css';
import './css/screen.css';
import './css/datepicker.css';
import './css/typeahead.css';
import './css/ngDialog.min.css';
import './css/ngDialog-theme-default.min.css';
import './css/ngDialog-theme-plain.min.css';
import './css/fine-uploader.css';
import './css/fine-uploader-gallery.css';
import './css/fullcalendar.css';
import './css/jquery.jscrollpane.css';
import './css/ng-tags-input.min.css';

import 'jquery'
import './js/libraries/jquery-migrate-3.3.1.min.js';
import 'jquery-ui';
import 'jquery.flot';
import 'jquery-flot-resize';
import 'jquery.nicescroll';
import 'jquery-sparkline';

import 'lodash';
import 'moment';
import 'moment/locale/nl';
import 'angular';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-animate';

import './js/libraries/excanvas.min';
import 'fullcalendar';
import '@popperjs/core';
import 'bootstrap';
import 'bootstrap-datepicker';
import './js/libraries/unicorn';
import './js/libraries/bootbox';
import 'fine-uploader/s3.fine-uploader/s3.fine-uploader.min';

import './js/libraries/angular-locale_nl-nl';
import './js/libraries/angular-typeahead';
import './js/libraries/typeahead.bundle';
import 'angular-local-storage';
import './js/libraries/draganddrop';
import './js/libraries/ngDialog.min';
import 'angular-rangeslider';
import './js/libraries/ng-tags-input.min';
import 'angular-simple-logger';
import './js/libraries/angular-google-maps.min';
import 'angular-moment';
import 'angular-data-table';

import './js/app.js';
import './js/constants.js';

import './js/filters/CapitalizeFilter.js';
import './js/filters/KeyValueFilter.js';
import './js/filters/ToArrayFilter.js';
import './js/filters/TranslateFilter.js';
import './js/filters/WeekDayFilter.js';

// import js files
import './js/services/ArticleService.js';
import './js/services/CampaignPlanningService.js';
import './js/services/CampaignService.js';
import './js/services/CommentService.js';
import './js/services/DistributionListsService.js';
import './js/services/ErrorService.js';
import './js/services/InspectionService.js';
import './js/services/MalfunctionService.js';
import './js/services/ObjectService.js';
import './js/services/OrganisationService.js';
import './js/services/PackageService.js';
import './js/services/PlanningOptionService.js';
import './js/services/SerieService.js';
import './js/services/TagService.js';

import './js/directives/CampaignDetailCardDirective.js';
import './js/directives/CampaignPhotosDirective.js';
import './js/directives/CampaignPlannerDirective.js';
import './js/directives/CampaignPlannerListDirective.js';
import './js/directives/CardDirective.js';
import './js/directives/CcTableDirective.js';
import './js/directives/currencyDirective.js';
import './js/directives/DateInputDirective.js';
import './js/directives/ErrorsDirective.js';
import './js/directives/FilterDirective.js';
import './js/directives/GalleryDirective.js';
import './js/directives/gpsDirective.js';
import './js/directives/MaterialLineDirective.js';
import './js/directives/MaterialsDirective.js';
import './js/directives/NumberOfAdSpotsDirective.js';
import './js/directives/ObjectMapDirective.js';
import './js/directives/OrderlinesDirective.js';
import './js/directives/OrderlinesDiscountsDirective.js';
import './js/directives/OrganisationDetailCardDirective.js';
import './js/directives/PaginatorDirective.js';
import './js/directives/RevenueDirective.js';
import './js/directives/SearchableTagDirective.js';
import './js/directives/SerieDetailCardDirective.js';

import './js/controllers/CampaignDetailsController.js';
import './js/controllers/CampaignListController.js';
import './js/controllers/CampaignMaterialsListController.js';
import './js/controllers/CaseController.js';
import './js/controllers/DistributionListsController.js';
import './js/controllers/HeatMapController.js';
import './js/controllers/HeatMapPlannerController.js';
import './js/controllers/InspectionController.js';
import './js/controllers/InspectionListController.js';
import './js/controllers/NetworkController.js';
import './js/controllers/ObjectDetailsController.js';
import './js/controllers/ObjectListController.js';
import './js/controllers/PackageDetailsController.js';
import './js/controllers/PackageListController.js';
import './js/controllers/QuoteComparisonController.js';
import './js/controllers/SerieDetailsController.js';
import './js/controllers/SerieListController.js';
import './js/controllers/SerieOrganisationListController.js';

import './js/entities/Campaign.js';
import './js/entities/CampaignMaterials.js';
import './js/entities/CampaignPlanning.js';
import './js/entities/Comment.js';
import './js/entities/Inspection.js';
import './js/entities/Malfunction.js';
import './js/entities/Orderline.js';
import './js/entities/Organisation.js';
import './js/entities/Package.js';
import './js/entities/PlanningOption.js';
import './js/entities/Role.js';
import './js/entities/Serie.js';
import './js/entities/User.js';

// start the Stimulus application
import './stimulus';

