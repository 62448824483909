import {directives} from '../app';
// phpcs:ignoreFile
directives.directive('orderlinesDiscounts', ['$rootScope', 'CampaignService', 'Orderline', 'ArticleService', function ($rootScope, CampaignService, Orderline, ArticleService) {
    return {
        restrict: 'A',
        templateUrl: '/build/html/partials/orderlines-discounts.html',
        scope: {
            data: '=orderlinesDiscounts',
            campaign: '=campaign',
        },

        link($scope)
        {
            $scope.creditObjects = [];
            $scope.creditSeries = [];
            $scope.creditOrganisations = [];
            $scope.objectTypes = $rootScope.objectTypes;

            $scope.isShowable = false;

            $scope.toggleOrderlines = function (event) {
                event.stopPropagation();
                if (typeof ($scope.data) !== 'undefined') {
                    if ($scope.data.length) {
                        $scope.isShowable = !$scope.isShowable;
                    }
                }
            };

            ArticleService.query({entry: 'CREDIT'}, (response) => {
                $scope.articles = response.map((item) => {
                    item.creditPercentage = item.creditPercentage
                        ? item.creditPercentage.toString()
                        : null;

                    return item;
                });
            });

            $scope.addOrderline = function () {
                const orderline = new Orderline();
                orderline.setCampaign($scope.campaign);

                $scope.data.push(orderline);
            };

            $scope.removeOrderline = function (orderline, $index) {
                $scope.data.splice($index, 1);
                orderline.remove(() => {
                    $rootScope.$broadcast('orderline-changed');
                });
            };

            $rootScope.$on('orderline-changed', () => {
                $scope.getCreditAmounts(true);
            });

            $scope.save = function (orderline) {
                orderline.save(() => {
                    $scope.addOrderline();
                });
            };

            $scope.bulkSave = function (orderlines) {
                orderlines = orderlines !== null ? orderlines : $scope.data;

                return CampaignService.bulkSaveOrderlines({id: $scope.campaign.id},
                    {
                        orderlines: orderlines.map((orderline) => orderline.toArray()),
                    },
                );
            };

            $scope.updateOrderline = function (orderline, article) {
                orderline.setDescription(article);
                orderline.creditPercentage = article.creditPercentage;

                $scope.getCreditTargetsForOrderline(orderline);
                $scope.getCreditAmounts();
            };

            $scope.getCreditAmounts = function (refresh = false) {
                return CampaignService.creditAmounts({id: $scope.campaign.id}, {
                    orderlines: $scope.data
                        // TODO creditTarget is not always needed to be defined
                        .filter((item) => item.article && item.creditPercentage)
                        .map((item) => item.toArray()),
                    refresh: refresh
                }, (response) => {
                    $scope.data.forEach((orderline) => {
                        const updatedOrderline = response.find((updated) => (typeof updated.id === 'undefined'
                                && typeof orderline.id === 'undefined')
                            || updated.id === orderline.id);

                        if (updatedOrderline) {
                            orderline.amount = updatedOrderline.amount / 100;
                        } else {
                            orderline.amount = 0;
                        }

                        // Remove if sale is empty.
                        if (orderline.article !== undefined &&
                            orderline.oldCreditTarget !== undefined &&
                            orderline.creditTarget == null &&
                            orderline.creditTarget !== orderline.oldCreditTarget &&
                            orderline.id !== undefined // not saved yet just ignore.
                        ) {

                            let index = _($scope.data).reduce(function (trues, v, k) {
                                if (v.$$hashKey === orderline.$$hashKey)
                                    trues.push(k);
                                return trues;
                            }, []);

                            if (orderline.article.creditType === 'object') {
                                $scope.removeOrderline(orderline, index[0]);
                                return;
                            }

                            if (orderline.article.creditType === 'serie') {
                                $scope.removeOrderline(orderline, index[0]);
                                return;
                            }

                            if (orderline.article.creditType === 'organisatie_gemeente') {
                                $scope.removeOrderline(orderline, index[0]);
                            }
                        }
                    });
                });
            };

            $scope.updateCreditAmounts = function (orderline) {
                if (!orderline.creditPercentage) {
                    return;
                }

                $scope.getCreditAmounts();
            };

            $scope.amountChanged = _.debounce(
                (resolve, reject) => CampaignService.checkDiscountMax(
                    {
                        id: $scope.campaign.id,
                    },
                    {
                        orderlines: $scope.data
                            .filter((item) => item.article && item.creditPercentage)
                            .map((item) => item.toArray()),
                    },
                    (response) => {
                        if (response[0].result === true) {
                            return
                        }
                        $rootScope.errors.push({
                            message: 'De korting mag niet hoger zijn dan de waarde van het onderdeel waar korting over wordt gegeven',
                        });
                        $scope.data.forEach((orderline) => {
                            const updatedOrderline = response[0].orderlines.find((updated) => (typeof updated.id === 'undefined'
                                    && typeof orderline.id === 'undefined')
                                || updated.id === orderline.id);

                            if (updatedOrderline && updatedOrderline.amount === 0) {
                                orderline.amount = 0;
                            }
                        });
                    },
                )
                    .$promise
                    .then(resolve)
                    .catch(reject),
                500,
            );

            $scope.getCreditObjects = _.debounce(
                (resolve, reject) => CampaignService.getPlannedObjectTypes(
                    {
                        id: $scope.campaign.id,
                    },
                    (response) => {
                        $scope.creditObjects = response.map((item) => {
                            const result = $scope.objectTypes.find(
                                (listItem) => listItem.key === item,
                            );

                            return {
                                id: item,
                                name: result.value,
                            };
                        });
                    },
                )
                    .$promise
                    .then(resolve)
                    .catch(reject),
                500,
            );

            $scope.getCreditSeries = _.debounce(
                (resolve, reject) => CampaignService.getPlannedSeries(
                    {
                        id: $scope.campaign.id,
                    },
                    (response) => {
                        $scope.creditSeries = response;
                    },
                )
                    .$promise
                    .then(resolve)
                    .catch(reject),
                500,
            );

            $scope.getCreditOrganisations = _.debounce(
                (resolve, reject) => CampaignService.getPlannedOrganisations(
                    {
                        id: $scope.campaign.id,
                    },
                    (response) => {
                        $scope.creditOrganisations = response;
                    },
                )
                    .$promise
                    .then(resolve)
                    .catch(reject),
                500,
            );

            // eslint-disable-next-line consistent-return
            $scope.getCreditTargetsForOrderline = function (orderline) {
                // New empty row or type other
                if (!orderline.article || orderline.article.creditType === 'other' || orderline.article.creditType === 'bureau') {
                    return Promise.resolve();
                }

                orderline.oldCreditTarget = orderline.creditTarget;
                if (orderline.article.creditType === 'object') {
                    return new Promise((resolve) => $scope.getCreditObjects(resolve));
                }

                if (orderline.article.creditType === 'serie') {
                    return new Promise((resolve) => $scope.getCreditSeries(resolve));
                }

                if (orderline.article.creditType === 'organisatie_gemeente') {
                    return new Promise((resolve) => $scope.getCreditOrganisations(resolve));
                }
            };

            $scope.getCreditTargets = async function () {
                await Promise.all(
                    $scope.data.map(
                        async (orderline) => $scope.getCreditTargetsForOrderline(orderline),
                    ),
                );
            };

            $rootScope.$on('planning-changed', () => {
                $scope
                    .getCreditTargets()
                    .then(() => $scope.getCreditAmounts().$promise)
                    .then(() => $scope.bulkSave(
                        $scope.data.filter((orderline) => typeof orderline.id !== 'undefined'),
                    ).$promise)
                    .then(() => {
                        // Update revenue once more
                        $scope.campaign.getRevenue($rootScope);
                    });
            });

            const init = $scope.$watchCollection('data', (newValue) => {
                if (newValue && newValue.length) {
                    $scope.getCreditTargets();

                    // Remove watcher after initialisation
                    init();
                }
            });
        },
    };
}]);
