import {directives} from '../app';

directives.directive('searchableTag', ['$rootScope', 'ngDialog', 'ObjectService', 'SerieService', 'TagService', function ($rootScope, ngDialog, ObjectService, SerieService, TagService) {
    return {
        restrict: 'E',
        templateUrl: '/build/html/partials/directives/searchable-tag.html',
        scope: {
            objectType: '@',
            object: '=',
            readonly: '=',
        },
        link($scope) {
            $scope.activeService = {};

            $scope.loadTags = function loadTags(query)
            {
                // TODO load only tags for objects or series
                return TagService.get({ text: query }).$promise;
            };

            $scope.addTagToObject = function addTagToObject(tag, object)
            {
                return $scope.activeService.addTag({ id: object.id, tag: tag.text });
            };

            $scope.removeTagFromObject = function removeTagFromObject(tag, object)
            {
                return $scope.activeService.removeTag({ id: object.id, tag: tag.text });
            };

            $scope.initialize = function initialize()
            {
                $scope.activeService = ($scope.objectType === 'object') ? ObjectService : SerieService;
            };

            $scope.initialize();
        },
    };
}]);
