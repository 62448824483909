import {directives} from '../app';

directives.directive('organisationDetailCard', ['OrganisationService', 'ngDialog', function (OrganisationService, ngDialog) {
    return {
        restrict: 'E',
        link($scope, element) {
            element.on('click', ($event) => {
                if ($scope.object.activeContractCount() > 0) {
                    $event.stopPropagation();
                }
            });

            $scope.openPopup = function () {
                ngDialog.openConfirm({
                    template: '/build/html/partials/ngDialogTemplates/organisation-detail-dialog.html',
                    className: 'ngdialog-theme-default ngdialogBig',
                    scope: $scope,
                });
            };

            $scope.construct = function () {
                $scope.organisation = $scope.object;

                element.on('click', () => {
                    if ($scope.organisation.activeContractCount() > 0) {
                        OrganisationService.getContracts({ id: $scope.organisation.id }, (data) => {
                            $scope.contracts = data;
                            $scope.openPopup();
                        });
                    }
                });
            };

            $scope.construct();
        },
    };
}]);
