import {directives} from '../app';

directives.directive('errors', ($rootScope) => ({
    restrict: 'A',
    templateUrl: '/build/html/partials/directives/errors.html',
    link($scope) {
        $scope.errors = $rootScope.errors;

        $scope.removeError = function (index) {
            $scope.errors.splice(index, 1);
        };
    },
}));
