import {entities} from '../app';

entities.factory('CampaignMaterials', ['$rootScope', 'CampaignService', function ($rootScope, CampaignService) {
    return function (data) {
        this.desiredAmount = 0;
        this.deliveredAmount = 0;
        this.usedAmount = 0;

        if (data) {
            this.id = data.id;
            this.size = data.size;
            this.campaign = data.campaign;
            this.distributor = data.distributor;
            this.desiredAmount = data.desiredAmount ? data.desiredAmount : 0;
            this.deliveredAmount = data.deliveredAmount ? data.deliveredAmount : 0;
            this.usedAmount = data.usedAmount ? data.usedAmount : 0;
            this.section = data.section;
            this.packingSlip = !!data.packingSlip;
            this.deliveredDate = data.deliveredDate;
        }

        const self = this;

        this.save = function () {
            if (!this.deliveredDate) {
                this.deliveredDate = null;
            }

            if (this.id) {
                return this.update();
            }

            return CampaignService.saveCampaignMaterials(
                {
                    id: this.campaign.id,
                },
                {
                    campaignMaterials: this,
                },
                (response) =>
                {
                    self.id = response.id;
                },
            ).$promise;
        };

        this.update = function () {
            return CampaignService.updateCampaignMaterials(
                {
                    id: this.campaign.id,
                    subId: self.id,
                },
                this,
            ).$promise;
        };

        this.delete = function () {
            return CampaignService.deleteCampaignMaterials({
                id: this.campaign.id,
                subId: self.id,
            }).$promise;
        };
    };
}]);
