import {controllers} from '../app';

controllers.controller('CampaignDetailsController', [
    '$rootScope', '$scope', 'CampaignService', 'Campaign', '$routeParams', '$timeout', 'Organisation', 'Package', function ($rootScope, $scope, CampaignService, Campaign, $routeParams, $timeout, Organisation, Package) {
        $scope.selectedOrganisation = null;
        $scope.organisations = [];
        $scope.new = {};
        $scope.createOfferSuccess = 0;
        $scope.campaign = null;
        $scope.addToggle = !$scope.organisations.length;
        $scope.showPlanner = true;
        $scope.recompilePlannerList = false;

        CampaignService.get({id: $routeParams.campaignId}, (response) => {
            const campaign = new Campaign(response);
            $scope.campaign = angular.extend({}, $scope.master, campaign);
            $scope.selected = $scope.campaign.businessType === 'supermarket_mall' ? 'supermarket' : $scope.campaign.businessType;

            $scope.handleCampaignPlanning($scope.campaign);
            $scope.handlePlanningOptions($scope.campaign);

            $scope.campaign.getNumberOfAdSpots();
            $scope.campaign.getRevenue($rootScope);
            $scope.campaign.getOrderLines();
            $scope.campaign.getOrderLinesDiscounts();
        });

        $scope.$watch('new.weekYear', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                $scope.new.week = newValue.getWeek();
                $scope.new.year = newValue.getFullYear();
            }
        });

        /**
         * @param campaign
         */
        $scope.handleCampaignPlanning = function (campaign) {
            const options = {
                competitor: $scope.campaign.competitor,
            };

            if (!options.competitor) {
                options.businessType = $scope.selected;
            }

            campaign.getCampaignPlanning(options, true).then(() => {
                $scope.campaignPlanning = campaign.campaignPlanning;
                angular.forEach(campaign.campaignPlanning, (campaignPlan) => {
                    if (!$scope.containsOrganisation(campaignPlan.serie.organisation)) {
                        $scope.organisations.push(campaignPlan.serie.organisation);
                    }
                });
            });
        };

        /**
         * @param campaign
         */
        $scope.handlePlanningOptions = function (campaign) {
            const options = {
                businessType: $scope.selected,
            };

            campaign.getPlanningOptions(options, true).then(() => {
                angular.forEach(campaign.planningOptions, (planningOption) => {
                    if (!$scope.containsOrganisation(planningOption.organisation)) {
                        $scope.organisations.push(planningOption.organisation);
                    }
                });
            });
        };

        $scope.planPackage = function () {
            $scope.campaign.planPackage(new Package($scope.new.package), $scope.new.week, $scope.new.year).then(() => {
                $scope.organisations = [];

                $scope.handleCampaignPlanning($scope.campaign);
                $scope.handlePlanningOptions($scope.campaign);

                $scope.campaign.getNumberOfAdSpots();
                $scope.campaign.getRevenue($rootScope);
            }).finally(() => {
                $scope.new.package = null;
                $scope.new.week = null;
                $scope.new.year = null;
            });
        };

        $scope.removePlanningByPackage = function () {
            $scope.campaign.removePlanningByPackage().then(() => {
                $scope.organisations = [];

                $scope.handleCampaignPlanning($scope.campaign);
                $scope.handlePlanningOptions($scope.campaign);

                $scope.campaign.getNumberOfAdSpots();
                $scope.campaign.getRevenue($rootScope);
            }, () => {
                console.log('planning removing failed');
            });
        };

        $scope.addOrganisation = function () {
            const organisation = new Organisation($scope.selectedOrganisation);
            if (!$scope.containsOrganisation(organisation)) {
                $scope.organisations.push(organisation);
            }
            $scope.new.organisation = null;
            $scope.selectedOrganisation = null;
        };

        $scope.createOffer = function (campaign) {
            if ($scope.createOfferSuccess === -2) {
                return;
            }
            $scope.createOfferSuccess = -2;

            campaign.createOffer().then(() => {
                console.log('offer created');
                $scope.createOfferSuccess = 1;
                $scope.createOfferSpinner = false;
            }, (reason) => {
                console.log('offer creation failed: ', reason);
                $scope.createOfferSuccess = -1;
                $scope.createOfferSpinner = false;
            });
        };

        const organisations = new Bloodhound({
            datumTokenizer(d) {
                return Bloodhound.tokenizers.whitespace(d.num);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: '/organisations', // Required
                replace(url, query) {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    return `/organisations?businessType=${$scope.campaign.businessType}&query=${query}&competitor=${$scope.campaign.competitor}`;
                },
            },
        });

        // initialize the bloodhound suggestion engine
        organisations.initialize();

        // Typeahead options object
        $scope.exampleOptions = {
            highlight: true,
        };

        $scope.organisationData = {
            displayKey: 'name',
            source: organisations.ttAdapter(),
        };

        const packages = new Bloodhound({
            datumTokenizer(d) {
                return Bloodhound.tokenizers.whitespace(d.num);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: '/packages', // Required
                replace(url, query) {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    return `/packages?onlyWithSeries=true&query=${query}&businessType=${$scope.selected}`;
                },
            },
        });

        // initialize the bloodhound suggestion engine
        packages.initialize();

        $scope.packageData = {
            displayKey: 'name',
            source: packages.ttAdapter(),
        };

        /**
         * @param organisation
         * @returns {boolean}
         */
        $scope.containsOrganisation = function (organisation) {
            if (!$scope.organisations) {
                return false;
            }

            return typeof $scope.organisations.find((item) => item.id === organisation.id) !== 'undefined';
        };

        $scope.removeOrganisation = function (organisation) {
            const key = $scope.organisations.findIndex((item) => item.id === organisation.id);

            if (typeof key !== 'undefined') {
                $scope.organisations.remove(key);
            }
        };

        $scope.toggleCampaignPlannerView = function () {
            $scope.showPlanner = !$scope.showPlanner;

            if ($scope.showPlanner) {
                $scope.organisations = [];
                $scope.handleCampaignPlanning($scope.campaign);
                $scope.handlePlanningOptions($scope.campaign);
            }
        };

        $rootScope.$on('planning-changed', (event, planning) => {
            $scope.campaign.getNumberOfAdSpots();
            $scope.campaign.getRevenue($rootScope);
            $rootScope.$broadcast('orderline-changed');
            $scope.resetOfferButton();

            if (
                !$scope.showPlanner
                && planning
                && planning[0].length === 0
                && planning[1].length === 0
            ) {
                $scope.toggleCampaignPlannerView();
            }
        });

        $rootScope.$on('orderline-changed', () => {
            $scope.campaign.getRevenue($rootScope);
            $scope.resetOfferButton();
        });

        $scope.resetOfferButton = function () {
            if ($scope.createOfferSuccess === -1 || $scope.createOfferSuccess === 1) {
                $scope.createOfferSuccess = 0;
            }
        };

        $scope.$watch('selected', (current, previous) => {
            if (current !== previous && current && previous) {
                $scope.organisations = [];

                if ($scope.showPlanner) {
                    $scope.handleCampaignPlanning($scope.campaign);
                    $scope.handlePlanningOptions($scope.campaign);
                    return;
                }

                $scope.$broadcast('reload-planning-list', current);
            }
        });

        $scope.$on('typeahead:selected', () => {
            $scope.selectedOrganisation = $scope.new.organisation;
            $scope.$apply();
        });
    }]);
