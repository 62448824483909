import {directives} from '../app';

directives.directive('numberOfAdSpots', ['CampaignService', 'SerieService', function (CampaignService, SerieService) {
    return {
        restrict: 'AE',
        transclude: true,
        template: '<span class="fa fa-refresh fa-spin" ng-show="numberOfAdSpots == undefined"></span><span ng-show="numberOfAdSpots != undefined">{{ numberOfAdSpots }}</span>',
        scope: {
            object: '=object',
            type: '@type',
            package: '=package',
        },
        link($scope) {
            /**
             * Get number of ad spots for a campaign
             * @param campaign
             */
            $scope.getAdSpotsForCampaign = function (campaign) {
                CampaignService.getNumberOfAdSpots({ id: campaign.id }, (response) => {
                    if ($scope.package) {
                        $scope.numberOfAdSpots = response.result.package;
                    } else {
                        $scope.numberOfAdSpots = response.result.total;
                    }
                });
            };

            /**
             * Get number of ad spots for a serie
             * @param serie
             */
            $scope.getAdSpotsForSerie = function (serie) {
                SerieService.getNumberOfAdSpots({ id: serie.id }, (response) => {
                    $scope.numberOfAdSpots = response.result;
                });
            };

            /**
             * Event for reinitializing the directive
             */
            $scope.$on('update-adspots', () => {
                $scope.numberOfAdSpots = undefined;
                $scope.init();
            });

            /**
             * Initialize directive
             */
            $scope.init = function () {
                if ($scope.type === 'campaign') {
                    $scope.getAdSpotsForCampaign($scope.object);
                    return;
                }

                $scope.getAdSpotsForSerie($scope.object);
            };

            $scope.init();
        },
    };
}]);
