import {controllers} from '../app';

controllers.controller('ObjectListController', ['$rootScope', '$scope', 'ObjectService', 'OrganisationService', '$location', '$timeout', 'Organisation', 'ngDialog', function ($rootScope, $scope, ObjectService, OrganisationService, $location, $timeout, Organisation, ngDialog) {
    $scope.api = OrganisationService.query;

    $scope.search = { organisation: { name: '' } };

    $scope.organisations = [];
    $scope.organisationOffset = 0;
    $scope.organisationLimit = 25;
    $scope.organisationType = ($location.hash() ? $location.hash() : 'city');

    $scope.object = null;
    $scope.searching = false;
    $scope.seperatorType = 'COMMA';

    $scope.open = function (organisation) {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        $location.path(`/objects/organisation/${organisation.id}`);
    };

    $scope.organisationSearch = function () {
        const currentCall = (new Date()).getTime();
        const lastCall = currentCall;

        $scope.api({
            query: $scope.search.organisation.name,
            businessType: $scope.organisationType,
            offset: $scope.organisationOffset,
            limit: $scope.organisationLimit,
        }, (json) => {
            if (currentCall !== lastCall) {
                return;
            }

            if ($scope.organisations.length) {
                $scope.organisations = $scope.organisations.concat(json);
            } else {
                $scope.organisations = (json.length > 0 ? json : null);
            }

            $scope.getOrganisationStatistics();
        });
    };

    $scope.getOrganisationStatistics = function () {
        angular.forEach($scope.organisations, (organisation, key) => {
            const org = new Organisation(organisation);
            if ($rootScope.user.isAuthorizedTo('manage_objects_toc')) {
                org.getStatistics({});
            }

            $scope.organisations[key] = org;
        });
    };

    $scope.getPreviousOrganisations = function () {
        $scope.organisationOffset = (
            $scope.organisationOffset > $scope.organisationLimit
                ? $scope.organisationOffset - $scope.organisationLimit
                : 0
        );
        $scope.organisations = [];

        $scope.organisationSearch();
    };

    $scope.getNextOrganisations = function () {
        $scope.organisationOffset += $scope.organisationLimit;
        $scope.organisations = [];

        $scope.organisationSearch();
    };

    $scope.$watch('search.organisation.name', (search) => {
        $scope.organisations = [];
        $scope.organisationOffset = null;

        if ($scope.timeout && typeof $scope.timeout === 'function') {
            $scope.timeout.cancel();
        }

        $scope.timeout = $timeout(() => {
            if (search === $scope.search.organisation.name) {
                $scope.organisationSearch();
                $scope.timeout = null;
            }
        }, 700);
    }, true);

    $scope.$watch('organisationType', (older, newer) => {
        if (older === newer) {
            return;
        }

        $scope.organisations = [];
        $scope.organisationOffset = 0;
        $scope.organisationLimit = 25;
        $scope.organisationSearch();
        $location.hash($scope.organisationType);
    });

    $scope.download = function () {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        window.open(`/objects.csv?businessType=${$scope.organisationType}&seperatorType=${$scope.seperatorType}`);
    };

    $scope.searchObject = function () {
        $scope.object = null;
        $scope.searching = true;
        angular.element('.ccid-search')
            .removeClass('not-found');
        angular.element('.ccid-search .btn')
            .removeClass('saved');

        ObjectService.get({ id: $scope.ccid == null ? 0 : $scope.ccid }, (data) => {
            $scope.object = data;
            $scope.searching = false;

            if (data.id === null) {
                angular.element('.ccid-search')
                    .addClass('not-found');
                $timeout(() => {
                    angular.element('.ccid-search')
                        .removeClass('not-found');
                }, 600);
                return;
            }

            $scope.openObjectDialog(data);
        });
    };

    $scope.openObjectDialog = function (object) {
        if (typeof object === 'object') {
            $scope.form = object;
        }

        $scope.openSerie = function openSerie(serieId)
        {
            if (serieId) {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                window.open(`/#/settings/series/${serieId}`, '_self');
                ngDialog.close('object-form');
            }
        };

        ngDialog.openConfirm({
            template: '/build/html/partials/ngDialogTemplates/object-form.html',
            className: 'ngdialog-theme-default ngdialogBig',
            name: 'object-form',
            scope: $scope,
        })
            .then((formObject) => {
                $scope.saveEditObject(formObject);
            });
    };

    $scope.validate = function (object) {
        if (object.postcode) {
            object.postcode = object.postcode.replace(/ /g, '')
                .toUpperCase();
            if (!object.postcode.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
                $scope.form.postcodeError = true;
                return false;
            }
        }
        $scope.form.postcodeError = false;
        return true;
    };

    $scope.saveEditObject = function (object) {
        ObjectService.update(object, () => {
            angular.element('.ccid-search .btn')
                .addClass('saved');
        });
    };

    /**
     * TODO: Clean this stuff up with a directive
     */
    const organisations = new Bloodhound({
        datumTokenizer(d) {
            return Bloodhound.tokenizers.whitespace(d.num);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/organisations', // Required
            replace(url, query) {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                return `/organisations?query=${query}&type=owner`;
            },
        },
    });

    $scope.searchOptions = {
        serie: {
            highlight: true,
            type: 'serie',
        },
        owner: {
            highlight: true,
            type: 'owner',
        },
        formowner: {
            highlight: true,
            type: 'formowner',
        },
    };

    $scope.organisationData = {
        displayKey: 'name',
        source: organisations.ttAdapter(),
    };

    organisations.initialize();

    $scope.$on('typeahead:selected', (event) => {
        if (
            event.targetScope.options.type === 'serie'
            && !$scope.serieLocked
            && $scope.selected.serie.name !== ''
        ) {
            const serie = $scope.selected.serie.name;
            $scope.selected.serie.id = serie.id;
            $scope.selected.serie.name = serie.name;
            $scope.serieLocked = true;
        }

        if (event.targetScope.options.type === 'owner') {
            const owner = $scope.selected.owner.name;
            $scope.selected.owner.id = owner.id;
            $scope.selected.owner.name = owner.name;
        }

        if (event.targetScope.options.type === 'formowner') {
            const owner = $scope.form.owner.name;
            $scope.form.owner.id = owner.id;
            $scope.form.owner.name = owner.name;
        }
        $scope.$apply();
    });
}]);
