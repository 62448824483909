import {controllers, getWeeks, WeekYear} from '../app';

controllers.controller('HeatMapController', [
    '$rootScope', '$scope', 'OrganisationService', 'Organisation', '$location', '$timeout', 'Comment', 'CommentService', function ($rootScope, $scope, OrganisationService, Organisation, $location, $timeout, Comment, CommentService) {
        $scope.organisations = [];
        $scope.weekYears = getWeeks(undefined, 8);
        $scope.offset = 0;
        $scope.limit = 50;
        $scope.businessType = 'city'; // buitenreclame
        $scope.search = {
            organisationName: null,
            quoteStage: '',
        };
        $scope.timeout = null;

        const now = new Date();
        $scope.currentWeek = new WeekYear(now.getWeek(), now.getFullYear());

        $scope.getOrganisationsByWeekYears = function () {
            $scope.getWeekYearComments($scope.weekYears);

            OrganisationService.getPlannedRate({
                requestParams: decodeURIComponent($.param({
                    weekYears: $scope.weekYears.map((weekYear) => weekYear.getSimpleObject()),
                })),
            businessType: $scope.businessType,
            offset: $scope.offset,
            limit: $scope.limit,
            searchString: $scope.search.organisationName,
            quoteStage: $scope.search.quoteStage,
            }, (data) => {
                $scope.createHeatMaps(data);
            });
        };

        $scope.getNextWeek = function () {
            const nextWeek = $scope.currentWeek.getNextWeek();
            $scope.weekYears = getWeeks(nextWeek, 8);
            $scope.currentWeek = nextWeek;

            $scope.getOrganisationsByWeekYears();
        };

        $scope.getPreviousWeek = function () {
            const previousWeek = $scope.currentWeek.getPreviousWeek();
            $scope.weekYears = getWeeks(previousWeek, 8);
            $scope.currentWeek = previousWeek;

            $scope.getOrganisationsByWeekYears();
        };

        $scope.getPreviousOrganisations = function () {
            $scope.offset = ($scope.offset <= $scope.limit ? 0 : $scope.offset - $scope.limit);
            $scope.getOrganisationsByWeekYears();
        };

        $scope.getNextOrganisations = function () {
            $scope.offset += $scope.limit;
            $scope.getOrganisationsByWeekYears();
        };

        $scope.createHeatMaps = function (organisations) {
            $scope.organisations = organisations.map((organisation) => {
                const entity = new Organisation(organisation);
                entity.buildHeatMap($scope.weekYears);

                return entity;
            });
        };

        $scope.changeOrganisationType = function (type) {
            $scope.search.organisationName = $scope.search.organisationName == null ? '' : null;

            $scope.businessType = type;
        };

        $scope.openPlanner = function (organisation, weekYear) {
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            $location.path(`/heatmap/${organisation.id}`).search({
                week: weekYear.week,
                year: weekYear.year,
            });
        };

        /**
         * @param percentage
         * @param hasOptionalPlanning
         * @returns {string}
         */
        $scope.getColorByPercentage = function (percentage, hasOptionalPlanning) {
            percentage *= 100;

            if (percentage <= 0) {
                return '#FFFFFF';
            }

            if (percentage > 0 && percentage < 25) {
                return '#FFFFCC';
            }

            if (percentage < 50 && percentage > 25) {
                return '#CCE6BF';
            }

            if (percentage < 75 && percentage > 50) {
                return '#99CCB3';
            }

            if (percentage < 100 && percentage > 75) {
                return '#66B3A6';
            }

            return hasOptionalPlanning ? '#FF0000' : '#339999';
        };

        /**
         * Get comment per week and add them to the weekYear object
         * @param weekYears
         */
        $scope.getWeekYearComments = function (weekYears) {
            const params = {
                requestParams: decodeURIComponent($.param({
                    weekYears: weekYears.map((weekYear) => weekYear.getSimpleObject()),
                })),
            };

            CommentService.getByWeekYear(params).$promise.then((comments) => {
                angular.forEach($scope.weekYears, (weekYear) => {
                    angular.forEach(comments, (data) => {
                        const comment = new Comment(data);
                        if (weekYear.week === comment.week) {
                            weekYear.setComment(comment);
                        }
                    });

                if (weekYear.comment == null) {
                    const comment = new Comment();
                    comment.type = 'weekyear';
                    comment.week = weekYear.week;
                    comment.year = weekYear.year;
                    weekYear.setComment(comment);
                }
                });
            });
        };

        $scope.openPopup = function (id) {
            $scope.hidePopup();
            $scope.showWeekCommentPopup = id;
        };

        $scope.hidePopup = function () {
            $scope.showWeekCommentPopup = null;
        };

        $scope.$watch('search', (newValue, oldValue) => {
            if (newValue === oldValue) {
                return;
            }

            if ($scope.timeout) {
                $timeout.cancel($scope.timeout);
            }

            $scope.timeout = $timeout(() => {
                $scope.getOrganisationsByWeekYears();
            }, 500);
        }, true);

        $scope.getOrganisationsByWeekYears();
    },
]);
