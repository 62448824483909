import {entities} from '../app';

entities.factory('Orderline', ['$rootScope', 'CampaignService', function ($rootScope, CampaignService) {
    return function (data) {
        if (data) {
            this.id = data.id;
            this.description = data.description;
            this.amount = data.amount / 100;
            this.campaign = null;
            this.article = data.article ? data.article : null;
            this.entry = data.entry;
            this.creditPercentage = data.creditPercentage ? data.creditPercentage : null;
            this.creditTarget = {id: data.creditTarget};
        }

        this.transmitting = false;

        const self = this;

        this.toArray = function () {
            return {
                id: this.id,
                description: this.description,
                amount: (this.amount * 100).toFixed(2),
                article: this.article,
                creditPercentage: this.creditPercentage,
                creditTarget: this.creditTarget ? this.creditTarget.id : null,
            };
        };

        this.setCampaign = function (campaign) {
            this.campaign = campaign;
        };

        this.save = function (cb) {
            if (this.transmitting) {
                return Promise.resolve();
            }

            this.transmitting = true;

            if (this.id) {
                return CampaignService.updateOrderline(
                    {
                        id: this.campaign.id,
                        subId: this.id,
                    },
                    this.toArray(),
                    () =>
                    {
                        $rootScope.$broadcast('orderline-changed');
                        self.transmitting = false;
                    },
                );
            }

            return CampaignService.saveOrderline(
                {
                    id: this.campaign.id,
                },
                this.toArray(),
                (response) =>
                {
                    self.id = response.id;
                    self.entry = response.entry;
                    self.transmitting = false;
                    $rootScope.$broadcast('orderline-changed');
                    cb();
                },
            );
        };

        this.remove = function (cb) {
            if (this.transmitting) {
                return;
            }

            this.transmitting = true;

            CampaignService.deleteOrderline({
                id: this.campaign.id,
                subId: this.id,
            }, () => {
                cb();
                self.transmitting = false;
            });
        };

        this.setDescription = function (article) {
            this.description = article.description;
        };
    };
}]);
