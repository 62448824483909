import {controllers} from '../app';

controllers.controller('CampaignListController', ['$rootScope', '$scope', 'CampaignService', '$location', function ($rootScope, $scope, CampaignService, $location) {
    $scope.service = CampaignService.query;
    $scope.sorts = { property: 'id', direction: 'desc' };
    $scope.columns = [
        { name: 'Id', prop: 'id' },
        { name: 'Naam', prop: 'name', flexGrow: 1 },
        { name: 'Organisatie', prop: 'organisation.name', flexGrow: 1 },
        { name: 'Netwerk', prop: 'businessType', flexGrow: 1 },
        { name: 'SugarCRMId', prop: 'sugarCRMId', flexGrow: 1 },
        { name: 'Aangemaakt op', prop: 'createdDateTime', flexGrow: 1 },
    ];

    $scope.rowClick = function (row) {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        $location.path(`/campaigns/${row.id}`);
    };
}]);
