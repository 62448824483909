import {controllers} from '../app';

controllers.controller('DistributionListsController', [
    '$rootScope',
    '$scope',
    'OrganisationService',
    'DistributionListsService',
    '$window',
    function (
        $rootScope,
        $scope,
        OrganisationService,
        DistributionListsService,
        $window
    ) {
        $scope.distributionLists = {};
        $scope.distributors = {};
        $scope.date = null;
        $scope.dateEnd = null;
        $scope.distributor = null;
        $scope.allDistributors = false;
        $scope.businessType = 'city';
        $scope.listType = 'DISTRIBUTION';
        $scope.cityFilter = 'all';
        $scope.seperatorType = 'COMMA';
        $scope.generationStatus = 0;
        $scope.blockGenerateButton = true;
        $scope.showCityFilter = false;

        $scope.sortType = 'createdDateTime'; // set the default sort type
        $scope.sortReverse = true;

        OrganisationService.query(
            {businessType: 'distributor'},
            (response) =>
            {
                $scope.distributors = response;
            }
        );

        $scope.$watchCollection(
            '[businessType, date, dateEnd, listType, cityFilter]',
            (older, newer) =>
            {
                if (older !== newer && $scope.distributor) {
                    getDistributionLists();
                }
                $scope.removeDownloadButton();
                validateBlockGenerateButton();
            }
        );

        $scope.setDistributor = function (index) {
			$scope.cityFilter = 'all';
			$scope.removeDownloadButton();
            $scope.allDistributors = false;
            $scope.distributor = $scope.distributors[index];

            if ($scope.distributor.tag === 'depot_west') {
                $scope.showCityFilter = true;
            }

            getDistributionLists();
            validateBlockGenerateButton();
        };

        $scope.setAllDistributors = function () {
            $scope.removeDownloadButton();
            $scope.allDistributors = true;
            getDistributionLists();
            validateBlockGenerateButton();
        };

        function validateBlockGenerateButton()
        {
            if (Date.parse($scope.date) && ($scope.distributor || $scope.allDistributors)) {
                $scope.blockGenerateButton = false;
            }
        }

        function getDistributionLists()
        {
            let params = {
                businessType: $scope.businessType,
                listType: $scope.listType,
                distributor: $scope.allDistributors ? 'all' : $scope.distributor.id,
            };

            if (Date.parse($scope.date)) {
                params = angular.extend(
                    params,
                    {date: $scope.date ? formatDate($scope.date) : null}
                );
            }

            if (Date.parse($scope.dateEnd)) {
                params = angular.extend(
                    params,
                    {dateEnd: $scope.dateEnd ? formatDate($scope.dateEnd) : null}
                );
            }

            DistributionListsService.query(
                params,
                (response) =>
                {
                    $scope.distributionLists = response;
                },
                (errorResponse) =>
                {
                    console.log('error', errorResponse);
                }
            );
        }

        function formatDate(date)
        {
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        }

        function download(filename)
        {
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            $window.open(`/downloads/${filename}`);
        }

        $scope.generate = function (distributor) {
            if (!Date.parse($scope.date) || $scope.generationStatus === 1) {
                return;
            }

            $scope.generationStatus = 1;

            DistributionListsService.generate(
                {
                    date: formatDate($scope.date),
                    dateEnd: $scope.dateEnd ? formatDate($scope.dateEnd) : null,
                    organisation: $scope.allDistributors ? 'all' : distributor.id,
                    businessType: $scope.businessType,
                    listType: $scope.listType,
                    seperatorType: $scope.seperatorType,
                    cityFilter: $scope.cityFilter,
                },
                (response) =>
                {
                    $scope.generationStatus = 2;
                    $scope.lastGenerated = response.filename;
                    getDistributionLists();
                },
                (errorResponse) =>
                {
                    $scope.generationStatus = 0;
                    console.log('error', errorResponse);
                },
            );
        };

        $scope.download = function (distributionList) {
            download(distributionList.filename);
        };

        $scope.downloadLast = function () {
            if ($scope.lastGenerated) {
                download($scope.lastGenerated);
            }
        };

        $scope.removeDownloadButton = function () {
            if ($scope.generationStatus === 2) {
                $scope.generationStatus = 0;
            }
        };
    }]);
