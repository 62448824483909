import {services} from '../app';

services.factory('ErrorService', ($rootScope, $timeout) => ({
    addResponseError(response) {
        if (!$rootScope.errors) {
            $rootScope.errors = [];
        }

        if (response.data.errors) {
            $rootScope.errors.concat(response.data.errors);
        } else {
            $rootScope.errors.push({
                message: `HTTP ${response.status} ${response.config.url}`,
            });
        }

        $timeout(() => {
            $rootScope.errors = [];
        }, 3000);
    },
}));
