import { controllers } from '../app';

controllers.controller('CampaignMaterialsListController', [
    '$rootScope',
    '$scope',
    'CampaignService',
    '$location',
    '$timeout',
    function ($rootScope, $scope, CampaignService, $location, $timeout) {
        $scope.campaigns = [];
        $scope.campaignStatistics = [];
        $scope.offset = 0;
        $scope.limit = 30;
        $scope.scrollEnabled = false;
        $scope.newCampaigns = [];
        $scope.businessType = 'city';

        const date = new Date();
        $scope.filter = {
            name: '',
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            startDateOn: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        };

        $scope.search = function () {
            $scope.scrollEnabled = false;

            const currentCall = (new Date()).getTime();
            const lastCall = currentCall;
            const destroymaterials = $scope.filter.destroyMaterials
                ? $scope.filter.destroyMaterials
                : ['KEEP', 'TO_DESTROY'];

            CampaignService.getMaterialsList({
                'quoteStage[]': ['SIGNED', 'CONFIRMED'],
                name: $scope.filter.name,
                startDateOn: $scope.filter.startDateOn,
                offset: $scope.offset,
                limit: $scope.limit,
                businessType: $scope.businessType,
                'destroyMaterials[]': destroymaterials,
            }, (data) => {
                if (currentCall !== lastCall) {
                    return;
                }
                if (data.length === 0) {
                    $scope.campaigns = null;
                    return;
                }

                $scope.newCampaigns = data;
                if ($scope.campaigns.length) {
                    $scope.campaigns = $scope.campaigns.concat(data);
                } else {
                    $scope.campaigns = data;
                }

                $scope.scrollEnabled = data.length >= $scope.limit;

                if (data.length === 0 || !$scope.campaigns) {
                    $scope.campaigns = [];
                } else {
                    $scope.getMaterialsStatistics();
                }
            });
        };

        $('*').scroll(function () {
            if ($scope.scrollEnabled && _.isFunction($scope.search)) {
                let scroll = $(this).scrollTop() + $(this).innerHeight();
                let pageHeight = $(this)[0].scrollHeight;
                if (scroll >= pageHeight) {
                    $scope.offset += $scope.limit;
                    $scope.search();
                }
            }
        });

        $scope.$watch('filter', (newValue, oldValue) => {
            if (newValue === oldValue) {
                return;
            }

            $scope.offset = 0;
            $scope.campaigns = [];

            if ($scope.timeout) {
                $timeout.cancel($scope.timeout);
            }

            $scope.timeout = $timeout(() => {
                $scope.search();
            }, 500);
        }, true);

        $scope.getMaterialsStatistics = function (campaignId) {
            const campaignIds = [];
            if (campaignId) {
                campaignIds.push(campaignId);
            } else {
                angular.forEach($scope.newCampaigns, (campaign) => {
                    campaignIds.push(campaign.id);
                });
            }

            CampaignService.getMaterialStatistics(
                {
                    'campaignId[]': campaignIds,
                },
                (data) => {
                    // phpcs:ignore Generic.WhiteSpace.ScopeIndent.IncorrectExact just wont indent correctly
                    angular.forEach(data, (stats) => {
                        angular.forEach($scope.campaigns, (campaign) => {
                            if (stats.campaignId === campaign.id.toString()) {
                                campaign.sizes = stats.sizes;
                                campaign.desiredAmount = stats.desiredAmount;
                                campaign.deliveredAmount = stats.deliveredAmount;
                                campaign.usedAmount = stats.usedAmount;
                                campaign.materialStatus = stats.materialStatus;
                            }
                        });
                    });
                },
            );
        };

        $scope.getSizeString = function (sizes, list) {
            if (sizes) {
                const output = [];
                angular.forEach(sizes, (inputItem) => {
                    angular.forEach(list, (listItem) => {
                        if (listItem.key === inputItem) {
                            output.push(listItem.value);
                        }
                    });
                });
                return output.join(', ');
            }

            return '';
        };

        $scope.download = function download()
        {
            const params = {
                destroyMaterials: $scope.filter.destroyMaterials
                    ? $scope.filter.destroyMaterials
                    : ['KEEP', 'TO_DESTROY'],
            };
            if ($scope.filter.startDateOn) {
                params.startDateOn = $scope.filter.startDateOn;
            }

            params.businessType = $scope.businessType;
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            window.open(`/campaigns/materialslist.xls?${decodeURIComponent($.param(params))}`);
        };

        $scope.headerWidths = [];
        $scope.setHeaderWidth = function (id, width) {
            $scope.headerWidths[id] = width + 18;
        };
        $scope.getHeaderWidth = function (id) {
            if ($scope.headerWidths[id]) {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                return `${$scope.headerWidths[id]}px`;
            }

            return '';
        };

        angular.forEach(document.getElementsByClassName('toSticky'), (element) => {
            element = angular.element(element);
            const id = element.attr('thid');

            $scope.setHeaderWidth(id, element.width());

            $scope.$watch(
                () => element.width(),
                (newValue, oldValue) => {
                    // phpcs:ignore Generic.WhiteSpace.ScopeIndent.IncorrectExact just wont indent correctly
                    if (newValue !== oldValue) {
                        $scope.setHeaderWidth(id, element.width());
                    }
                },
            );
        });

        $scope.checkTableBodyScrollPosition = function () {
            setInterval(() => {
                const tableBody = document.getElementById('materialsListTable');
                if (tableBody) {
                    const tableBodyPosition = tableBody.getBoundingClientRect();
                    $scope.showFixedHeader = tableBodyPosition.top <= 0;
                    $scope.$apply();
                }
            }, 100);
        };

        $scope.setBusinessType = function (type) {
            $scope.businessType = type;
            $scope.campaigns = [];
            $scope.search();
        };

        $scope.checkTableBodyScrollPosition();

        $rootScope.$on('campaignmaterials-changed', (event, campaignId) => {
            $scope.getMaterialsStatistics(campaignId);
        });

        $scope.search();
    }]);
