import {controllers} from '../app';

controllers.controller('ObjectDetailsController', [
    '$rootScope',
    '$scope',
    '$routeParams',
    'ObjectService',
    'OrganisationService',
    '$timeout',
    'ngDialog',
    'CommentService',
    'Comment',
    'SerieService',
    function (
        $rootScope,
        $scope,
        $routeParams,
        ObjectService,
        OrganisationService,
        $timeout,
        ngDialog,
        CommentService,
        Comment,
        SerieService,
    ) {
        $scope.objects = [];

        // The current opened organisation
        $scope.organisationId = $routeParams.organisationId;

        // If the form to add an new object is displayed
        $scope.formEnabled = false;

        $scope.formMap = {
            center: {
                latitude: 52.28366519999999,
                longitude: 4.834538699999939,
            }, // centercom
            zoom: 12,
            control: {},
            draggable: true,
            dragging: true,
            bounds: {},
            options: {},
            refresh: true,
        };

        $scope.form = {};
        $scope.markers = [];

        // Object scrolling
        $scope.objectScrollEnabled = false;
        $scope.objectOffset = 0;
        $scope.objectLimit = 20;

        // Edit object
        $scope.selected = null;

        $scope.search = {
            expired: false,
        };

        $scope.comment = {
            comment: '',
        };

        $scope.initialRequest = function () {
            OrganisationService.get({ id: $scope.organisationId }, (data) => {
                $scope.organisation = data;
                $scope.organisation.active = true;
            });

            $scope.objectSearch();
        };

        $scope.objectSearch = function () {
            $scope.objects = [];
            $scope.objectScrollEnabled = false;

            const currentCall = (new Date()).getTime();
            const lastCall = currentCall;
            const request = angular.extend({
                id: $scope.organisationId,
                offset: $scope.objectOffset,
                limit: $scope.objectLimit,
            }, $scope.search);

            OrganisationService.getObjects(request, (data) => {
                if (currentCall !== lastCall) {
                    return;
                }

                $scope.objects = [];
                if (data && data.length) {
                    $scope.objects = data;
                }

                $scope.objectScrollEnabled = (data.length >= $scope.objectLimit);
            });
        };

        $scope.delete = function (objectIndex) {
            const object = $scope.objects[objectIndex];

            if (!confirm('Weet u zeker dat u dit object wilt verwijderen?')) {
                return;
            }

            ObjectService.delete({ id: object.id }, () => {
                $scope.objects.splice(objectIndex, 1);
            });
        };

        $scope.copy = function (object) {
            ObjectService.get({ id: object.id }, (data) => {
                data.id = null;
                data.number = null;
                data.BRONumber = null;
                data.serie = null;
                $scope.openObjectDialog(data);
            });
        };

        $scope.showForm = function () {
            $scope.formEnabled = true;
            $scope.selected = null;
        };

        $scope.saveForm = function (object) {
            if (typeof object.id !== 'undefined' && object.id) {
                $scope.saveEdit(object);
                return;
            }

            object.organisation = { id: $scope.organisationId };

            ObjectService.save(object, (data) => {
                $scope.objects.push(data);

                $scope.formEnabled = false;
                $scope.form = {};
            });
        };

        $scope.edit = function (object) {
            if ($scope.selected !== null) {
                $scope.selected = null;
                $scope.serieLocked = false;
                return;
            }

            ObjectService.get({ id: object.id }, (data) => {
                $scope.selected = data;
                $scope.selected.startDate = data.startDate ? new Date(data.startDate) : null;
                $scope.selected.endDate = data.endDate ? new Date(data.endDate) : null;

                $scope.selected.map = $scope.initMap(data.GPSPositionY, data.GPSPositionX);

                $scope.serieLocked = $scope.selected.serie && $scope.selected.serie.id;

                series.clearRemoteCache();
            });

            $scope.formEnabled = false;
        };

        $scope.saveEdit = function (object) {
            if ($rootScope.user.isAuthorizedTo('manage_objects_toc')) {
                object = {
                    id: object.id,
                    routeNumber: object.routeNumber,
                };
            }
            // Basic validation for the postcode
            if (object.postcode) {
                object.postcode = object.postcode.replace(/ /g, '')
                    .toUpperCase();
                if (!object.postcode.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
                    $rootScope.errors.push({
                        message: 'Postcode formaat is ongeldig!',
                    });
                    return;
                }
            }

            ObjectService.update(object, (data) => {
                const updated = $scope.getObjectById(data.id);

                updated.active = data.active;
                updated.updatedDateTime = data.updatedDateTime;
                updated.number = data.number;
                updated.routeNumber = data.routeNumber;
                updated.postcode = data.postcode;
                updated.type = data.type;
                updated.locationName = data.locationName;
                updated.addressDescription = data.addressDescription;
                updated.endDate = data.endDate;
                updated.serie = data.serie;

                $scope.selected = null;
            });
        };

        $scope.getObjectById = function (id) {
            return $scope.objects.find((object) => +object.id === +id);
        };

        $scope.getPreviousObjects = function () {
            $scope.objectOffset = (
                $scope.objectOffset > $scope.objectLimit
                    ? $scope.objectOffset - $scope.objectLimit
                    : 0
            );
            $scope.objectSearch();
        };

        $scope.getNextObjects = function () {
            $scope.objectOffset += $scope.objectLimit;
            $scope.objectSearch();
        };

        const organisations = new Bloodhound({
            datumTokenizer(d) {
                return Bloodhound.tokenizers.whitespace(d.num);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: '/organisations', // Required
                replace(url, query) {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    return `/organisations?query=${query}&type=owner`;
                },
            },
        });

        $scope.searchOptions = {
            serie: {
                highlight: true,
                type: 'serie',
            },
            owner: {
                highlight: true,
                type: 'owner',
            },
            formowner: {
                highlight: true,
                type: 'formowner',
            },
        };

        $scope.organisationData = {
            displayKey: 'name',
            source: organisations.ttAdapter(),
        };

        organisations.initialize();

        $scope.resetOrganisation = function () {
            $scope.selected.owner = null;
        };

        $scope.$watch('search', (oldValue, newValue) => {
            if (oldValue === newValue) {
                return;
            }

            $scope.objects = null;
            $scope.objectOffset = null;
            if ($scope.timeout) {
                $timeout.cancel($scope.timeout);
            }
            $scope.timeout = $timeout(() => {
                $scope.objectSearch();
                $scope.timeout = null;
            }, 500);
        }, true);

        $scope.initMap = function (lat, long) {
            lat = lat || '52.28366519999999';
            long = long || '4.834538699999939';

            // @todo create a filter or something (or fix this in import - make it a float)
            lat = lat.replace(/,/g, '.');
            long = long.replace(/,/g, '.');

            $scope.markers = [
                {
                    id: 0,
                    latitude: lat,
                    longitude: long,
                    icon: ($scope.selected.type === '2sign'
                        ? '/build/html/img/icons/sign.png'
                        : ''),
            },
            ];

            return {
                center: {
                    latitude: lat,
                    longitude: long,
                },
                zoom: 12,
                control: {},
                draggable: true,
                dragging: true,
                bounds: {},
                options: {},
                refresh: true,
                markers: $scope.markers,
            };
        };

        $scope.openObjectDialog = function (copiedObject) {
            $scope.form.type = 'FRAME';
            $scope.form.size = 'A0';
            $scope.form.numberOfAdSpots = 1;
            $scope.form.active = false;
            $scope.form.owner = $scope.organisation;

            // CCM-460 - Reset the IMS frame ID's
            copiedObject.imsFrameIdSide1 = null;
            copiedObject.imsFrameIdSide2 = null;
            copiedObject.imsFrameIdSide3 = null;

            if (typeof copiedObject === 'object') {
                // CCM-460 - Reset the IMS frame ID's
                copiedObject.imsFrameIdSide1 = null;
                copiedObject.imsFrameIdSide2 = null;
                copiedObject.imsFrameIdSide3 = null;

                $scope.form = copiedObject;
            }

            $scope.showForm();

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/object-form.html',
                className: 'ngdialog-theme-default ngdialogBig',
                scope: $scope,
            })
                .then((formObject) => {
                    $scope.saveForm(formObject);
                })
                .finally(() => {
                    $scope.formEnabled = false;
                });
        };

        $scope.validate = function (object) {
            if (object.postcode) {
                object.postcode = object.postcode.replace(/ /g, '')
                    .toUpperCase();
                if (!object.postcode.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
                    $scope.form.postcodeError = true;
                    return false;
                }
            }
            $scope.form.postcodeError = false;
            return true;
        };

        $scope.objectIsExpired = function (object) {
            if (!object.endDate) {
                return false;
            }

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return new Date(object.endDate) < today;
        };

        $scope.openSerie = function (serieId) {
            if (serieId && $rootScope.user.isAuthorizedTo('manage_series')) {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                window.open(`/#/settings/series/${serieId}`, '_self');
            }
        };

        $scope.getRunningCampaigns = function () {
            $scope.runningCampaigns = {
                previous: {},
                current: {},
                next: {},
                loaded: false,
            };

            if (!$scope.selected.serie || !$scope.selected.serie.id) {
                return;
            }

            SerieService.getRunningCampaigns({ id: $scope.selected.serie.id }, (response) => {
                $scope.runningCampaigns = {
                    previous: response.previous,
                    current: response.current,
                    next: response.next,
                    loaded: true,
                };
            });
        };

        $scope.switchSerieLock = function (object) {
            if (!(object.serie && $scope.serieLocked &&
                $rootScope.user.isAuthorizedTo('manage_series'))) {
                return;
            }

            $scope.serieLocked = false;
            $scope.selected.serie = null;
            const serieSearchInput = angular.element('#seriesData');
            serieSearchInput.select();
        };

        $scope.getComments = function (object) {
            $scope.comments = [];
            CommentService.getByObject({ objectId: object.id }, (comments) => {
                angular.forEach(comments, (data) => {
                    const comment = new Comment(data);
                    $scope.comments.push(comment);
                });
            });
        };

        $scope.saveComment = function (comment) {
            if ($scope.savingComment) {
                return;
            }

            $scope.savingComment = true;
            const commentEntity = new Comment(comment);
            commentEntity.setObject($scope.selected);
            commentEntity.setType('object');
            commentEntity.save()
                .then(() => {
                    $scope.comments.push(commentEntity);
                    $scope.comment = {
                        comment: '',
                    };
                    $scope.savingComment = false;
                });
        };

        const series = new Bloodhound({
            datumTokenizer(d) {
                return Bloodhound.tokenizers.whitespace(d.num);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: '/series',
                replace(url, query) {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    return `/organisations/${$scope.organisationId}/series?query=${JSON.stringify(
                        { searchString: query },
                    )}`;
                },
            },
            limit: 25,
        });

        $scope.$on('typeahead:selected', (event) => {
            if (
                event.targetScope.options.type === 'serie'
                && !$scope.serieLocked
                && $scope.selected.serie.name !== ''
            ) {
                const serie = $scope.selected.serie.name;
                $scope.selected.serie.id = serie.id;
                $scope.selected.serie.name = serie.name;
                $scope.serieLocked = true;
            }

            if (event.targetScope.options.type === 'owner') {
                const owner = $scope.selected.owner.name;
                $scope.selected.owner.id = owner.id;
                $scope.selected.owner.name = owner.name;
            }

            if (event.targetScope.options.type === 'formowner') {
                const owner = $scope.form.owner.name;
                $scope.form.owner.id = owner.id;
                $scope.form.owner.name = owner.name;
            }
            $scope.$apply();
        });

        $scope.seriesData = {
            displayKey: 'name',
            source: series.ttAdapter(),
            templates: {
                suggestion(serie) {
                    if (!serie.actualNumberOfAdSpots) {
                        serie.actualNumberOfAdSpots = 0;
                    }
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    return `<p>${serie.name}</p>`
                        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                        + `<p><i>Aantal vlakken:</i>${serie.actualNumberOfAdSpots}/${serie.numberOfAdSpots}</p>`;
                },
            },
        };

        series.initialize();

        $scope.initialRequest();
    }]);
