import {directives} from '../app';

directives.directive('revenue', ['CampaignService', function (CampaignService) {
    return {
        restrict: 'A',
        transclude: true,
        template: '<span class="fa fa-refresh fa-spin" ng-show="!revenue"></span><span ng-show="revenue">{{ revenue/100 | currency }}</span>',
        scope: {
            campaign: '=revenueCampaign',
            revenueType: '@revenue',
        },
        link($scope) {
            CampaignService.getRevenue({ id: $scope.campaign.id }, (response) => {
                $scope.revenue = response.result.gross;
                if (typeof $scope.revenueType === 'undefined' || $scope.revenueType === 'net') {
                    $scope.revenue = response.result.net;
                }
            });
        },
    };
}]);
