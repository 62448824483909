import {bootboxConfirm, directives} from '../app';

directives.directive('campaignPlannerList', [
    '$rootScope', function ($rootScope) {
        return {
            restrict: 'A',
            templateUrl: '/build/html/partials/directives/campaignPlannerList.html',
            scope: {
                campaign: '=',
                businessType: '=',
            },
            link($scope)
            {
                $scope.showSeries = true;
                $scope.showOptions = true;
                $scope.campaignPlanning = [];

                $scope.getCampaignPlanning = function (campaign) {
                    const options = {
                        competitor: $scope.campaign.competitor,
                        limit: $scope.limit,
                        offset: $scope.offset,
                        orderBy: $scope.orderBy,
                        orderReverse: $scope.orderReverse,
                    };

                    if (!options.competitor) {
                        options.businessType = $scope.businessType;
                    }

                    campaign.getCampaignPlanning(options).then(() => {
                        $scope.campaignPlanningCount = $scope.campaignPlanning.length;
                        $scope.campaignPlanning = $scope.campaignPlanning.concat(campaign.campaignPlanning);

                        if ($scope.campaignPlanningCount === $scope.campaignPlanning.length) {
                            $scope.lazyLoadEnabled = false;
                        } else {
                            $scope.campaignPlanningCount = $scope.campaignPlanning.length;
                        }
                    });
                };

                $scope.getCampaignOptions = function (campaign) {
                    const options = {
                        businessType: $scope.businessType,
                    };

                    campaign.getPlanningOptions(options).then(() => {
                        $scope.planningOptions = campaign.planningOptions;
                    });
                };

                $scope.removePlanning = async function (planning) {
                    if (!await bootboxConfirm('Let op!', 'U staat op het punt om deze geplande serie te verwijderen. Weet u het zeker?')) {
                        return;
                    }

                    planning.delete().then(
                        () =>
                        {
                            for (let i = 0; i < $scope.campaignPlanning.length; i++) {
                                if ($scope.campaignPlanning[i].id === planning.id) {
                                    $scope.campaignPlanning.splice(i, 1);
                                }
                            }
                            $rootScope.$broadcast('planning-changed', [$scope.campaignPlanning, $scope.planningOptions]);
                            $rootScope.$broadcast('reload-planning-list');
                            $rootScope.$broadcast('campaignmaterials-changed', $scope.campaign.id);
                        },
                    );
                };

                $scope.removeOption = function (option) {
                    if (!confirm('Let op! U staat op het punt om deze optie te verwijderen. Weet u het zeker?')) {
                        return;
                    }

                    option.delete().then(
                        () =>
                        {
                            for (let i = 0; i < $scope.planningOptions.length; i++) {
                                if ($scope.planningOptions[i].id === option.id) {
                                    $scope.planningOptions.splice(i, 1);
                                }
                            }
                            $rootScope.$broadcast('planning-changed', [$scope.campaignPlanning, $scope.planningOptions]);
                            $rootScope.$broadcast('reload-planning-list');
                        },
                    );
                };

                $scope.toggleSeries = function (event) {
                    event.stopPropagation();
                    if (typeof $scope.campaignPlanning !== 'undefined' && $scope.campaignPlanning.length) {
                        $scope.showSeries = !$scope.showSeries;
                    }
                };

                $scope.toggleOptions = function (event) {
                    event.stopPropagation();
                    if (typeof $scope.planningOptions !== 'undefined' && $scope.planningOptions.length) {
                        $scope.showOptions = !$scope.showOptions;
                    }
                };

                $scope.showNextPlanning = function () {
                    if (!$scope.lazyLoadEnabled) {
                        return;
                    }

                    $scope.offset += $scope.limit;
                    $scope.getCampaignPlanning($scope.campaign);
                };

                $scope.resetLazyLoad = function () {
                    $scope.limit = 50;
                    $scope.offset = 0;
                    $scope.campaignPlanning = [];
                    $scope.lazyLoadEnabled = true;
                    $scope.showPlanningDescriptionPopup = null;
                };

                $scope.$on('reload-planning-list', (event, businessType) => {
                    $scope.resetLazyLoad();
                    $scope.businessType = businessType;
                    $scope.showSeries = true;
                    $scope.showOptions = true;
                    $scope.getCampaignPlanning($scope.campaign);
                    $scope.getCampaignOptions($scope.campaign);
                });

                $scope.resetLazyLoad();
                $scope.getCampaignPlanning($scope.campaign);
                $scope.getCampaignOptions($scope.campaign);

                $scope.updateInlinePlanningList = function (planning) {
                    planning.update().then(
                        () =>
                        {
                            for (let i = 0; i < $scope.planningOptions.length; i++) {
                                if ($scope.planningOptions[i].id === option.id) {
                                    $scope.planningOptions.splice(i, 1);
                                }
                            }
                            $rootScope.$broadcast('planning-changed', [$scope.campaignPlanning, $scope.planningOptions]);
                            $rootScope.$broadcast('reload-planning-list');
                        },
                    );
                };
            },
        };
    }]);
