import {entities, WeekYear} from '../app';
// phpcs:ignoreFile
entities.factory('CampaignPlanning', [
    '$rootScope', 'Serie', '$injector', 'CampaignPlanningService', 'CampaignService', 'Package', function ($rootScope, Serie, $injector, CampaignPlanningService, CampaignService, Package) {
        return function (data, handleCampaign) {
            this.id = data.id;
            this.description = data.description;
            this.quoteDescription = data.quoteDescription;
            this.priceLevel = data.priceLevel ? data.priceLevel : 'HIGH';
            this.status = data.status;
            this.campaign = null;
            this.package = null;
            this.serie = new Serie(data.serie);
            this.startDateOn = data.startDateOn;
            this.endDateOn = data.endDateOn;
            this.customObjectAmount = data.customObjectAmount ?? false;
            this.amount = data.amount;
            this.remainingAmount = data.remainingAmount;
            this.objectAmount = data.objectAmount;
            this.weekYear = data.weekYear
                ? new WeekYear(data.weekYear.week, data.weekYear.year)
                : new WeekYear(data.week, data.year);

            if (data.package) {
                this.package = new Package(data.package);
            }
            this.preferred = data.preferred;
            this.proclaimed = data.proclaimed;
            this.user = data.user;

            let self = this;

            /**
             * @param campaign
             */
            this.setCampaign = function (campaign) {
                this.campaign = campaign;
            };

            /**
             * @param serie
             */
            this.setSerie = function (serie) {
                this.serie = serie;
            };

            /**
             * @param campaign
             * @returns {boolean}
             */
            this.isFromCampaign = function (campaign) {
                return this.campaign.id === campaign.id;
            };

            /**
             * @returns {*}
             */
            this.delete = function () {
                return CampaignService.deleteCampaignPlanning(
                    {
                        id: this.campaign.id,
                        subId: this.id,
                    },
                    null,
                    () => {
                    },
                    (reason) => {
                        console.log('removing planning failed: ', reason);
                    },
                ).$promise;
            };

            /**
             * @param serie
             * @param weekYear
             * @returns {*}
             */
            this.update = function () {
                self = this;

                const updateData = {
                    week: this.weekYear.week,
                    year: this.weekYear.year,
                    serieId: this.serie.id,
                    description: this.description,
                    quoteDescription: this.quoteDescription,
                    amount: this.amount,
                    priceLevel: this.priceLevel,
                    status: this.status,
                    preferred: this.preferred,
                    proclaimed: this.proclaimed,
                };

                if (this.serie.isFromType('triangle', 'screen', 'stopper')) {
                    updateData.startDateOn = this.startDateOn.split('-').reverse().join('-');
                    updateData.endDateOn = this.endDateOn.split('-').reverse().join('-');
                }

                if (this.serie.isFromType('folders', 'custom', 'screen', 'stopper')) {
                    updateData.objectAmount = this.objectAmount;
                }

                return CampaignPlanningService.update(
                    {id: this.id},
                    updateData,
                    (response) => {
                        self.status = response.status;
                        self.startDateOn = response.startDateOn;
                        self.endDateOn = response.endDateOn;
                        self.amount = response.amount;
                        self.objectAmount = response.objectAmount;
                        self.remainingAmount = response.remainingAmount;

                        return response;
                    },
                ).$promise;
            };

            /**
             * @param locked
             */
            this.setLocked = function (locked) {
                if (locked) {
                    this.addStatus('LOCKED');
                    return;
                }

                this.removeStatus('LOCKED');
            };

            /**
             * @param status
             */
            this.addStatus = function (status) {
                const statuses = angular.copy(this.status); // no reference

                if (!this.hasStatus(status)) {
                    statuses.push(status);
                }

                this.status = statuses;

                this.saveStatus();
            };

            /**
             * @param status
             */
            this.removeStatus = function (status) {
                const statuses = angular.copy(this.status); // no reference

                if (this.hasStatus(status)) {
                    statuses.splice(status.indexOf(), 1);
                }

                this.status = statuses;

                this.saveStatus();
            };

            /**
             * @param status
             * @returns {boolean}
             */
            this.hasStatus = function (status) {
                if (this.status.indexOf(status) > -1) {
                    return true;
                }

                return false;
            };

            /**
             * @param weekYear
             */
            this.setWeekYear = function (weekYear) {
                this.weekYear = weekYear;
            };

            /**
             * @returns {boolean}
             */
            this.isLocked = function () {
                if (this.hasStatus('LOCKED')) {
                    return true;
                }

                return false;
            };

            this.setPreferred = function () {
                const updateData = {
                    preferred: !this.preferred,
                    week: this.weekYear.week,
                    year: this.weekYear.year,
                    serieId: this.serie.id,
                };

                return CampaignPlanningService.preferPlanning(
                    {id: this.id},
                    updateData,
                    (response) => {
                        self.preferred = response.preferred;
                        return response;
                    },
                ).$promise;
            };

            this.proclaim = function () {
                if (this.isLocked()) {
                    return Promise.resolve();
                }

                const updateData = {
                    proclaimed: !this.proclaimed,
                    week: this.weekYear.week,
                    year: this.weekYear.year,
                    serieId: this.serie.id,
                };

                return CampaignPlanningService.proclaimPlanning(
                    {id: this.id},
                    updateData,
                    (response) => {
                        self.proclaimed = response.proclaimed;
                        return response;
                    },
                ).$promise;
            };

            this.saveDescription = function () {
                const updateData = {
                    description: this.description,
                    week: this.weekYear.week,
                    year: this.weekYear.year,
                    serieId: this.serie.id,
                };

                return CampaignPlanningService.describePlanning(
                    {id: this.id},
                    updateData,
                    (response) => {
                        self.description = response.description;
                        return response;
                    },
                ).$promise;
            };

            this.saveStatus = function () {
                const updateData = {
                    status: this.status,
                    week: this.weekYear.week,
                    year: this.weekYear.year,
                    serieId: this.serie.id,
                };

                return CampaignPlanningService.saveStatus(
                    {id: this.id},
                    updateData,
                    (response) => {
                        self.status = response.status;
                        return response;
                    },
                ).$promise;
            };

            this.getTotalObjects = function () {
                switch (this.serie.type) {
                    case 'triangle':
                    case 'folders':
                    case 'custom':
                        return this.amount;
                    case 'stopper':
                        return this.remainingAmount;
                    case 'screen':
                        return this.amount;
                    default:
                        return this.serie.numberOfObjects;
                }
            };

            this.isCustomPlanning = function () {
                return ['custom', 'folders', 'stopper'].indexOf(this.serie.type) > -1;
            };

            this.getTotalPrice = function () {
                if (this.package == null) {
                    if (this.priceLevel === 'STOPPER' && this.customObjectAmount) {
                        return this.serie.numberOfAdSpots * this.customObjectAmount;
                    }
                    return this.getTotalObjects() * this.objectAmount;
                }

                switch (this.campaign.rate) {
                    case 'HIGH':
                        return this.package.priceHigh;
                    case 'MIDDLE':
                        return this.package.priceMiddle;
                    case 'LOW':
                        return this.package.priceLow;
                    case 'FREE':
                    case 'VEIL':
                    case 'SAG':
                    default:
                        return 0;
                }
            };

            this.getMaterialStatusColor = function (isInHeatMapPlanner) {
                if (!isInHeatMapPlanner) {
                    return false;
                }

                if (this.campaign.materialStatus) {
                    return '6px solid #0d0';
                }
                if (
                    !this.campaign.materialStatus
                    && (
                        this.campaign.quoteStage === 'SIGNED'
                        || this.campaign.quoteStage === 'CONFIRMED'
                    )
                ) {
                    return '6px solid #d00';
                }
                return false;
            };

            this.getPrettyEndDate = function () {
                const fragments = this.endDateOn.split('-');
                const parsedDate = new Date(
                    parseInt(fragments[2], 10),
                    fragments[1] - 1,
                    parseInt(fragments[0], 10),
                );

                switch (this.serie.type) {
                    case 'triangle':
                    case 'screen':
                    case 'stopper':
                        return this.endDateOn;
                    case 'folders':
                    case 'custom':
                    case 'scroller':
                    default:
                        return new Date(parsedDate - 1);
                }
            };

            if (handleCampaign !== false && data.campaign) {
                const Campaign = $injector.get('Campaign');
                this.campaign = new Campaign(data.campaign);
                this.campaign.package = this.package;
            }
        };
    }]);
