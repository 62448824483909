import {services} from '../app';

services.factory('OrganisationService', ($resource) => $resource('/organisations/:id/:action', { id: '@id' }, {
    update: { method: 'PUT' },
    getSeries: { method: 'GET', params: { action: 'series' }, isArray: true },
    getObjects: { method: 'GET', params: { action: 'objects' }, isArray: true },
    getCampaignPlanning: { url: '/organisations/:id/campaignplanning?:requestParams', method: 'GET', isArray: true },
    getPlannedRate: { url: '/organisations/plannedrates?:requestParams', method: 'GET', isArray: true },
    getPlanningOptions: { url: '/organisations/:id/planningoptions?:requestParams', method: 'GET', isArray: true },
    getStatistics: { url: '/organisations/:id/statistics', method: 'GET', isArray: false },
    getContracts: { method: 'GET', params: { action: 'contracts' }, isArray: true },
}));
