import {directives} from '../app';

directives.directive('dateInput', () => ({
    restrict: 'A',
    require: ['ngModel'],
    link(scope, element, attr, ctrls) {
        const ngModelController = ctrls[0];

        // called with a JavaScript Date object when picked from the datepicker
        ngModelController.$parsers.push((viewValue) => {
            if (viewValue instanceof Date) {
                // undo the timezone adjustment we did during the formatting
                viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
                // we just want a local date in ISO format
                return viewValue.toISOString().substring(0, 10);
            }

            return viewValue;
        });

        // called with a 'yyyy-mm-dd' string to format
        ngModelController.$formatters.push((modelValue) => {
            if (!modelValue) {
                return undefined;
            }

            // date constructor will apply timezone deviations from UTC
            // (i.e. if locale is behind UTC 'dt' will be one day behind)
            const dt = new Date(modelValue);

            if (dt instanceof Date) {
                // 'undo' the timezone offset again (so we end up on the original date again)
                dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
            }
            return dt;
        });
    },
}));
