import {entities} from '../app';

entities.factory('User', ['Role', function (Role) {
    return function (data) {
        this.id = data.id;
        this.username = data.username;
        this.email = data.email;
        this.initials = data.initials;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.role = new Role(data.roles[0]);

        /**
         * Returns if the user has a certain role
         * @param roles
         * @returns {boolean}
         */
        this.hasRole = function (...roles) {
            return roles.some((role) => this.role.code === role);
        };

        /**
         * Checks if the users role is authorized to do a certain action
         * @param authorizationCode
         * @returns {boolean}
         */
        this.isAuthorizedTo = function (authorizationCode) {
            return this.role.authorizations.some(
                (item) => item.code === authorizationCode,
            );
        };

        /**
         * @param authorizationCodes array
         * @returns {boolean}
         */
        this.hasAuthorization = function (authorizationCodes) {
            if (!authorizationCodes) {
                return true;
            }

            return authorizationCodes.some((item) => this.isAuthorizedTo(item));
        };

        /**
         * @returns {boolean}
         */
        this.isDistributor = function () {
            return this.role.isDistributor();
        };
    };
}]);
