import {directives} from '../app';

directives.directive('campaignDetailCard', ($rootScope, ngDialog, CampaignService, Comment, CommentService, $timeout) => ({
    restrict: 'E',
    link($scope, element) {
        element.on('click', ($event) => {
            $event.stopPropagation();
        });

        $scope.comment = {
            comment: '',
        };
        $scope.materialsOfSizeList = [];
        $scope.distributors = [];
        $scope.savingCampaign = false;
        $scope.savedCampaign = false;
        $scope.objects = null;

        $scope.openPopup = function () {
            $scope.objectTypes = $rootScope.objectTypes;
            $scope.objectSizes = $rootScope.objectSizes;

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/campaign-detail-dialog.html',
                className: 'ngdialog-theme-default ngdialogBig',
                scope: $scope,
            });
        };

        $scope.getComments = function () {
            $scope.comments = [];
            CommentService.getByCampaign({ campaignId: $scope.campaign.id }, (comments) => {
                $scope.comments = comments.map((data) => new Comment(data));
            });
        };

        $scope.saveComment = function (comment) {
            if ($scope.savingComment) {
                return;
            }

            $scope.savingComment = true;
            const commentEntity = new Comment(comment);
            commentEntity.setCampaign($scope.campaign);
            commentEntity.setType('campaign');
            commentEntity.save().then(() => {
                $scope.comments.push(commentEntity);
                $scope.comment = {
                    comment: '',
                };
                $scope.savingComment = false;
            });
        };

        $scope.saveCampaign = function (campaign) {
            $scope.savingCampaign = false;
            $scope.savedCampaign = false;

            if ($scope.timeout) {
                $timeout.cancel($scope.timeout);
            }

            $scope.timeout = $timeout(() => {
                $scope.savingCampaign = true;
                $scope.savedCampaign = false;
                CampaignService.update({ id: campaign.id }, campaign, () => {
                    $scope.savingCampaign = false;
                    $scope.savedCampaign = true;
                });
            }, 500);
        };

        $scope.getAllPlannedObjectsForCampaign = function (campaign) {
            if (!campaign) {
                return;
            }

            $scope.objects = null;
            CampaignService.getAllPlannedObjects({ id: campaign.id }, (response) => {
                $scope.objects = response;
            });
        };

        $scope.download = function download()
        {
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            window.open(`/objects.xls?campaignId=${$scope.campaign.id}`);
        };

        $scope.construct = function () {
            CampaignService.get({ id: $scope.object.id }, (response) => {
                $scope.campaign = response;
                $scope.openPopup();
                $scope.getComments();
            });
        };

        if ($rootScope.user.isAuthorizedTo('view_campaigndetailcard')) {
            element.on('click', () => {
                $scope.construct();
            });
        }
    },
}));
