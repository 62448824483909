import {controllers} from '../app';
import qq from 'fine-uploader/lib/s3'

controllers.controller('InspectionController', [
    '$rootScope',
    '$scope',
    'InspectionService',
    'ObjectService',
    '$routeParams',
    '$location',
    'Inspection',
    'SerieService',
    '$q',
    'config',
    'moment',
    'MalfunctionService',
    'Malfunction',
    'ngDialog',
    function ($rootScope, $scope, InspectionService, ObjectService, $routeParams, $location, Inspection, SerieService, $q, config, moment, MalfunctionService, Malfunction, ngDialog) {
        $scope.saving = false;

        $scope.initialize = function () {
            return MalfunctionService.query().$promise.then((data) => {
                $scope.createMalfunctionEntities(data);

                const id = $routeParams.inspectionId;
                if (id && parseInt(id, 10)) {
                    return InspectionService.get({id}).$promise.then((data2) => $scope.initializeInspection(data2)).catch(() => {
                        $location.path('/inspections');
                    });
                }
                if (!$rootScope.user.isAuthorizedTo('create_inspections')) {
                    $location.path('/inspections');
                } else {
                    $scope.inspection = new Inspection();
                }

                return $scope.inspection;
            }).then((inspection) => Promise.resolve(inspection));
        };

        $scope.saveInspection = function () {
            $scope.saving = true;

            $scope.inspection.save().then((data) => {
                $scope.setInspectionPhotoEndpoint(data.id);

                if ($scope.galleryUploader.getUploads({status: qq.status.SUBMITTED}).length) {
                    $scope.galleryUploader.uploadStoredFiles();
                }
            }).then(() => {
                $location.path('/inspections');
            });
        };

        $scope.assignInspection = function () {
            InspectionService.assign({id: $scope.inspection.id}).$promise.then((data) => {
                $scope.initializeInspection(data);
            });
        };

        $scope.openCaseDialog = function () {
            const dialogScope = $scope.$new(true);
            dialogScope.priorities = [
                {
                    key: 'P3',
                    value: 'Laag',
            },
                {
                    key: 'P2',
                    value: 'Middel',
            },
                {
                    key: 'P1',
                    value: 'Hoog ',
            },
            ];

            dialogScope.locations = [
                {
                    key: 'Amstelveen',
                    value: 'Centercom Amstelveen',
            },
                {
                    key: 'Groningen',
                    value: 'Centercom Groningen',
            },
                {
                    key: 'Raalte',
                    value: 'Centercom Raalte',
            },
            ];

            dialogScope.inspection = $scope.inspection;

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/case-dialog.html',
                className: 'ngdialog-theme-default',
                scope: dialogScope,
            }).then((caseData) => {
                $scope.createCaseForInspection(caseData);
            });
        };

        $scope.createCaseForInspection = function (caseData) {
            InspectionService.createCase({id: $scope.inspection.id}, {
                priority: caseData.priority,
                location: caseData.location,
            }).$promise.then((data) => {
                $scope.initializeInspection(data);
            });
        };

        $scope.approveInspection = function () {
            InspectionService.approve({id: $scope.inspection.id}).$promise.then((data) => {
                $scope.initializeInspection(data);
            });
        };

        $scope.finalizeInspection = function () {
            InspectionService.finalize({id: $scope.inspection.id}).$promise.then((data) => {
                $scope.initializeInspection(data);
            });
        };

        $scope.disApproveInspection = function (message) {
            if (!message) {
                $scope.isDisapproving = true;
                $('[data-target="#comments-tab"]').click();
                $('[data-target="#campaign-information-tab"]').hide();
                $('[data-target="#object-information-tab"]').hide();
                return;
            }

            InspectionService.disapprove({id: $scope.inspection.id}, {
                comment: message,
            }).$promise.then((data) => {
                $scope.cancelDisapproving();
                $scope.initializeInspection(data);
            });
        };

        $scope.cancelDisapproving = function () {
            $scope.isDisapproving = false;
            $('[data-target="#object-information-tab"]').click();
            $('[data-target="#campaign-information-tab"]').show();
            $('[data-target="#object-information-tab"]').show();
        };

        $scope.saveComment = function (message) {
            InspectionService.addComment({id: $scope.inspection.id}, {
                comment: message,
            }).$promise.then((data) => {
                $scope.initializeInspection(data);
            });
        };

        $scope.deleteInspection = function () {
            if (!$scope.inspection.id) {
                return;
            }

            const dialogScope = $scope.$new(true);
            dialogScope.title = 'Weet je het zeker?';
            dialogScope.message = 'De inspectie en alle foto\'s worden permanent verwijderd.';
            dialogScope.buttons = [
                {
                    text: 'Verwijderen',
                    value: true,
                    style: 'ngdialog-button-primary',
            },
                {
                    text: 'Annuleren',
                    close: true,
                    style: 'ngdialog-button-secondary',
            },
            ];

            ngDialog.openConfirm({
                template: '/build/html/partials/ngDialogTemplates/question-dialog.html',
                className: 'ngdialog-theme-default',
                scope: dialogScope,
            }).then(() => {
                InspectionService.delete({id: $scope.inspection.id}).$promise.then(() => {
                    $location.path('/inspections');
                });
            });
        };

        $scope.getRunningCampaigns = function () {
            if (
                !(
                    $scope.inspection.object
                    && $scope.inspection.object.serie
                    && $scope.inspection.object.serie.id
                )
            ) {
                return;
            }

            const data = {id: $scope.inspection.object.serie.id};
            if ($routeParams.inspectionId && $routeParams.inspectionId !== 'new') {
                angular.extend(data, {
                    date: moment($scope.inspection.createdDateTime).toDate(),
                });
            }
            SerieService.getRunningCampaigns(data, (response) => {
                $scope.runningCampaigns = {
                    previous: response.previous,
                    current: response.current,
                    next: response.next,
                    loaded: true,
                };
            });
        };

        $scope.createMalfunctionEntities = function (data) {
            $scope.malfunctions = data.map((item) => new Malfunction(item));
        };

        $scope.initializeInspection = function (inspectionData) {
            $scope.inspection = new Inspection(inspectionData);
            $scope.givenObject = $scope.inspection.object;

            $scope.isDistributed = $scope.inspection.isDistributed();
            $scope.canBeApproved = $scope.inspection.canBeApproved($rootScope.user);
            $scope.canBeDisapproved = $scope.inspection.canBeDisapproved($rootScope.user);
            $scope.mayBeDistributed = $scope.inspection.mayBeDistributed($rootScope.user);
            $scope.canBeDistributed = $scope.inspection.canBeDistributed();

            return $scope.inspection.getInspectionPhotos().then(() => $scope.inspection);
        };

        $scope.addUploadedPhotoToImageObject = function (responseJSON) {
            $scope.inspection.images.push({
                id: responseJSON.id,
                name: responseJSON.name,
                fileName: responseJSON.fileName,
                secureUrl: responseJSON.tempLink,
            });

            $scope.$apply();
        };

        $scope.openImage = function (image) {
            window.open(image.secureUrl);
        };

        $scope.deleteImage = function ($event, image) {
            $event.stopPropagation();
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            if (!confirm(`Weet u zeker dat u de foto '${image.name}' wilt verwijderen ? `)) {
                return;
            }

            InspectionService.deleteImage({
                id: $scope.inspection.id,
                actionId: image.id,
            }, () => {
                $scope.deleteMode = false;

                $scope.inspection.images.filter(
                    (inspectionImage) => inspectionImage.name !== image.name,
                );
            });
        };

        $scope.setInspectionPhotoEndpoint = function (inspectionId) {
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            $scope.galleryUploader.setUploadSuccessEndpoint(`/inspections/${inspectionId}/photos`);
        };

        $scope.$on('object-map:reset', () => {
            $scope.inspection = new Inspection();
        });

        $scope.$on('object-map:object-selected', (event, selectedObject) => {
            $scope.inspection.object = selectedObject;
            $scope.getRunningCampaigns();
        });

        $scope.initialize().then(() => {
            $scope.initGalleryUploader();
        });

        $scope.initGalleryUploader = function () {
            if (!$rootScope.user.isAuthorizedTo('create_inspections')) {
                return;
            }

            const inspectionId = $scope.inspection && $scope.inspection.id
                ? $scope.inspection.id
                : null;

            $scope.galleryUploader = new qq.s3.FineUploader({
                autoUpload: inspectionId,
                element: document.getElementById('inspection-uploader'),
                request: {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    endpoint: `https://${config.S3_INSPECTION_BUCKET}.s3-eu-west-1.amazonaws.com`,
                    accessKey: 'AKIAJNZ3573DYHSQX7LQ',
                },
                signature: {
                    endpoint: '/s3/signatures?type=inspection',
                },
                uploadSuccess: {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    endpoint: inspectionId ? `/inspections/${inspectionId}/photos` : null,
                    method: 'PUT',
                },
                validation: {
                    allowedExtensions: ['gif', 'jpeg', 'jpg', 'png'],
                    acceptFiles: 'image/gif, image/jpeg, image/png',
                    sizeLimit: 100000000,
                    itemLimit: 10,
                },
                retry: {
                    enableAuto: true,
                },
                deleteFile: {
                    enabled: false,
                },
                objectProperties: {
                    acl: 'private',
                },
                callbacks: {
                    onComplete(id, name, responseJSON) {
                        $scope.addUploadedPhotoToImageObject(responseJSON);
                    },
                },
            });
        };
    }]);
