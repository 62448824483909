import {controllers, WeekYear} from '../app';

controllers.controller('HeatMapPlannerController', [
    '$rootScope', '$scope', 'OrganisationService', '$routeParams', 'Organisation', '$location', function ($rootScope, $scope, OrganisationService, $routeParams, Organisation, $location) {
        $scope.organisations = [];
        $scope.weekYear = ($location.search().week && ($location.search().year))
            ? new WeekYear(parseInt($location.search().week, 10), parseInt($location.search().year, 10))
            : undefined;

        OrganisationService.get({id: $routeParams.organisationId}, (response) => {
            const organisation = new Organisation(response);
            organisation.isShowable = true;

            $scope.organisations.push(organisation);
        });
    }]);
