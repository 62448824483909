import {services} from '../app';

services.factory('ArticleService', ($resource) => $resource('/articles/:id/:action/:subId/:subAction', { id: '@id' }, {
    query: {
        method: 'GET',
        params: {
            entry(current) {
                if (current) {
                    return current;
                }

                return 'DEBIT';
            },
        },
        isArray: true,
    },
    update: { method: 'PUT' },
}));
