import {filters} from '../app';

filters.filter('weekday', () => function (obj, fallback) {
    const mapping = [
        'maandag',
        'dinsdag',
        'woensdag',
        'donderdag',
        'vrijdag',
        'zaterdag',
        'zondag',
    ];

    return mapping[parseInt(obj, 10)] ? mapping[parseInt(obj, 10)] : fallback;
});
