import {entities, WeekYear} from '../app';

entities.factory('Serie', [
    '$injector', function ($injector) {
        return function (data, handleOrganisation) {
            this.id = data.id;
            this.name = data.name;
            this.kernen = data.kernen;
            this.remark = data.remark;
            this.startDate = data.startDate;
            this.endDate = data.endDate ? data.endDate : null;
            this.numberOfObjects = data.numberOfObjects;
            this.numberOfAdSpots = data.numberOfAdSpots;
            this.actualNumberOfObjects = data.actualNumberOfObjects;
            this.actualNumberOfAdspots = data.actualNumberOfAdspots;
            this.priceLow = data.priceLow;
            this.startingDate = data.startingDate;
            this.priceMiddle = data.priceMiddle;
            this.priceHigh = data.priceHigh;
            this.dayOfChange = data.dayOfChange;
            this.changeIntervalType = data.changeIntervalType;
            this.changeIntervalNumber = data.changeIntervalNumber;
            this.type = data.type;
            this.organisation = null;
            this.objectTypes = data.objectTypes;

            /**
             * @param organisation
             */
            this.setOrganisation = function (organisation) {
                this.organisation = organisation;
            };

            /**
             * @param {string} types
             * @returns boolean
             */
            this.isFromType = function (...types) {
                return types.some((type) => this.type === type);
            };

            /**
             * @param wy
             * @returns {boolean}
             */
            this.isWeekOfChange = function (wy) {
                const startDate = new Date(this.startDate);
                let startWeekYear = new WeekYear(startDate.getWeek(), startDate.getFullYear());

                // eslint-disable-next-line no-constant-condition
                while (true) {
                    if (startWeekYear.isEqual(wy)) {
                        return true;
                    }

                    if (startWeekYear.isGreaterThan(wy)) {
                        return false;
                    }

                    startWeekYear = startWeekYear.getNextWeek(this.changeIntervalNumber);
                }
            };

            /**
             * Checks if the endDate of the serie is in the past of the current date
             * @param wy
             * @returns {*}
             */
            this.isEndDateInPast = function (wy) {
                if (!this.endDate) {
                    return false;
                }

                const endDate = new Date(this.endDate);

                return endDate && endDate < wy.getEndDate();
            };

            /**
             * Checks if the startDate of the serie is in the future of the current date
             * @param wy
             * @returns {*}
             */
            this.isStartDateInFuture = function (wy) {
                if (!this.startDate) {
                    return true;
                }

                const startDate = new Date(this.startDate);

                return startDate && startDate > wy.getStartDate();
            };

            if (handleOrganisation !== false && data.organisation) {
                const Organisation = $injector.get('Organisation');
                this.organisation = new Organisation(data.organisation);
            }

            /**
             * Get a date base on week, year and day of week
             * @param wy
             * @returns {Date}
             */
            this.getDateOfChange = function (wy) {
                return wy.getDateByDay(this.dayOfChange);
            };

            /**
             * @param priceLevel
             * @returns {*}
             */
            this.getPriceByPriceLevel = function (priceLevel) {
                switch (priceLevel) {
                    case 'HIGH':
                        return this.priceHigh;
                    case 'MIDDLE':
                        return this.priceMiddle;
                    case 'LOW':
                        return this.priceLow;
                    case 'FREE':
                    case 'VEIL':
                    case 'SAG':
                    default:
                        return 0;
                }
            };

            this.isPlannableInWeekYear = function (weekYear) {
                const $rootScope = $injector.get('$rootScope');
                return !(
                    this.isEndDateInPast(weekYear) || this.isStartDateInFuture(weekYear)
                ) && !$rootScope.isDateInPast(this.getDateOfChange(weekYear));
            };
        };
    }]);
