import {directives} from '../app';

directives.directive('filter', [
    '$timeout', function ($timeout) {
        return {
            restrict: 'AE',
            templateUrl: '/build/html/partials/directives/filter.html',
            scope: {
                currentWeekYear: '=currentWeekYear',
                filteractive: '=filter',
            },
            link($scope) {
                $scope.objectsRange = {
                    min: 0,
                    max: 150,
                };

                $scope.filter = {
                    active: true,
                    currentWeekYear: $scope.currentWeekYear,
                    minObjects: $scope.objectsRange.min,
                    maxObjects: $scope.objectsRange.max,
                    searchString: '',
                };

                $scope.$watchCollection('[filter.minObjects, filter.maxObjects]', (oldValues, newValues) => {
                    if (oldValues === newValues) {
                        return;
                    }

                    if ($scope.timeout) {
                        $timeout.cancel($scope.timeout);
                    }
                    $scope.timeout = $timeout(() => {
                        $scope.$emit('filter-changed', $scope.filter);
                        // $scope.filterChange();
                    }, 500);
                });

                $scope.filterChange = function (delay) {
                    if (delay) {
                        if ($scope.timeout) {
                            $timeout.cancel($scope.timeout);
                        }

                        $scope.timeout = $timeout(() => {
                            $scope.$emit('filter-changed', $scope.filter);
                        }, 500);
                    } else {
                        $scope.$emit('filter-changed', $scope.filter);
                    }
                };

                $scope.toggleFilterActive = function () {
                    $scope.filteractive = !$scope.filteractive;

                    $scope.filter = {
                        active: true,
                        currentWeekYear: $scope.currentWeekYear,
                        minObjects: 0,
                        maxObjects: 150,
                        searchString: '',
                        size: null,
                        type: null,
                    };

                    $scope.$emit('filter-changed', $scope.filter);
                };
            },
        };
    }]);
