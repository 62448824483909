import {filters} from '../app';

filters.filter('keyvalue', () => function (input, list) {
    if (!input) {
        return null;
    }

    const result = list.find((listItem) => listItem.key === input);

    return result ? result.value : undefined;
});
