import {directives} from '../app';

directives.directive('gps', () => ({
    restrict: 'A',
    require: 'ngModel',
    scope: { gps: '=gps' },
    link($scope) {
        $scope.$watch('gps', () => {
            if ($scope.gps) {
                $scope.gps = $scope.gps.replace(/,/g, '.').replace(/ /g, '');
            }
        });
    },
}));
