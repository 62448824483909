import {services} from '../app';

services.factory('CampaignPlanningService', ($resource) => $resource('/campaignplannings/:id/:action', { id: '@id' }, {
    save: { method: 'POST' },
    update: { method: 'PUT' },
    preferPlanning: { method: 'PUT', params: { action: 'prefer' } },
    proclaimPlanning: { method: 'PUT', params: { action: 'proclaim' } },
    describePlanning: { method: 'PUT', params: { action: 'description' } },
    saveStatus: { method: 'PUT', params: { action: 'status' } },
    getStopperAvailableAdSpots: { method: 'GET', params: { action: 'stopperavailableadspots' } },
}));
