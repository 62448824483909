import {directives} from '../app';

directives.directive('materials', ['$rootScope', 'CampaignService', function ($rootScope, CampaignService) {
    return {
        restrict: 'A',
        templateUrl: '/build/html/partials/materials.html',
        scope: {
            campaign: '=campaign',
            isInPlanner: '=isInPlanner',
        },

        link($scope)
        {
            $scope.isShowable = false;
            $scope.isInPlanner = typeof $scope.isInPlanner !== 'undefined' ? $scope.isInPlanner : false;

            $scope.toggleMaterials = function (event) {
                event.stopPropagation();
                if (typeof ($scope.materialsOfSizeList) !== 'undefined' && $scope.materialsOfSizeList.length) {
                    $scope.isShowable = !$scope.isShowable;
                }
            };

            $scope.getCampaignSizeMaterials = function () {
                $scope.gettingMaterials = true;
                CampaignService.getCampaignSizeMaterials({id: $scope.campaign.id}, (campaignMaterials) => {
                        $scope.gettingMaterials = false;
                        $scope.materialsOfSizeList = campaignMaterials;
                    });
            };

            $scope.$on('campaignmaterials-changed', () => {
                if ($scope.isInPlanner) {
                    $scope.getCampaignSizeMaterials();
                }
            });

            $scope.getCampaignSizeMaterials();
        },
    };
}]);
