import {services} from '../app';

services.factory('SerieService', ($resource) => $resource('/series/:id/:action/:objectId', { id: '@id' }, {
    update: { method: 'PUT' },
    getObjects: { method: 'GET', params: { action: 'objects' }, isArray: true },
    addObject: { method: 'POST', params: { action: 'objects', objectId: '@objectId' } },
    removeObject: { method: 'DELETE', params: { action: 'objects', objectId: '@objectId' } },
    getNumberOfAdSpots: { method: 'GET', params: { action: 'numberofadspots' }, isArray: false },
    getRunningCampaigns: { url: '/series/:id/runningcampaigns/:requestParams', method: 'GET', isArray: false },
    getStatistics: { method: 'GET', params: { action: 'statistics' }, isArray: false },
    getPriceForPlanning: { method: 'GET', params: { action: 'priceforplanning' }, isArray: false },
    getRates: { method: 'GET', params: { action: 'rates' }, isArray: true },
    getCurrentRates: { method: 'GET', params: { action: 'currentrates' }, isArray: false },
}));
