import {controllers} from '../app';

controllers.controller('PackageListController', ['$rootScope', '$scope', 'PackageService', '$location', function ($rootScope, $scope, PackageService, $location) {
    $scope.init = function () {
        $scope.packages = [];
        $scope.scrollEnabled = true;
        $scope.offset = 0;
        $scope.limit = 25;
    };

    $scope.open = function (packageObject) {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        $location.path(`/settings/packages/${packageObject.id}`);
    };

    $scope.search = function () {
        $scope.scrollEnabled = false;

        PackageService.query({ name: $rootScope.globalSearch, offset: $scope.offset, limit: $scope.limit }, (json) => {
            $scope.packages = $scope.packages.concat(json);
            $scope.scrollEnabled = json.length === $scope.limit;
            });
    };

    $('*').scroll(function () {
        if ($scope.scrollEnabled && _.isFunction($scope.search)) {
            let scroll = $(this).scrollTop() + $(this).innerHeight();
            let pageHeight = $(this)[0].scrollHeight;
            if (scroll >= pageHeight) {
                $scope.offset += $scope.limit;
                $scope.search();
            }
        }
    });

    $scope.init();
    $scope.search();
}]);
