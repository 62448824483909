import {entities} from '../app';

entities.factory('Comment', ['CommentService', function (CommentService) {
    return function (data) {
        if (data) {
            this.id = data.id;
            this.comment = data.comment;
            this.type = data.type;
            this.user = data.user;
            this.createdDateTime = data.createdDateTime;

            this.campaign = null;
            if (data.type === 'campaign') {
                this.campaign = data.campaign;
            }

            this.week = null;
            this.year = null;
            if (data.type === 'weekyear') {
                this.week = data.week;
                this.year = data.year;
            }

            this.object = null;
            if (data.type === 'object') {
                this.object = data.object;
            }
        }

        const self = this;

        this.setCampaign = function (campaign) {
            this.campaign = campaign;
        };

        this.setObject = function (object) {
            this.object = object;
        };

        this.setType = function (type) {
            this.type = type;
        };

        this.save = function () {
            if (this.id) {
                return CommentService.update({ id: this.id }, this).$promise;
            }

            return CommentService.save(this, (response) => {
                self.id = response.id;
                self.relation = response.relation;
                self.createdDateTime = response.createdDateTime;
            }).$promise;
        };
    };
}]);
