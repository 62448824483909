import {controllers} from '../app';
// phpcs:disable Generic.WhiteSpace.ScopeIndent.Incorrect, Generic.WhiteSpace.ScopeIndent.IncorrectExact
controllers.controller('PackageDetailsController', ['$rootScope', '$scope', 'PackageService', '$routeParams', 'OrganisationService', function ($rootScope, $scope, PackageService, $routeParams, OrganisationService) {
    $scope.init = function () {
        $scope.package = {};
        $scope.series = [];
        $scope.formEnabled = false;

        PackageService.get(
            { id: $routeParams.packageId },
            (data) => {
            $scope.package = data;
            },
        );
    };

    const organisations = new Bloodhound({
        datumTokenizer(d) {
            return Bloodhound.tokenizers.whitespace(d.num);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/organisations', // Required
            replace(url, query) {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                return `/organisations?query=${query}&businessType=${$scope.form.organisationType}`;
            },
        },
    });

    $scope.searchOptions = {
        highlight: true,
    };

    $scope.organisationData = {
        displayKey: 'name',
        source: organisations.ttAdapter(),
    };

    organisations.initialize();

    $scope.loadSeriesByOrganisation = function () {
        OrganisationService.getSeries({ id: $scope.form.organisation.id }, (series) => {
            $scope.series = series;
        });
    };

    $scope.$watch('form.organisation', (newValue, oldValue) => {
        if ((oldValue !== newValue) && newValue && (typeof newValue.id !== 'undefined')) {
            $scope.loadSeriesByOrganisation($scope.form.organisation);
        }
    });

    $scope.$watch('form.organisationType', (newValue, oldValue) => {
        if (oldValue !== newValue) {
            $scope.resetOrganisation();
        }
    });

    $scope.resetOrganisation = function () {
        $scope.form.organisation = null;
        $scope.form.serie = null;
    };

    $scope.addSerie = function (serie) {
        PackageService.addSerie(
            {
                id: $scope.package.id,
                serieId: serie.id,
            },
            () => {
            if (typeof $scope.package.series.find((packageSerie) => packageSerie.id === serie.id) === 'undefined') {
                $scope.package.series.push(serie);
            }
            },
            (errorResponse) => {
            console.log('errorResponse', errorResponse);
            },
        );
    };

    $scope.removeSerie = function (serieKey) {
        return PackageService.removeSerie(
            {
                id: $scope.package.id,
                serieId: $scope.package.series[serieKey].id,
            },
            () => {
            $scope.package.series.splice(serieKey, 1);
            },
            (errorResponse) => {
            console.log('errorResponse', errorResponse);
            },
        );
    };

    $scope.showForm = function () {
        $scope.formEnabled = true;
    };

    $scope.init();
}]);
