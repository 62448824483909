import {entities} from '../app';

entities.factory('Malfunction', () => function (data) {
    if (!data) {
        data = {};
    }

    this.id = data.id;
    this.code = data.code;
    this.description = data.description;
    this.createdDateTime = data.createdDateTime;
    this.updatedDateTime = data.updatedDateTime;
});
