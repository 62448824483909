import {entities} from '../app';
// phpcs:ignoreFile
entities.factory('Campaign', ['CampaignService', 'CampaignPlanning', 'Package', 'PlanningOption', 'Orderline', function (CampaignService, CampaignPlanning, Package, PlanningOption, Orderline) {
    return function (data) {
        this.id = data.id;
        this.name = data.name;
        this.businessType = data.businessType;
        this.quoteStage = data.quoteStage;
        this.package = null;
        this.competitor = false;
        this.campaignPlanning = [];
        this.planningOptions = [];
        this.numberOfAdSpots = {
            total: 0,
            package: 0,
        };
        this.orderlines = [];
        this.orderlinesDiscounts = [];
        this.rate = data.rate;
        this.materialStatus = data.materialStatus;
        this.materialQuality = data.materialQuality;
        this.destroyMaterials = data.destroyMaterials;
        this.type = data.type;


        if (data.package) {
            this.package = new Package(data.package);
        }

        let self = this;

        /**
         * @returns {*}
         */
        this.getCampaignPlanning = function (options) {
            this.campaignPlanning = [];
            self = this;

            const params = angular.extend(options, {id: this.id});

            return CampaignService
                .getCampaignPlanning(
                    params,
                    (response) => { // @todo return organisations instead of campaign planning
                        angular.forEach(response, (campaignData) =>
                        {
                            const campaignPlanning = new CampaignPlanning(campaignData, false);
                            campaignPlanning.setCampaign(self);

                            self.campaignPlanning.push(campaignPlanning);
                        });
                    }).$promise;
        };

        /**
         * @returns {*}
         */
        this.getPlanningOptions = function (options) {
            this.planningOptions = [];
            self = this;

            const params = angular.extend(options, {id: this.id});

            return CampaignService
                .getPlanningOptions(
                    params,
                    (response) => { // @todo return organisations instead of campaign planning
                        angular.forEach(response, (planningOptionData) =>
                        {
                            const planningOption = new PlanningOption(planningOptionData, false);
                            planningOption.setCampaign(self);

                            self.planningOptions.push(planningOption);
                        });
                    }).$promise;
        };

        /**
         * @returns {*}
         */
        this.createOffer = function () {
            return CampaignService.createOffer({id: this.id}).$promise;
        };

        /**
         * @param {Package} pkg
         * @param week
         * @param year
         * @returns {*}
         */
        this.planPackage = function (pkg, week, year) {
            self = this;
            return CampaignService.planPackage(
                {
                    id: this.id,
                    subId: pkg.id,
                },
                {
                    week,
                    year,
                },
                () =>
                {
                    self.package = pkg;
                },
            ).$promise;
        };

        /**
         * @returns {string}
         */
        this.getHSLColorForCampaign = function () {
            const hsl = {
                hue: this.id % 360,
                saturation: ((this.id + 1) * (this.id ** 2)) % 75 + 25,
                lightness: ((this.id + 20) * (this.id ** 4)) % 50 + 20,
            };
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`;
        };

        /**
         * @returns {string}
         */
        this.getBackgroundHSLColorForPlanning = function (planning) {
            let hsl = {
                hue: 0,
                saturation: 0,
                lightness: 94,
            };

            if (
                planning.id
                && (
                    planning.hasStatus('LOCKED')
                    || this.quoteStage === 'SIGNED'
                    || this.quoteStage === 'CONFIRMED'
                )
            ) {
                hsl = {
                    hue: this.id % 360,
                    saturation: ((this.id + 1) * (this.id ** 2)) % 75 + 25,
                    lightness: (((this.id + 20) * (this.id ** 4)) % 50) + 40,
                };
            }
            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
            return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`;
        };

        /**
         * @returns {*}
         */
        this.removePlanningByPackage = function () {
            self = this;
            return CampaignService.deleteCampaignPackage({
                id: this.id,
                subId: this.package.id,
            }, () => {
                self.package = null;
            }).$promise;
        };

        /**
         * @param organisation
         * @returns {*}
         */
        this.removePlanningByOrganisation = function (organisation) {
            return CampaignService.deleteCampaignOrganisation({
                id: this.id,
                subId: organisation.id,
            }).$promise;
        };

        /**
         * @TODO: Add timeout if this will be performance issue.
         */
        this.getNumberOfAdSpots = function () {
            this.numberOfAdSpots = null;

            CampaignService.getNumberOfAdSpots({id: self.id}, (response) => {
                self.numberOfAdSpots = response.result;
            });
        };

        this.getRevenue = function (rootScope) {
            this.revenue = null;

            CampaignService.getRevenue({id: self.id}, (response) => {
                self.revenue = response.result;
                if (self.revenue.net < 0) {
                    rootScope.errors.push({
                        message: 'De Netto omzet mag niet lager zijn dan 0.',
                    });
                }
            });
        };

        /*
         * @param {Package} pkg
         * @returns {boolean}
         */
        this.isEqualToPackage = function (pkg) {
            return pkg && this.package !== null && this.package.id === pkg.id;
        };

        this.getOrderLines = function () {
            this.orderlines = [];
            CampaignService.getOrderLines({id: self.id}, (response) => {
                self.processOrderLines(response, self.orderlines);
            });
        };

        this.getOrderLinesDiscounts = function () {
            this.orderlinesDiscounts = [];
            CampaignService.getOrderLinesDiscounts({id: self.id}, (response) => {
                self.processOrderLines(response, self.orderlinesDiscounts);
            });
        };

        this.processOrderLines = function (response, stack) {
            angular.forEach(response, (orderlineData) => {
                const orderline = new Orderline(orderlineData);
                orderline.setCampaign(self);

                stack.push(orderline);
            });

            const newOrderline = new Orderline();
            newOrderline.setCampaign(self);
            stack.push(newOrderline);
        };

        this.getStopperAvailableAdSpots = function (serie) {
            return CampaignService.getStopperAvailableAdSpots(
                {
                    id: self.id,
                    subId: serie.id,
                },
            ).$promise;
        };
    };
}]);
