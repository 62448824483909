import {directives} from '../app';

directives.directive('objectMap', [
    '$rootScope',
    'ObjectService',
    '$filter',
    '$timeout',
    '$q',
    'uiGmapIsReady',
    'uiGmapGoogleMapApi',
    'ngDialog',
    function (
        $rootScope,
        ObjectService,
        $filter,
        $timeout,
        $q,
        uiGmapIsReady,
        uiGmapGoogleMapApi,
        ngDialog,
    ) {
        return {
            restrict: 'EA',
            templateUrl: '/build/html/partials/object-map.html',
            scope: {
                givenObject: '=',
            },
            link($scope) {
                $scope.object = null;
                $scope.selectedMarker = null;
                $scope.markerStack = [];
                $scope.moveMarkerStack = true;
                $scope.refreshing = true;
                $scope.objectTypes = $rootScope.objectTypes;
                $scope.objectSizes = $rootScope.objectSizes;

                $scope.map = {
                    center: {
                        latitude: 52.2836652,
                        longitude: 4.8323447,
                    },
                    cluster: true,
                    draggable: true,
                    zoom: 15,
                    ownMarker: [],
                    updatingMarker: [],
                    markers: [],
                    markerEvents: {
                        click(marker, eventName, model) {
                            if (!$scope.givenObject || $scope.givenObject.id === model.id) {
                                $scope.selectMarker(model);
                                $scope.updateMarkerStack(model);
                            }
                        },
                        drag(marker) {
                            $scope.newObjectCoordinates = {};
                            $scope.newObjectCoordinates.GPSPositionX = marker.position.lat();
                            $scope.newObjectCoordinates.GPSPositionY = marker.position.lng();
                        },
                    },
                    markerOptions: {
                        updatingMarker: {
                            draggable: true,
                        },
                        markers: {
                            draggable: false,
                        },
                    },
                    window: {
                        marker: {},
                        model: {},
                        show: false,
                        closeClick() {
                            this.show = false;
                        },
                        options: {},
                    },
                };

                /**
                 * Update the map, get and place all markers that are within the viewport
                 */
                $scope.updateVisibleMarkers = function () {
                    $scope.refreshing = true;

                    return $scope.getGMap()
                        .then((gMap) => {
                            const bounds = gMap.getBounds();
                            const northEast = bounds.getNorthEast();
                            const southWest = bounds.getSouthWest();

                            return {
                                minX: southWest.lat(),
                                maxX: northEast.lat(),
                                minY: southWest.lng(),
                                maxY: northEast.lng(),
                            };
                        })
                        .then((positions) => ObjectService.query({
                            onlyGPS: true,
                            minX: positions.minX,
                            maxX: positions.maxX,
                            minY: positions.minY,
                            maxY: positions.maxY,
                        }).$promise)
                        .then((objects) => {
                            $scope.map.markers = objects.map((item) => ({
                                id: parseInt(item.id, 10),
                                latitude: parseFloat(item.GPSPositionX),
                                longitude: parseFloat(item.GPSPositionY),
                                showWindow: true,
                                icon: '/build/img/icons/marker_red.png',
                            }));
                            $scope.refreshing = false;
                        });
                };

                /**
                 * Highlight a marker and all markers on the same spot
                 * @param marker
                 */
                $scope.highlightMarker = function (marker) {
                    for (let i = 0; i < $scope.map.markers.length; i++) {
                        $scope.map.markers[i].icon = '/build/img/icons/marker_red.png';
                        if (
                            $scope.map.markers[i].latitude === marker.latitude
                            && $scope.map.markers[i].longitude === marker.longitude
                        ) {
                            $scope.map.markers[i].icon = '/build/img/icons/marker_blue.png';
                        }
                    }
                };

                /**
                 * Update the stack of markers that are on the same position for the dropdown.
                 * Set the currently active marker as selected
                 * @param marker
                 */
                $scope.updateMarkerStack = function (marker) {
                    $scope.markerStack = [];

                    for (let i = 0; i < $scope.map.markers.length; i++) {
                        if (
                            $scope.map.markers[i].latitude === marker.latitude
                            && $scope.map.markers[i].longitude === marker.longitude
                        ) {
                            $scope.markerStack.push($scope.map.markers[i]);
                        }
                    }

                    $scope.selectedMarker = marker;
                };

                /**
                 * Select a marker by clicking on it, searching for CCID,
                 * selecting it from the dropdown or when opening an existing inspection.
                 * @param marker
                 */
                $scope.selectMarker = function (marker) {
                    if (!marker) {
                        return null;
                    }

                    $scope.map.window.closeClick();
                    $scope.highlightMarker(marker);

                    return $scope.getObject(marker.id, false)
                        .then(() => {
                            $scope.map.window.model = marker;
                            $scope.map.window.model.object = $scope.object;
                            $scope.map.window.show = true;
                            $scope.map.window.options.pixelOffset = new google.maps.Size(0, -30);
                        });
                };

                /**
                 * Get an object by CCID and set it in the scope
                 * (skip if the object is already active: same id)
                 * @param ccid
                 * @param force
                 */
                $scope.getObject = function (ccid, force) {
                    if ($scope.object && $scope.object.id === ccid && !force) {
                        return $q.resolve($scope.object);
                    }

                    if ($scope.givenObject && !force) {
                        $scope.ccid = $scope.givenObject.id;
                        $scope.object = $scope.givenObject;
                        return $q.resolve()
                            .finally(() => {
                                $rootScope.$broadcast('object-map:object-selected', $scope.object);
                            });
                    }

                    return ObjectService.get({ id: ccid })
                        .$promise
                        .then((data) => {
                            $scope.ccid = data.id;
                            $scope.object = data;
                            $scope.giveObject = data;
                        })
                        .catch(() => {
                            angular.element('.ccid-search')
                                .addClass('not-found');
                            $timeout(() => {
                                angular.element('.ccid-search')
                                    .removeClass('not-found');
                            }, 500);
                        })
                        .finally(() => {
                            $rootScope.$broadcast('object-map:object-selected', $scope.object);
                        });
                };

                /**
                 * Get google Maps instance
                 */
                $scope.getGMap = function () {
                    return uiGmapIsReady.promise(1)
                        .then((instances) => instances[0].map);
                };

                /**
                 * Returns location from device (Note: Only works on HTTPS in Chrome)
                 * @returns {*}
                 */
                $scope.getCurrentLocation = function () {
                    return $q((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition((pos) => {
                            resolve(pos);
                        }, (error) => {
                            reject(error);
                        }, { enableHighAccuracy: true });
                    });
                };

                /**
                 * Centers the map at coordinates
                 * @param coords
                 * @param placeOwnMarker
                 * @returns {*}
                 */
                $scope.centerMap = function (coords, placeOwnMarker) {
                    const promise = new Promise((resolve) => {
                        const map = angular.copy($scope.map);

                        map.center.latitude = coords.latitude;
                        map.center.longitude = coords.longitude;

                        if (placeOwnMarker) {
                            $scope.map.ownMarker = [];
                            map.ownMarker.push({
                                id: 0,
                                latitude: coords.latitude,
                                longitude: coords.longitude,
                                showWindow: false,
                                icon: '/build/img/icons/marker_green.png',
                            });
                        }

                        resolve(map);
                    });

                    return promise.then((map) => {
                        $scope.$apply($scope.map = map);
                        return Promise.resolve();
                    });
                };

                /**
                 * Search for the CCID currently in the search field
                 */
                $scope.searchObject = function () {
                    $scope.object = {};
                    $scope.markerStack = [];

                    if ($scope.ccid) {
                        $scope.loadObject($scope.ccid);
                    } else {
                        $scope.reset();
                        $rootScope.$broadcast('object-map:object-selected', $scope.object);
                    }
                };

                /**
                 * Called when google map initializes.
                 * Reset to current location or load object of existing inspection
                 */
                $scope.initialize = function () {
                    if ($scope.givenObject) {
                        $scope.loadObject($scope.givenObject.id);
                    } else {
                        $scope.reset();
                    }
                };

                /**
                 * Reset view to current device location and get all markers of nearby objects
                 * Called upon initializing, searching for nonexistent object
                 * or when clicking the button
                 */
                $scope.reset = function () {
                    $scope.object = null;
                    $scope.map.zoom = 15;
                    $scope.ccid = null;
                    $scope.selectedMarker = null;
                    $scope.markerStack = [];
                    $scope.map.window.closeClick();

                    $scope.$emit('object-map:reset');

                    $scope.getCurrentLocation()
                        .then((position) => $scope.centerMap(position.coords, true))
                        .finally(() => $scope.updateVisibleMarkers());
                };

                /**
                 * Load a single object, center the map on it, and get markers of objects near it
                 * @param id
                 * @param force
                 */
                $scope.loadObject = function (id, force) {
                    $scope.markers = [];
                    $scope.map.window.closeClick();

                    $scope.getObject(id, force)
                        .then(() => {
                            $scope.centerMap({
                                latitude: $scope.object.GPSPositionX,
                                longitude: $scope.object.GPSPositionY,
                            }, false)
                                .then(() => {
                                    $scope.map.zoom = 17;
                                    return $scope.updateVisibleMarkers();
                                })
                                .then(() => {
                                    for (let i = 0; i < $scope.map.markers.length; i++) {
                                        if ($scope.map.markers[i].id === $scope.object.id) {
                                            $scope.selectMarker($scope.map.markers[i]);
                                            $scope.updateMarkerStack($scope.map.markers[i]);
                                            break;
                                        }
                                    }
                                });
                        });
                };

                /**
                 * Turn clustering on and off on this zoomlevel
                 */
                $scope.$watchCollection('map', (newValue) => {
                    $scope.map.cluster = newValue.zoom <= 18;
                });

                /**
                 * Initialize after the Google Maps instance loads
                 */
                uiGmapGoogleMapApi.then(() => {
                    $scope.initialize();
                });

                $scope.openObjectDialog = function (objectId) {
                    ObjectService.get({ id: objectId })
                        .$promise
                        .then((data) => {
                            $scope.form = data;

                            $scope.openSerie = function (serieId) {
                                if (serieId) {
                                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                                    window.open(`/#/settings/series/${serieId}`, '_self');
                                    ngDialog.close('object-form');
                                }
                            };

                            ngDialog.openConfirm({
                                template: '/build/html/partials/ngDialogTemplates/object-form.html',
                                className: 'ngdialog-theme-default ngdialogBig',
                                name: 'object-form',
                                scope: $scope,
                            })
                                .then((formObject) => {
                                    ObjectService.update(formObject);
                                    $scope.object = formObject;
                                });
                        });
                };

                $scope.validate = function (object) {
                    if (object.postcode) {
                        object.postcode = object.postcode.replace(/ /g, '')
                            .toUpperCase();
                        if (!object.postcode.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
                            $scope.form.postcodeError = true;
                            return false;
                        }
                    }
                    $scope.form.postcodeError = false;
                    return true;
                };

                $scope.editObjectCoordinates = function (object) {
                    $scope.editingObjectCoordinates = true;

                    if (typeof $scope.markerStack.find((item) => item.id === object.id)
                        !== 'undefined') {
                        const marker = {
                            id: parseInt(object.id, 10),
                            latitude: parseFloat(object.GPSPositionX),
                            longitude: parseFloat(object.GPSPositionY),
                            showWindow: false,
                            icon: '/build/img/icons/marker_yellow.png',
                        };

                        $scope.map.updatingMarker = [marker];
                        $scope.map.window.show = false;
                        $scope.map.zoom = 20;
                        $scope.centerMap({
                            latitude: marker.latitude,
                            longitude: marker.longitude,
                        }, false);
                    }
                };

                $scope.cancelEditingObjectCoordinates = function () {
                    $scope.editingObjectCoordinates = false;
                    $scope.map.updatingMarker = [];
                    $scope.map.window.show = true;
                    $scope.newObjectCoordinates = null;
                };

                $scope.saveObjectCoordinates = function (moveMarkerStack) {
                    if (!($rootScope.user.isAuthorizedTo('manage_objects_full')
                        || $rootScope.user.isAuthorizedTo('manage_objects_fieldservice'))) {
                        return;
                    }

                    const updatingMarker = $scope.map.updatingMarker[0];
                    let objectIds = [];
                    if (moveMarkerStack) {
                        for (let i = 0; i < $scope.markerStack.length; i++) {
                            objectIds.push($scope.markerStack[i].id);
                        }
                    } else {
                        objectIds = [updatingMarker.id];
                    }

                    ObjectService.updateCoordinates({
                        ids: objectIds,
                        GPSPositionX: $scope.newObjectCoordinates.GPSPositionX,
                        GPSPositionY: $scope.newObjectCoordinates.GPSPositionY,
                    })
                        .$promise
                        .then(() => {
                            $scope.cancelEditingObjectCoordinates();
                            $scope.object = null;
                            $scope.loadObject($scope.ccid, true);
                        });
                };

                /**
                 * TODO: Clean this stuff up with a directive
                 */
                const organisations = new Bloodhound({
                    datumTokenizer(d) {
                        return Bloodhound.tokenizers.whitespace(d.num);
                    },
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: '/organisations', // Required
                        replace(url, query) {
                            // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                            return `/organisations?query=${query}&type=owner`;
                        },
                    },
                });

                $scope.searchOptions = {
                    serie: {
                        highlight: true,
                        type: 'serie',
                    },
                    owner: {
                        highlight: true,
                        type: 'owner',
                    },
                    formowner: {
                        highlight: true,
                        type: 'formowner',
                    },
                };

                $scope.organisationData = {
                    displayKey: 'name',
                    source: organisations.ttAdapter(),
                };

                organisations.initialize();

                $scope.$on('typeahead:selected', (event) => {
                    if (
                        event.targetScope.options.type === 'serie'
                        && !$scope.serieLocked
                        && $scope.selected.serie.name !== ''
                    ) {
                        const serie = $scope.selected.serie.name;
                        $scope.selected.serie.id = serie.id;
                        $scope.selected.serie.name = serie.name;
                        $scope.serieLocked = true;
                    }

                    if (event.targetScope.options.type === 'owner') {
                        const owner = $scope.selected.owner.name;
                        $scope.selected.owner.id = owner.id;
                        $scope.selected.owner.name = owner.name;
                    }

                    if (event.targetScope.options.type === 'formowner') {
                        const owner = $scope.form.owner.name;
                        $scope.form.owner.id = owner.id;
                        $scope.form.owner.name = owner.name;
                    }
                    $scope.$apply();
                });
            },
        };
    }]);
