import {directives} from '../app';

directives.directive('paginator', () => ({
    restrict: 'A',
    scope: true,
    link($scope) {
        let lastCall = null;

        $scope.sort = '';
        $scope.sortOrder = '';
        $scope.search = '';
        $scope.houseNumber = '';
        $scope.postalCode = '';
        $scope.exactId = '';
        $scope.offset = 0;
        $scope.limit = 25;
        $scope.scrollEnabled = true;

        $scope.initialRequest = function () {
            $scope.api({ offset: $scope.offset, limit: $scope.limit }, (json) => {
                $scope.data = json;
            });
        };

        $scope.apiSearch = function () {
            $scope.scrollEnabled = false;

            const currentCall = (new Date()).getTime();
            lastCall = currentCall;

            $scope.api({
                query: $scope.search,
                orderBy: $scope.sort,
                order: $scope.sortOrder,
                offset: $scope.offset,
                limit: $scope.limit,
            }, (json) => {
                if (currentCall !== lastCall) {
                    return;
                }

                if ($scope.data) {
                    $scope.data = $scope.data.concat(json);
                } else {
                    $scope.data = json;
                }

                $scope.scrollEnabled = json.length >= $scope.limit;
            });
        };

        $('*').scroll(function () {
            if ($scope.scrollEnabled && _.isFunction($scope.search)) {
                let scroll = $(this).scrollTop() + $(this).innerHeight();
                let pageHeight = $(this)[0].scrollHeight;
                if (scroll >= pageHeight) {
                    $scope.offset += $scope.limit;
                    $scope.search();
                }
            }
        });

        $scope.initialRequest();
    },
}));
