import { entities, WeekYear } from '../app';
// phpcs:disable Generic.WhiteSpace.ScopeIndent.Incorrect, Generic.WhiteSpace.ScopeIndent.IncorrectExact
entities.factory('Organisation', [
    '$rootScope',
    'OrganisationService',
    'Serie',
    'CampaignPlanning',
    'CampaignService',
    'CampaignPlanningService',
    'PlanningOption',
    function (
        $rootScope,
        OrganisationService,
        Serie,
        CampaignPlanning,
        CampaignService,
        CampaignPlanningService,
        PlanningOption,
    ) {
        return function (data) {
            this.id = data.id;
            this.city = data.city;
            this.name = data.name;
            this.description = data.description;
            this.series = [];
            this.newSeries = [];
            this.isLocked = false;
            this.campaignPlanning = [];
            this.planningOptions = [];
            this.isShowable = false;
            this.plannedRates = data.plannedRates;
            this.heatMap = [];
            this.revenue = null;
            this.contracts = data.contracts;

            let self = this;

            /**
             * @returns $promise
             */
            this.getSeries = function (options) {
                self.newSeries = [];

                if (!options.offset) {
                    this.series = [];
                }

                self = this;

                const params = angular.extend(options, { id: this.id });

                return OrganisationService.getSeries(params)
                    .$promise
                    .then((response) => {
                        angular.forEach(response, (serieData) => {
                            const serie = new Serie(serieData, false);
                            serie.setOrganisation(self);

                            self.newSeries.push(serie);
                            self.series.push(serie);
                        });

                        return response;
                    });
            };

            /**
             * @returns $promise
             */
            this.getStatistics = function (options) {
                this.statistics = [];

                self = this;

                const params = angular.extend(options, { id: this.id });

                return OrganisationService.getStatistics(params)
                    .$promise
                    .then((response) => {
                        self.statistics = response;
                    });
            };

            /**
             * @param campaign
             */
            this.getRevenueByCampaign = function (campaign) {
                delete self.revenue;

                const options = {
                    id: campaign.id,
                    subId: self.id,
                };

                CampaignService.getRevenueByOrganisation(options)
                    .$promise
                    .then((response) => {
                        self.revenue = response.result.gross;
                    });
            };

            /**
             * @param weekYears
             * @param series
             * @returns {*}
             */
            this.getCampaignPlanning = function (weekYears, series) {
                const serieIds = [];
                angular.forEach(series, (serie) => {
                    serieIds.push(serie.id);
                });

                const wys = [];
                angular.forEach(weekYears, (weekYear) => {
                    wys.push(weekYear.getSimpleObject());
                });

                const params = {
                    id: this.id,
                    requestParams: decodeURIComponent($.param({
                        weekYears: wys,
                        serieIds,
                    })),
                };

                self = this;

                return OrganisationService.getCampaignPlanning(params)
                    .$promise
                    .then((response) => {
                        angular.forEach(response, (campaignPlanningData) => {
                            if (!self.containsCampaignPlanning(campaignPlanningData)) {
                                const campaignPlanning = new CampaignPlanning(campaignPlanningData);
                                campaignPlanning.serie.setOrganisation(self);
                                self.campaignPlanning.push(campaignPlanning);
                            }
                        });
                    });
            };

            this.findPlanningForSerie = function (serie, campaign) {
                return this.campaignPlanning.find(
                    (item) => (item.serie.id === serie.id && item.campaign.id === campaign.id),
                );
            };

            /**
             * @param {Serie} serie
             * @param {WeekYear} weekYear
             * @returns {Array}
             */
            this.findPlanningForSerieAndWeekYear = function (serie, weekYear) {
                const planning = [];

                if (serie.isFromType('scroller', 'custom', 'screen', 'folders', 'stopper')) {
                    const ids = [];
                    for (let i = 0; i <= this.campaignPlanning.length; i++) {
                        const plan = this.findPlanningForSerieAndWeekYearPerCampaign(
                            serie,
                            weekYear,
                            ids,
                        );
                        planning.push(plan);
                        if (plan === null) {
                            break;
                        }
                        ids.push(plan.id);
                    }

                    return planning;
                }

                planning.push(this.findPlanningForSerieAndWeekYearPerCampaign(serie, weekYear));

                return planning;
            };

            /**
             * @param {Serie} serie
             * @param {WeekYear} weekYear
             * @param {Array} ids
             * @returns {CampaignPlanning|null}
             */
            this.findPlanningForSerieAndWeekYearPerCampaign = function (serie, weekYear, ids = []) {
                const plan = this.campaignPlanning.find((item) => (
                    item.serie.id === serie.id
                    && weekYear.isEqual(item.weekYear)
                    && ids.indexOf(item.id) === -1));

                return typeof plan !== 'undefined' ? plan : null;
            };

            /**
             * @param campaign
             * @param serie
             * @param weekYear
             * @returns {boolean}
             */
            this.hasCampaignPlannedForSerieAndWeekYear = function (campaign, serie, weekYear) {
                if (serie.isFromType('custom', 'screen', 'folders', 'stopper')) {
                    return false;
                }

                const planning = this.findPlanningForSerieAndWeekYear(serie, weekYear);

                if (!serie.isFromType('scroller')) {
                    return planning && planning.length > 0 && planning[0] !== null;
                }

                return planning.some((item) => item.campaign.id === campaign.id);
            };

            /**
             * @param campaign
             * @param weekYear
             * @returns {boolean}
             */
            this.hasCampaignPlannedForWeekYear = function (campaign, weekYear) {
                return this.campaignPlanning.some((item) => (
                    campaign.id === item.campaign.id && weekYear.isEqual(item.weekYear)
                ));
            };

            /**
             * @param planning
             */
            this.deletePlanning = function (planning) {
                if (!(planning.id in this.campaignPlanning)) {
                    return;
                }

                delete this.campaignPlanning[planning.id];

                self = this;
                planning.delete()
                    .then(
                        null,
                        () => {
                        self.campaignPlanning[planning.id] = planning;
                        },
                    );
            };

            /**
             * @param campaign
             * @param serie
             * @param weekYear
             * @param packageId
             * @param startDateOn
             * @param endDateOn
             * @param amount
             * @param priceLevel
             * @param description
             * @returns {*}
             */
            this.addPlanning = function (
                campaign,
                serie,
                weekYear,
                packageId,
                startDateOn,
                endDateOn,
                amount,
                priceLevel,
                description,
            ) {
                self = this;

                // @todo fill campaign planning object and use save there
                return CampaignPlanningService.save(
                    [],
                    {
                        campaignId: campaign.id,
                        week: weekYear.week,
                        year: weekYear.year,
                        serieId: serie.id,
                        packageId,
                        startDateOn,
                        endDateOn,
                        amount,
                        priceLevel: priceLevel || campaign.rate,
                        description,
                    },
                    (response) => {
                    const campaignPlanning = new CampaignPlanning(response);
                    campaignPlanning.setSerie(serie);
                    campaignPlanning.serie.setOrganisation(self);
                    campaignPlanning.setCampaign(campaign);
                    self.campaignPlanning.push(campaignPlanning);
                    if (response.customMaterialSizeChanged) {
                            $rootScope.$broadcast('campaignmaterials-changed', campaign.id);
                    }
                    },
                ).$promise;
            };

            /**
             * @param campaignPlanning
             * @returns {*}
             */
            this.removePlanning = function (campaignPlanning) {
                self = this;

                return CampaignService.deleteCampaignPlanning(
                    {
                        id: campaignPlanning.campaign.id,
                        subId: campaignPlanning.id,
                    },
                    null,
                    () => {
                    for (let i = 0; i < self.campaignPlanning.length; i++) {
                            if (self.campaignPlanning[i].id === campaignPlanning.id) {
                                self.campaignPlanning.remove(i);
                                break;
                                }
                    }
                    },
                ).$promise;
            };

            /**
             * @param campaignPlanning
             * @returns {*}
             */
            this.updatePlanning = function (campaignPlanning) {
                self = this;

                return campaignPlanning.update()
                    .then(
                        (response) => {
                        const updated = new CampaignPlanning(response);
                        updated.setSerie(campaignPlanning.serie);
                        updated.serie.setOrganisation(self);
                        updated.setCampaign(campaignPlanning.campaign);
                        const current = self.campaignPlanning.findIndex((item) => (
                                item.id === campaignPlanning.id
                            ));
                        self.campaignPlanning[current] = campaignPlanning;
                        $rootScope.$broadcast('planning-changed');
                        return campaignPlanning;
                        },
                    );
            };

            /**
             * @param campaign
             * @param serie
             * @param weekYear
             * @param packageId
             * @param values
             * @returns {*}
             */
            this.addSpecialPlanning = function (campaign, serie, weekYear, packageId, values) {
                self = this;

                // @todo fill campaign planning object and use save there
                return CampaignPlanningService.save(
                    [],
                    {
                        campaignId: campaign.id,
                        week: weekYear.week,
                        year: weekYear.year,
                        serieId: serie.id,
                        packageId,
                        startDateOn: typeof values.startDateOn === 'undefined'
                            ? null
                            : values.startDateOn,
                        endDateOn: typeof values.endDateOn === 'undefined'
                            ? null
                            : values.endDateOn,
                        amount: values.numberOfObjects,
                        objectAmount: (values.objectAmount * 100).toFixed(2),
                        quoteDescription: values.quoteDescription,
                        priceLevel: campaign.rate,
                    },
                    (response) => {
                    const campaignPlanning = new CampaignPlanning(response);
                    campaignPlanning.setSerie(serie);
                    campaignPlanning.serie.setOrganisation(self);
                    campaignPlanning.setCampaign(campaign);
                    self.campaignPlanning.push(campaignPlanning);
                    },
                ).$promise;
            };

            /**
             * @param campaignPlanningId
             * @returns {campaignPlanning}
             */
            this.findCampaignPlanningById = function (campaignPlanningId) {
                for (let i = 0; i < self.campaignPlanning.length; i++) {
                    if (self.campaignPlanning[i].id === campaignPlanningId) {
                        return self.campaignPlanning[i];
                    }
                }

                return null;
            };

            /**
             * @todo plannedRates should contain 'heat' objects with weekYear objects
             * @param weekYears
             */
            this.buildHeatMap = function (weekYears) {
                self = this;

                angular.forEach(weekYears, (weekYear) => {
                    let heatMap = {
                        weekYear,
                        heat: 0,
                        optional: 0,
                        isLockedCompletely: false,
                    };

                    angular.forEach(self.plannedRates, (plannedRate) => {
                        if (plannedRate.week === weekYear.week && plannedRate.year ===
                            weekYear.year) {
                            heatMap = {
                                weekYear,
                                heat: plannedRate.percentage,
                                optional: plannedRate.optional,
                                isLockedCompletely: plannedRate.isLockedCompletely,
                            };
                        }
                    });

                    self.heatMap.push(heatMap);
                });
            };

            /**
             * @param weekYear
             * @param isSerieTypeScrollerAllowed
             * @returns {Array}
             */
            this.findCampaignPlanningByWeekYear = function (weekYear, isSerieTypeScrollerAllowed) {
                isSerieTypeScrollerAllowed = typeof isSerieTypeScrollerAllowed === 'undefined'
                    ? true
                    : isSerieTypeScrollerAllowed;
                const campaignPlanning = [];
                for (let i = 0; i < self.campaignPlanning.length; i++) {
                    if (weekYear.isEqual(self.campaignPlanning[i].weekYear)
                        && (isSerieTypeScrollerAllowed ||
                            !self.campaignPlanning[i].serie.isFromType('scroller'))
                    ) {
                        campaignPlanning.push(self.campaignPlanning[i]);
                    }
                }

                return campaignPlanning;
            };

            /**
             * @param weekYears
             * @returns {boolean}
             */
            this.hasFullyPlannedWeekYear = function (weekYears) {
                for (let i = 0; i < weekYears.length; i++) {
                    if (this.isWeekYearFullyPlanned(weekYears[i])) {
                        return true;
                    }
                }

                return false;
            };

            /**
             * @param weekYear
             * @returns {boolean}
             */
            this.isWeekYearFullyPlanned = function (weekYear) {
                return this.series.length === this.findCampaignPlanningByWeekYear(
                    weekYear,
                    false,
                ).length;
            };

            /**
             * @param campaign
             * @param weekYears
             * @returns {boolean}
             */
            this.hasFullyPlannedWeekYearAndCampaignNotPlanned = function (campaign, weekYears) {
                for (let i = 0; i < weekYears.length; i++) {
                    if (this.isWeekYearFullyPlannedAndCampaignNotPlanned(campaign, weekYears[i])) {
                        return true;
                    }
                }

                return false;
            };

            /**
             * @param campaign
             * @param weekYear
             * @returns {boolean}
             */
            this.isWeekYearFullyPlannedAndCampaignNotPlanned = function (campaign, weekYear) {
                return !this.hasCampaignPlannedForWeekYear(campaign, weekYear)
                    && this.isWeekYearFullyPlanned(weekYear);
            };

            /**
             * @param campaign
             * @param weekYear
             * @returns {boolean}
             */
            this.isWeekYearFullyPlannedAndCampaignNotPlannedNorAnOption = function (
                campaign,
                weekYear,
            ) {
                return this.isWeekYearFullyPlannedAndCampaignNotPlanned(campaign, weekYear)
                    && !this.isCampaignInPlanningOptionsForWeekYear(campaign, weekYear);
            };

            /**
             * @param weekYear
             * @returns {Array<PlanningOption>}
             */
            this.getPlanningOptionsForWeekYear = function (weekYear) {
                const options = [];
                for (let i = 0; i < self.planningOptions.length; i++) {
                    if (weekYear.isEqual(self.planningOptions[i].weekYear)) {
                        options.push(self.planningOptions[i]);
                    }
                }

                return options;
            };

            /**
             * @param weekYear
             * @returns {boolean}
             */
            this.hasPlanningOptionsForWeekYear = function (weekYear) {
                return this.getPlanningOptionsForWeekYear(weekYear).length > 0;
            };

            /**
             * @param campaign
             * @param weekYear
             * @returns {boolean}
             */
            this.isCampaignInPlanningOptionsForWeekYear = function (campaign, weekYear) {
                for (let i = 0; i < self.planningOptions.length; i++) {
                    if (
                        weekYear.isEqual(self.planningOptions[i].weekYear)
                        && campaign.id === self.planningOptions[i].campaign.id
                    ) {
                        return true;
                    }
                }

                return false;
            };

            /**
             * @param planningOption
             * @returns {*}
             */
            this.addOption = function (planningOption) {
                planningOption.setOrganisation(this);

                const length = self.planningOptions.push(planningOption);

                return planningOption.save()
                    .then(
                        () => {
                        self.planningOptions[length - 1] = planningOption;
                        },
                        () => {
                        self.planningOptions.remove(length - 1);
                        },
                    );
            };

            /**
             * @param planningOption
             * @returns {*}
             */
            this.deleteOption = function (planningOption) {
                return planningOption.delete()
                    .then(() => {
                        for (let i = 0; i < self.planningOptions.length; i++) {
                            if (self.planningOptions[i].id === planningOption.id) {
                                self.planningOptions.remove(i);
                                break;
                            }
                        }
                    });
            };

            /**
             * @param weekYears
             * @returns {*}
             */
            this.getPlanningOptions = function (weekYears) {
                const wys = [];
                angular.forEach(weekYears, (weekYear) => {
                    wys.push(weekYear.getSimpleObject());
                });

                const params = {
                    id: this.id,
                    requestParams: decodeURIComponent($.param({
                        weekYears: wys,
                    })),
                };

                self = this;

                return OrganisationService.getPlanningOptions(params, (response) => {
                    angular.forEach(response, (planningOptionData) => {
                        if (!self.containsPlanningOption(planningOptionData)) {
                            const planningOption = new PlanningOption(planningOptionData);
                            planningOption.setOrganisation(self);
                            self.planningOptions.push(planningOption);
                        }
                    });
                }).$promise;
            };

            /**
             * @param campaignPlanning
             * @returns {boolean}
             */
            this.containsCampaignPlanning = function (campaignPlanning) {
                return typeof this.campaignPlanning.find(
                    (item) => item.id === campaignPlanning.id,
                ) !== 'undefined';
            };

            /**
             * @param planningOption
             * @returns {boolean}
             */
            this.containsPlanningOption = function (planningOption) {
                return typeof this.planningOptions.find(
                    (item) => item.id === planningOption.id,
                ) !== 'undefined';
            };

            this.activeContractCount = function () {
                return this.contracts.filter(
                    (item) => item.id && item.status !== 'DONT_SHOW',
                ).length;
            };
        };
    }]);
