import {services} from '../app';

services.factory('CampaignService', ($resource) => $resource('/campaigns/:id/:action/:subId/:subAction', { id: '@id' }, {
    update: { method: 'PUT' },
    query: { method: 'GET', isArray: false },
    getCampaignPlanning: {
        method: 'GET',
        params: { action: 'campaignplanning', serieIds: [], yearWeeks: [] },
        isArray: true,
    },
    getPlanningOptions: { method: 'GET', params: { action: 'planningoptions', yearWeeks: [] }, isArray: true },
    deleteCampaignPlanning: { method: 'DELETE', params: { action: 'campaignplannings' } },
    deleteCampaignOrganisation: { method: 'DELETE', params: { action: 'organisations' } },
    deleteCampaignPackage: { method: 'DELETE', params: { action: 'packages' } },
    createOffer: { method: 'POST', params: { action: 'offers' } },
    planPackage: { method: 'POST', params: { action: 'packages', subAction: 'plan' } },
    getNumberOfAdSpots: { method: 'GET', params: { action: 'numberofadspots' }, isArray: false },
    getOrderLines: { method: 'GET', params: { action: 'orderlines', entry: ['DEBIT'] }, isArray: true },
    getOrderLinesDiscounts: { method: 'GET', params: { action: 'orderlines', entry: ['CREDIT'] }, isArray: true },
    saveOrderline: { method: 'POST', params: { action: 'orderlines' } },
    updateOrderline: { method: 'PUT', params: { action: 'orderlines' } },
    deleteOrderline: { method: 'DELETE', params: { action: 'orderlines' } },
    getRevenue: { method: 'GET', params: { action: 'revenue' }, isArray: false },
    getRevenueByOrganisation: {
        method: 'GET',
        params: { action: 'organisations', subAction: 'revenue' },
        isArray: false,
    },
    getCountByStatus: { method: 'GET', params: { action: 'countbystatus', status: [] }, isArray: false },
    saveCampaignMaterials: { method: 'POST', params: { action: 'campaignmaterials' } },
    updateCampaignMaterials: { method: 'PUT', params: { action: 'campaignmaterials' } },
    deleteCampaignMaterials: { method: 'DELETE', params: { action: 'campaignmaterials' } },
    getCampaignMaterials: { method: 'GET', params: { action: 'campaignmaterials' }, isArray: true },
    getCampaignSizeMaterials: { method: 'GET', params: { action: 'campaignsizematerials' }, isArray: true },
    getMaterialsList: { method: 'GET', params: { id: 'materialslist', quoteStage: [] }, isArray: true },
    getMaterialStatistics: { method: 'GET', params: { action: 'materialstatistics' }, isArray: true },
    getMaterialPhotos: { method: 'GET', params: { action: 'materialphotos' }, isArray: true },
    deleteMaterialPhoto: { method: 'DELETE', params: { action: 'materialphotos' } },
    getAllPlannedObjects: { method: 'GET', params: { action: 'allplannedobjects' }, isArray: true },
    getQuoteComparisonList: { method: 'GET', params: { id: 'quotecomparison' }, isArray: true },
    getPlannedObjectTypes: { method: 'GET', params: { action: 'plannedobjecttypes' }, isArray: true },
    checkDiscountMax: { method: 'POST', params: { action: 'checkmaxdiscounts' }, isArray: true },
    getPlannedSeries: { method: 'GET', params: { action: 'plannedseries' }, isArray: true },
    getPlannedOrganisations: { method: 'GET', params: { action: 'plannedorganisations' }, isArray: true },
    creditAmounts: { method: 'POST', params: { action: 'creditamounts' }, isArray: true },
    bulkSaveOrderlines: { method: 'POST', params: { action: 'bulkorderlines' }, isArray: true },
    getStopperAvailableAdSpots: { method: 'GET', params: { action: 'stopperavailableadspots' } },
}));
