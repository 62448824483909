import {directives} from '../app';

directives.directive('orderlines', ['$rootScope', 'CampaignService', 'Orderline', 'ArticleService', function ($rootScope, CampaignService, Orderline, ArticleService) {
    return {
        restrict: 'A',
        templateUrl: '/build/html/partials/orderlines.html',
        scope: {
            data: '=orderlines',
            campaign: '=campaign',
        },

        link($scope) {
            $scope.isShowable = false;

            $scope.toggleOrderlines = function (event) {
                event.stopPropagation();
                if (typeof ($scope.data) !== 'undefined' && $scope.data.length) {
                    $scope.isShowable = !$scope.isShowable;
                }
            };

            ArticleService.query({}, (response) => {
                $scope.articles = response;
            });

            $scope.addOrderline = function () {
                const orderline = new Orderline();
                orderline.setCampaign($scope.campaign);

                $scope.data.push(orderline);
            };

            $scope.removeOrderline = function (orderline, $index) {
                orderline.remove(() => {
                    $scope.data.splice($index, 1);
                    $rootScope.$broadcast('orderline-changed');
                });
            };

            $scope.save = function (orderline) {
                orderline.save(() => {
                    $scope.addOrderline();
                });
            };
        },
    };
}]);
