import {directives} from '../app';

directives.directive('ccTable', () => ({
    restrict: 'E',
    templateUrl: '/build/html/partials/directives/cc-table.html',
    scope: {
        service: '=',
        columns: '=',
        rowClick: '=',
        sorts: '=',
    },
    link($scope) {
        $scope.data = [];
        $scope.options = {
            rowHeight: 50,
            headerHeight: 30,
            footerHeight: 50,
            columnMode: 'flex',
            scrollbarV: false,
            sortType: 'single',
            emptyMessage: 'Geen resultaten gevonden',
            loadingMessage: 'Bezig met ophalen van de gegevens...',
            paging: {
                externalPaging: true,
                count: 0,
                offset: 0,
                size: 35,
                loadingIndicator: true,
            },
            columns: $scope.columns,
        };

        $scope.fetchData = function (size, offset) {
            $scope.options.paging.loadingIndicator = true;
            return $scope.service({
                offset,
                limit: size,
                sort: $scope.sorts.property,
                direction: $scope.sorts.direction,
            }, (data) => {
                $scope.options.paging.count = data.count;
                $scope.options.paging.loadingIndicator = false;

                for (let i = 0; i < data.result.length; i++) {
                    $scope.data.push(data.result[i]);
                }
            });
        };

        $scope.search = function () {
            $scope.fetchData(
                $scope.options.paging.size,
                $scope.options.paging.offset * $scope.options.paging.size,
            );
        };

        $scope.onColumnSort = function (columns) {
            $scope.options.paging.offset = 0;
            $scope.sorts = {
                property: columns[0].prop,
                direction: columns[0].sort,
            };

            $scope.data = [];
            $scope.search();
        };

        $scope.onRowClick = function (row) {
            $scope.rowClick(row);
        };
    },
}));
