import {controllers} from '../app';

controllers.controller('SerieListController', ['$rootScope', '$scope', 'OrganisationService', '$location', '$routeParams', 'SerieService', function ($rootScope, $scope, OrganisationService, $location, $routeParams, SerieService) {
    $scope.api = OrganisationService.getSeries;

    $scope.series = [];
    $scope.scrollEnabled = true;
    $scope.offset = 0;
    $scope.limit = 25;
    $scope.serieFilter = {
        incomplete: true,
        complete: true,
        overcomplete: true,
        searchString: '',
    };

    $scope.resetSearch = function () {
        $scope.series = [];
        $scope.offset = 0;
        $scope.limit = 25;
    };

    $scope.open = function (serie) {
        // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
        $location.path(`/settings/series/${serie.id}`);
    };

    $scope.search = function () {
        if ($routeParams.organisationId === undefined) {
            return;
        }
        $scope.scrollEnabled = false;
        $scope.noResultsFound = false;

        const currentCall = (new Date()).getTime();
        const lastCall = currentCall;
        const options = {
            id: $routeParams.organisationId,
            offset: $scope.offset,
            limit: $scope.limit,
        };

        if ($scope.serieFilter) {
            angular.extend(options, { query: JSON.stringify($scope.serieFilter) });
        }

        $scope.api(options, (json) => {
            if (currentCall !== lastCall) {
                return;
            }

            if ($scope.series.length) {
                $scope.series = $scope.series.concat(json);
            } else {
                $scope.series = (json.length > 0) ? json : null;
            }

            $scope.scrollEnabled = !(json.length < $scope.limit);
        })
            .$promise
            .then(() => {
                getNextStatistics();
            });
    };

    $('*').scroll(function () {
        if ($scope.scrollEnabled && _.isFunction($scope.search)) {
            let scroll = $(this).scrollTop() + $(this).innerHeight();
            let pageHeight = $(this)[0].scrollHeight;
            if (scroll >= pageHeight) {
                $scope.offset += $scope.limit;
                $scope.search();
            }
        }
    });

    $scope.initialRequest = function () {
        $scope.scrollEnabled = false;
        OrganisationService.get({ id: $routeParams.organisationId }, (organisation) => {
            $scope.organisation = organisation;

            $scope.api({
                id: $scope.organisation.id,
                offset: $scope.offset,
                limit: $scope.limit,
            }, (json) => {
                $scope.series = json;

                if (json.length === 0) {
                    $scope.noResultsFound = true;
                }

                $scope.scrollEnabled = true;
            })
                .$promise
                .then(getNextStatistics);
        });
    };

    function getNextStatistics()
    {
        for (let i = 0; i < $scope.series.length; i++) {
            if (!$scope.series[i].statistics) {
                return showStatistics($scope.series[i])
                    .$promise
                    .then(getNextStatistics);
            }
        }

        return Promise.resolve();
    }

    function showStatistics(serie)
    {
        serie.statistics = true;

        return SerieService.getStatistics({ id: serie.id }, (response) => {
            serie.statistics = response;
        });
    }

    $scope.$watch('serieFilter', (newValue, oldValue) => {
        $scope.resetSearch();

        if (newValue !== oldValue && newValue !== null) {
            $scope.search();
        }
    }, true);

    $scope.initialRequest();
}]);
