import {controllers} from '../app';

controllers.controller('NetworkController', ['$scope', '$rootScope', 'ObjectService', 'CampaignService', function ($scope, $rootScope, ObjectService, CampaignService) {
    $scope.getNetworkStatistics = function () {
        $scope.numberOfObjects = null;
        $scope.numberOfCampaigns = null;

        /**
         * Get number total of objects for
         */
        if ($rootScope.user.isAuthorizedTo('manage_objects_full')) {
            ObjectService.getStatistics((statistics) => {
                $scope.numberOfObjects = statistics.result.total;
            });
        }

        /**
         * Get number of campaigns with status confirmed or signed for materials list menu item
         */
        if ($rootScope.user.isAuthorizedTo('view_materiallist')) {
            CampaignService.getCountByStatus({ 'status[]': ['CONFIRMED', 'SIGNED'] }, (response) => {
                $scope.numberOfCampaigns = response.result;
            });
        }
    };

    $scope.getNetworkStatistics();
}]);
