import {filters} from '../app';

filters.filter('translate', () => function (obj, fallback) {
    const mapping = {
        supermarket: 'supermarkt',
        mall: 'winkelcentrum',
        city: 'gemeente',
        distributor: 'distributeur',
        '2sign': '2-sign',
        '3sign': '3-sign',
        pillar: 'pilaar',
        triangle: 'losse driehoek',
        static: 'statisch',
        all: 'Alles',
        only: 'Enkel',
        except: 'Alles zonder',
    };

    return mapping[obj] ? mapping[obj] : fallback;
});
