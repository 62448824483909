import {directives} from '../app';
import qq from 'fine-uploader/lib/s3'

directives.directive('gallery', ['CampaignService', 'config', function (CampaignService, config) {
    return {
        restrict: 'AE',
        templateUrl: '/build/html/partials/directives/gallery.html',
        scope: {
            campaign: '=',
        },
        link($scope) {
            $scope.images = [];

            $scope.galleryUploader = new qq.s3.FineUploader({
                debug: false,
                element: document.getElementById('fine-uploader-gallery'),
                request: {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    endpoint: `https://${config.S3_MATERIAL_BUCKET}.s3-eu-west-1.amazonaws.com`,
                    accessKey: 'AKIAIAKM7STN77GK6UQA',
                    params: {
                        campaign: $scope.campaign.id,
                    },
                },
                signature: {
                    endpoint: '/s3/signatures?type=material',
                },
                uploadSuccess: {
                    // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                    endpoint: `/campaigns/${$scope.campaign.id}/photos`,
                },
                validation: {
                    allowedExtensions: ['gif', 'jpeg', 'jpg', 'png'],
                    acceptFiles: 'image/gif, image/jpeg, image/png',
                    sizeLimit: 100000000,
                    itemLimit: 10,
                },
                retry: {
                    enableAuto: true,
                },
                deleteFile: {
                    enabled: false,
                },
                objectProperties: {
                    acl: 'private',
                },
                callbacks: {
                    onComplete(id, name, responseJSON) {
                        $scope.addUploadedPhotoToImageObject(responseJSON);
                    },
                },
            });

            $scope.nextImage = function () {
                if ($scope.currentIndex < $scope.images.length - 1) {
                    $scope.currentIndex++;
                    return;
                }
                $scope.currentIndex = 0;
            };

            $scope.previousImage = function () {
                if ($scope.currentIndex > 0) {
                    $scope.currentIndex--;
                    return;
                }
                $scope.currentIndex = $scope.images.length - 1;
            };

            $scope.addUploadedPhotoToImageObject = function (responseJSON) {
                const newCurrentIndex = $scope.images.length;
                $scope.images.push({
                    name: responseJSON.name,
                    fileName: responseJSON.fileName,
                    secureUrl: responseJSON.tempLink,
                    visible: true,
                });

                $scope.$apply(() => {
                    $scope.currentIndex = newCurrentIndex;
                });
            };

            $scope.getMaterialPhotos = function () {
                CampaignService.getMaterialPhotos({ id: $scope.campaign.id }, (response) => {
                    $scope.images = response;
                    $scope.currentIndex = 0;
                });
            };

            $scope.deleteImage = function () {
                // phpcs:ignore PSR12.Operators.OperatorSpacing.NoSpaceAfter, PSR12.Operators.OperatorSpacing.NoSpaceBefore
                if (!confirm(`Weet u zeker dat u de foto '${$scope.images[$scope.currentIndex].name}' wilt verwijderen?`)) {
                    return;
                }

                CampaignService.deleteMaterialPhoto({
                    id: $scope.campaign.id,
                    fileName: $scope.images[$scope.currentIndex].fileName,
                }, () => {
                    $scope.images.splice($scope.currentIndex, 1);
                    if ($scope.images.length > 0) {
                        if ($scope.currentIndex !== 0) {
                            $scope.currentIndex--;
                        }
                        $scope.images[$scope.currentIndex].visible = true;
                    }
                });
            };

            $scope.updateView = function () {
                if (!$scope.images.length) {
                    return;
                }

                for (let i = 0; i < $scope.images.length; i++) {
                    $scope.images[i].visible = false;
                }

                $scope.images[$scope.currentIndex].visible = true;
            };

            $scope.$watch('currentIndex', $scope.updateView);

            $scope.getMaterialPhotos();
        },
    };
}]);
